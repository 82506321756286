
<p-card>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" [options]="empresas" optionValue="id_empresa" optionLabel="nombre"
                        formControlName="empresa_id"></p-dropdown>
                    <label>Empresa</label>
                    <small
                        *ngIf="form.get('empresa_id')?.invalid && (form.get('empresa_id')?.dirty || form.get('empresa_id')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('empresa_id') }}
                    </small>
                </span>
            </div>

            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="Selecciona Categoría" [options]="sedes"
                        optionValue="id_sede" optionLabel="nombre" formControlName="sede_id"></p-dropdown>
                    <label>Sede</label>
                    <small
                        *ngIf="form.get('sede_id')?.invalid && (form.get('sede_id')?.dirty || form.get('sede_id')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('sede_id') }}
                    </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">
                    <p-dropdown [showClear]="true" placeholder="Selecciona la Area" [options]="areas"
                        optionValue="id_area" optionLabel="nombre" formControlName="area_id"></p-dropdown>
                    <label>Area</label>
                    <small
                        *ngIf="form.get('area_id')?.invalid && (form.get('area_id')?.dirty || form.get('area_id')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('area_id') }}
                    </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">

                    <p-dropdown [showClear]="true" placeholder="Selecciona la Area" [options]="Tipo_documento"
                        optionLabel="nombre" formControlName="tipo_documento"></p-dropdown>
                    <label>Tipo Documento</label>
                    <small
                        *ngIf="form.get('tipo_documento')?.invalid && (form.get('tipo_documento')?.dirty || form.get('tipo_documento')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('tipo_documento') }}
                    </small>
                </span>
            </div>
        </div>
        <div class="row mt-5">

            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText  class="form-control" formControlName="numero_documento">
                    
                    <label>Nº Documento</label>
                    <small
                        *ngIf="form.get('numero_documento')?.invalid && (form.get('numero_documento')?.dirty || form.get('numero_documento')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('numero_documento') }}
                    </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText class="form-control" formControlName="apellido_paterno">
                    <label>Apellido Paterno</label>
                    <small
                        *ngIf="form.get('apellido_paterno')?.invalid && (form.get('apellido_paterno')?.dirty || form.get('apellido_paterno')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('apellido_paterno') }}
                    </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText class="form-control" formControlName="apellido_materno">
                    <label>Apellido Materno</label>
                    <small
                        *ngIf="form.get('apellido_materno')?.invalid && (form.get('apellido_materno')?.dirty || form.get('apellido_materno')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('apellido_materno') }}
                    </small>
                </span>
            </div>
            <div class="col-lg-3">
                <span class="p-float-label">
                    <input pInputText class="form-control" formControlName="nombres">
                    <label>Nombres</label>
                    <small
                        *ngIf="form.get('nombres')?.invalid && (form.get('nombres')?.dirty || form.get('nombres')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('nombres') }}
                    </small>
                </span>
            </div>
            <div class="row mt-5">

                <div class="col-lg-3">
                    <span class="p-float-label">
                        <input pInputText class="form-control" formControlName="celulares">
                        <label>Celulares</label>
                        <small
                            *ngIf="form.get('celulares')?.invalid && (form.get('celulares')?.dirty || form.get('celulares')?.touched)"
                            class="p-error">
                            {{ getErrorMessage('celulares') }}
                        </small>
                    </span>
                </div>
                <div class="col-lg-3">
                    <span class="p-float-label">
                        <input pInputText class="form-control" formControlName="correos">
                        <label>Correos</label>
                        <small
                            *ngIf="form.get('correos')?.invalid && (form.get('correos')?.dirty || form.get('correos')?.touched)"
                            class="p-error">
                            {{ getErrorMessage('correos') }}
                        </small>
                    </span>
                </div>
                <div class="col-lg-3">
                    <span class="p-float-label">
                        <input pInputText class="form-control" formControlName="direccion">
                        <label>Dirección</label>
                        <small
                            *ngIf="form.get('direccion')?.invalid && (form.get('direccion')?.dirty || form.get('direccion')?.touched)"
                            class="p-error">
                            {{ getErrorMessage('direccion') }}
                        </small>
                    </span>
                </div>
                <div class="col-lg-3">
                    <span class="p-float-label">
                        <input pInputText class="form-control" formControlName="departamento">
                        <label>Departamento</label>
                        <small
                            *ngIf="form.get('departamento')?.invalid && (form.get('departamento')?.dirty || form.get('departamento')?.touched)"
                            class="p-error">
                            {{ getErrorMessage('departamento') }}
                        </small>
                    </span>
                </div>

            </div>

            <div class="row mt-5">
                <div class="col-lg-3">
                    <span class="p-float-label">
                        <input pInputText class="form-control" formControlName="provincia">
                        <label>Provincia</label>
                        <small
                            *ngIf="form.get('provincia')?.invalid && (form.get('provincia')?.dirty || form.get('provincia')?.touched)"
                            class="p-error">
                            {{ getErrorMessage('provincia') }}
                        </small>
                    </span>
                </div>

                <div class="col-lg-3">
                    <span class="p-float-label">
                        <input pInputText class="form-control" formControlName="distrito">
                        <label>Distrito</label>
                        <small
                            *ngIf="form.get('distrito')?.invalid && (form.get('distrito')?.dirty || form.get('distrito')?.touched)"
                            class="p-error">
                            {{ getErrorMessage('distrito') }}
                        </small>
                    </span>
                </div>
                <div class="col-lg-3">
                    <span class="p-float-label">
                        <input pInputText class="form-control" formControlName="detalles">
                        <label>Detalles</label>
                        <small
                            *ngIf="form.get('detalles')?.invalid && (form.get('detalles')?.dirty || form.get('detalles')?.touched)"
                            class="p-error">
                            {{ getErrorMessage('detalles') }}
                        </small>
                    </span>
                </div>
                <div class="col-lg-3">
                    <span class="p-float-label">
                        <input pInputText class="form-control" formControlName="descripcion">
                        <label>Descripción</label>
                        <small
                            *ngIf="form.get('descripcion')?.invalid && (form.get('descripcion')?.dirty || form.get('descripcion')?.touched)"
                            class="p-error">
                            {{ getErrorMessage('descripcion') }}
                        </small>
                    </span>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            <div class="col-lg-12 text-end">
                <div class="register-botton">
                    <button pButton type="button" class="p-button-success" icon="pi pi-save" label="Registrar"
                        (click)="Register_Solicitante()"></button>
                    <p-button label="Cancelar" (click)="hideDialog()" [raised]="true" severity="danger"></p-button>
                </div>
            </div>
        </div>
    </form>
</p-card>