
<div class="parent"> 
    <div class="container" >
        <div class="form-container sign-up" >
          
        </div>
        <div class="form-container sign-in" >
                <h1 class="login_title">Login </h1>
        
                <span class="p-input-icon-left ">
                    <i class="pi pi-user" style="font-size: 1.5rem"></i>
                    
                    <input  placeholder="Usuario" pInputText id="username" [(ngModel)]="credentials.email" style="padding-left: 50px;"/>
                   
                </span>
                <span class="p-input-icon-left">
                    <i class="pi pi-lock" style="font-size: 1.5rem"></i>
                    <input placeholder="Contraseña" type="password" pInputText id="password" [(ngModel)]="credentials.password" style="padding-left: 50px;" />
                </span>
                <p></p>
                <button (click)="login()">INGRESAR</button>
        </div>
        <div class="toggle-container">
            <div class="toggle">
           
                <div class="toggle-panel toggle-right">
                    
                    <p class="p_title"></p>
                    <!-- <button type="button" class="hidden" id="register" (click)="isSignDivVisiable = false">Sign Up</button> -->
                </div>
            </div>
        </div>
    </div>
</div>

