
<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <input type="file" name="file" (change)="onFileSelected($event)">
            <button type="button" (click)="onSubmit()">Subir archivo</button>
            <br>
            <img [src]="imagen_url" alt="" width="500px">
        </div>
    </div>
</div>