<div class="row">
	<div class="col-lg-12">
		<p-card>
			<div class="row mb-5">
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.codigo" [disabled]="true">
						<label>Código </label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre_empresa" [disabled]="true">
						<label>Empresa</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre_sede" [disabled]="true">
						<label>Sede</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre" [disabled]="true">
						<label>Nombre</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre_corto" [disabled]="true">
						<label>Nombre corto</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.nombre_largo" [disabled]="true">
						<label>Nombre largo</label>
					</p-floatLabel>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.fecha_inicio" [disabled]="true">
						<label>Fecha inicio</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.fecha_fin" [disabled]="true">
						<label>Fecha fin</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.estado" [disabled]="true">
						<label>Estado</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-4">
					<p-floatLabel>
						<input pInputText class="w-100" [(ngModel)]="DetallesCaja_Response.detalles" [disabled]="true">
						<label>Detalles</label>
					</p-floatLabel>
				</div>
				<div class="col-lg-2">
					<p-floatLabel>
						<input pInputText [(ngModel)]="DetallesCaja_Response.monto_actual" [disabled]="true">
						<label>Saldo disponible</label>
					</p-floatLabel>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<label>Periodos: </label>
					<span *ngFor="let periodo of PeriodosCaja_Db"> {{ periodo.nombre }} , </span> <button
						class="btn btn-link" type="button" (click)="Open_Modal_Ver_PeriodosCaja()">Detalles</button>

					<p-dialog header="Periodos" [modal]="true" (onHide)="Close_Modal_Ver_PeriodosCaja()"
						[draggable]="false" [(visible)]="Display_Modal_Ver_PeriodosCaja">
						<table>
							<thead>
								<tr>
									<th>N</th>
									<th>Nombre</th>
									<th>Detalles</th>
									<th>Inicio</th>
									<th>Fin</th>
									<th>Saldo Inicial</th>
									<th>Ingresos</th>
									<th>Egresos</th>
									<th>Saldo Final</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let periodo_caja of PeriodosCaja_Db; let i = index">
									<td> {{ i + 1 }} </td>
									<td> {{ periodo_caja.nombre }} </td>
									<td> {{ periodo_caja.detalles }} </td>
									<td> {{ periodo_caja.inicio }} </td>
									<td> {{ periodo_caja.fin }} </td>
									<td> {{ periodo_caja.monto_inicial }} </td>
									<td> {{ periodo_caja.monto_ingresos }} </td>
									<td> {{ periodo_caja.monto_egresos }} </td>
									<td> {{ periodo_caja.monto_final }} </td>
								</tr>
							</tbody>
						</table>
					</p-dialog>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<h4>Movimientos</h4>

					<button pButton pRipple label="Registrar Movimiento" severity="success" class="mr-5"
						(click)="Open_Modal_RegistrarMovimientoCaja()">
					</button>
					<label>
                        Periodo:
                        <p-dropdown [showClear]="true" [options]="PeriodosCaja" optionValue="id_periodo_caja"
                            optionLabel="nombre" [(ngModel)]="id_periodo_caja"
                            (onChange)="Get_Movimientos_By_Periodo_TipoMovimiento()"></p-dropdown>
                    </label>

					<label>
						Tipo Movimiento
						<p-dropdown [showClear]="true" [options]="TiposMovimiento_Caja" optionValue="nombre"
							optionLabel="nombre" [(ngModel)]="tipo_movimiento"
							(onChange)="Get_Movimientos_By_Periodo_TipoMovimiento()"></p-dropdown>
					</label>

					<button pButton pRipple severity="info" class="mr-5" label="Ver resumen"
						(click)="Open_Modal_VerResumenMovimientoCaja()">
					</button>
					<button pButton pRipple severity="danger" label="Cerrar" (click)="Close_Modal_RegistrarCaja()">
					</button>

					<p-table [value]="Movimientos" [tableStyle]="{ 'min-width': '50rem' }"
						styleClass="p-datatable-gridlines p-datatable-striped">
						<ng-template pTemplate="header">
							<tr>
								<th>Tipo</th>
								<th>Fecha</th>
								<th>Periodo</th>
								<th>Concepto</th>
								<th>Moneda</th>
								<th>Ingreso</th>
								<th>Egreso</th>
								<th>Saldo disponible</th>
								<th>Tipo Pago</th>
								<th>Método Pago</th>
								<th>Tipo Comprobante</th>
								<th>Comprobante</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-movimiento>
							<tr>
								<td>{{ movimiento.tipo }}</td>
								<td>{{ movimiento.fecha }}</td>
								<td>{{ movimiento.nombre_periodo_caja }}</td>
								<td>{{ movimiento.concepto }}</td>
								<td>{{ movimiento.moneda }}</td>
								<td>{{ ( movimiento.tipo == 'INGRESO' ) ? movimiento.monto : '' }}</td>
								<td>{{ ( movimiento.tipo == 'EGRESO' ) ? movimiento.monto : '' }}</td>
								<td>{{ movimiento.monto_disponible }}</td>
								<td>{{ movimiento.tipo_pago }}</td>
								<td>{{ movimiento.metodo_pago }}</td>
								<td>{{ movimiento.tipo_comprobante }}</td>
								<td>{{ movimiento.comprobante }}</td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</div>
		</p-card>
	</div>
</div>

<!-- Child Componentes -->
<!-- Registrar moviemiento de caja -->
<p-dialog header="Registrar movimiento de Caja" [modal]="true" [(visible)]="Display_Modal_RegistrarMovimientoCaja"
	[draggable]="false" [resizable]="false" [closable]="false" styleClass="custom-dialog"
	[style]="{width: '1200px', height: '900px', maxWidth: '90vw', maxHeight: '80vh'}">
	<app-registrar-movimiento-caja (registroAgregado)="ActualizarRegistroAgregado($event)" [Data_Parent]="formData"
		(Close_Modal_RegistrarMovimientCaja_Event)="Close_Modal_RegistrarMovimientoCaja()">
	</app-registrar-movimiento-caja>
</p-dialog>


<!-- Ver resumen de movimiento -->
<p-dialog header="Resumen de movimientos" [draggable]="false" [resizable]="false"
	[style]="{width: '1500px', height: '1500px', maxWidth: '150vw', maxHeight: '90vh'}" [modal]="true"
	[(visible)]="Display_Modal_VerResumenMovimientoCaja">
	<app-resumen-movimiento-caja [RowData_Parent]="formData"></app-resumen-movimiento-caja>
</p-dialog>