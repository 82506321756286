import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { Subject } from 'rxjs';
import { MovimientoCuenta_Request } from 'src/app/request/gestion-cuenta/movimiento-cuenta-request';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { PeriodoService } from 'src/app/services/general-services/periodo.service';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';
import { MovimientoCuenta_Service } from 'src/app/services/gestion-cuenta/movimiento_cuenta.service';
import { PeriodoCuentaService } from 'src/app/services/gestion-cuenta/periodo-cuenta.service';
import { Desembolso_Service } from 'src/app/services/gestion-desembolso/desembolso.service';

@Component({
    selector: 'app-registrar-movimiento-cuenta',
    standalone: true,
    imports: [
        FormsModule,
        CalendarModule,
        FloatLabelModule,
        ReactiveFormsModule,
        CommonModule,
        DropdownModule,
        InputTextModule,
        CardModule,
        ToastModule,
    ],
    templateUrl: './registrar-movimiento-cuenta.component.html',
    styleUrl: './registrar-movimiento-cuenta.component.css',
    providers: [MessageService],
})
export class RegistrarMovimientoCuentaComponent {
    @Output() Close_Modal_Event3 = new EventEmitter<void>();
    @Output() registroAgregado = new EventEmitter<any>();
    eventsSubject: Subject<void> = new Subject<void>();

    form: FormGroup;
    errors: any = {};

    MovimientoCuenta_Request: MovimientoCuenta_Request =
        new MovimientoCuenta_Request();

    @Input() Data_Parent: any;

    /* Data Forms Inputs */
    cuentas: any[] = [];
    id_cuenta: any;
    periodos_cuenta: any[] = [];
    Monedas: any[] = [];

    Tipos: any[] = [
        {
            id_tipo: 1,
            nombre: 'INGRESO',
        },
        {
            id_tipo: 2,
            nombre: 'EGRESO',
        },
    ];
    TiposPago: any[] = [
        {
            id_tipo_pago: 1,
            nombre: 'FÍSICO',
        },
        {
            id_tipo_pago: 2,
            nombre: 'DIGITAL',
        },
    ];
    MetodosPago: any[] = [
        {
            id_metodo_pago: 1,
            nombre: 'EFECTIVO',
        },
        {
            id_metodo_pago: 2,
            nombre: 'YAPE',
        },
        {
            id_metodo_pago: 3,
            nombre: 'TRANSFERENCIA',
        },
    ];
    TiposComprobante: any[] = [
        {
            id_tipo_comprobante: 1,
            nombre: 'VOUCHER',
        },
    ];
    desembolsos: any = [];
    constructor(
        private _movimientoCuenta: MovimientoCuenta_Service,
        private _Moneda_Service: Moneda_Service,
        private fb: FormBuilder,
        private _Cuenta_service: Cuenta_Service,
        private messageService: MessageService,
        private _PeriodoCuenta_service: PeriodoCuentaService,
        private _ReporteDesembolso_Service: Desembolso_Service
    ) {
        this.form = this.fb.group({
            id_cuenta: [{ value: null, disabled: true }, Validators.required],
            id_desembolso: [null],
            tipo: [null, Validators.required],
            concepto: [null, Validators.required],
            fecha: [null, Validators.required],
            id_periodo_cuenta: [null, Validators.required],
            moneda: [null, Validators.required],
            simbolo_moneda: ['', Validators.required],
            monto: [null, Validators.required],
            tipo_pago: [null, Validators.required],
            metodo_pago: [null, Validators.required],
            tipo_comprobante: [null, Validators.required],
            comprobante: [null, Validators.required]
        });

        this.limpiar_validacion_escribir();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['Data_Parent'] && changes['Data_Parent'].currentValue) {

            this.form.patchValue({ ...changes['Data_Parent'].currentValue });
            this.id_cuenta = changes['Data_Parent'].currentValue.id_cuenta;
            if (this.id_cuenta) {
                this.Get_PeriodosCuenta_By_CuentaId_Api();
            }
        }
    }

    ngOnInit(): void {
        this.Get_Cuenta_Api();
        this.Get_Monedas_Api();
        this.Get_Desembolsos_Api();
    }

    Get_Desembolsos_Api() {
        this._ReporteDesembolso_Service.get().subscribe({
            next: (response: any) => {
                const response_data = response.data;
                if (response_data) {
                    this.desembolsos = response_data;
                } else {
                    console.error('No se encontró ningún dato');
                }
            },
            error: (error) => {
                console.log('Error al consumir el servicio', error);
            },
        });
    }

    limpiar_validacion_escribir(): void {
        Object.keys(this.form.controls).forEach((key) => {
            this.form.get(key)?.valueChanges.subscribe(() => {
                this.errors[key] = null;
            });
        });
    }
    Get_Cuenta_Api(): void {
        this._Cuenta_service.get().subscribe({
            next: (response: any) => {
                if (response.status == 'OK') {
                    this.cuentas = response.data;
                } else {
                    console.error('No se encontró ningún dato');
                }
            },
            error: (error) => {
                console.log('Error al llamar el servicio', error);
            },
        });
    }
    Get_PeriodosCuenta_By_CuentaId_Api(): void {
        const request = {
            cuenta: this.id_cuenta,
        };
        this._PeriodoCuenta_service.getByOther(request).subscribe({
            next: (response: any) => {
                if (response.status == 'OK') {
                    this.periodos_cuenta = response.data;
                } else {
                    console.error('No se encontró ningún dato');
                }
            },
            error: (error) => {
                console.log('Error al llamar el servicio', error);
            },
        });
    }
    Get_Monedas_Api(): void {
        this._Moneda_Service.get().subscribe({
            next: (response: any) => {
                if (response.status == 'OK') {
                    this.Monedas = response.data;
                    setTimeout(() => {
                        this.eventsSubject.next();
                    }, 200);
                } else {
                    console.error('No se encontró ningún dato');
                }
            },
            error: (error) => {
                console.log('Error al llamar el servicio', error);
            },
        });
    }

    onFileSelect(event: any) {
        const file = event.target.files[0];
        if (file) {
            this.form.patchValue({ comprobante: file });
        }
    }

    Registrar_MovimientoCuenta(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        
        this.form.get('id_cuenta')?.enable();
        const Form_MovimientoCuenta_Request = new FormData();
        Form_MovimientoCuenta_Request.append('id_cuenta', this.form.value.id_cuenta);
        Form_MovimientoCuenta_Request.append('id_desembolso', this.form.value.id_desembolso);
        Form_MovimientoCuenta_Request.append('tipo', this.form.value.tipo);
        Form_MovimientoCuenta_Request.append('concepto', this.form.value.concepto);
        Form_MovimientoCuenta_Request.append('fecha', this.form.value.fecha);
        Form_MovimientoCuenta_Request.append('id_periodo_cuenta', this.form.value.id_periodo_cuenta);
        Form_MovimientoCuenta_Request.append('moneda', this.form.value.moneda);
        Form_MovimientoCuenta_Request.append('simbolo_moneda', this.form.value.simbolo_moneda);
        Form_MovimientoCuenta_Request.append('monto', this.form.value.monto);
        Form_MovimientoCuenta_Request.append('tipo_pago', this.form.value.tipo_pago);
        Form_MovimientoCuenta_Request.append('metodo_pago', this.form.value.metodo_pago);
        Form_MovimientoCuenta_Request.append('tipo_comprobante', this.form.value.tipo_comprobante);
        Form_MovimientoCuenta_Request.append('comprobante', this.form.value.comprobante);
        this.form.get('id_cuenta')?.disable();


        this._movimientoCuenta.post(Form_MovimientoCuenta_Request).subscribe({
            next: (response: any) => {
                if (response.status === 'OK') {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Éxito',
                        detail: 'El registro se realizó correctamente',
                        life: 5000,
                    });

                    this.form.reset({
                        id_cuenta: this.form.get('id_cuenta')?.value
                    });

                    this.registroAgregado.emit();
                    this.form.patchValue({
                        id_desembolso: '',
                        tipo: '',
                        concepto: '',
                        id_periodo_cuenta: '',
                        moneda: '',
                        simbolo_moneda: '',
                        monto: '',
                        tipo_pago: '',
                        metodo_pago: '',
                        tipo_comprobante: '',
                        comprobante: '',
                        detalles: ''
                    });

                    // Reestablecer la validación y estado de los campos
                    this.form.markAsPristine(); // Marcar el formulario como limpio
                    this.form.markAsUntouched(); // Marcar todos los campos como no tocados
                    this.form.updateValueAndValidity(); // Actualizar la validez del formulario // O usa el método que mantenga id_cuenta


                    setTimeout(() => {
                        this.Close_Modal_Event3.emit();
                    }, 3000);
                } else {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Advertencia',
                        detail: 'No hay suficiente saldo',
                    });
                }
            },
            error: (error) => {
                console.log('Error al llamar el servicio', error);
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Ocurrió un error al procesar la solicitud',
                });
            },
        });
    }

    hideDialog() {
        this.form.reset({
            id_cuenta: this.form.get('id_cuenta')?.value
        });
        this.Close_Modal_Event3.emit();
    }




    getErrorMessage(controlName: string): string {
        const control = this.form.get(controlName);
        if (control!.hasError('required')) {
            return 'Este campo es obligatorio';
        }
        if (control!.hasError('pattern')) {
            return 'Formato no válido';
        }
        return '';
    }
}
