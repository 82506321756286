<section class="row">
	<div class="col-lg-12">
		<div class="row">

			<div class="col-lg-4">
				<label for="empresa_id">Empresa:</label>
				<p-dropdown inputId="empresa_id" [options]="Empresas_Response" optionValue="id_empresa"
					optionLabel="nombre" [(ngModel)]="id_empresa" (onChange)="Get_Empresas_With__By_PeriodoId_Api()">
				</p-dropdown>
			</div>
			<div class="col-lg-4">
				<label for="periodo_id">Periodo:</label>
				<p-dropdown inputId="periodo_id" [options]="Periodos_Response" optionValue="id_periodo"
					optionLabel="nombre" [(ngModel)]="id_periodo"
					(onChange)="Get_Cuentas_With_Desembolsos_By_PeriodoId_Api()">
				</p-dropdown>
			</div>
			<div class="col-lg-4 mt-3">
				<p-button label="Cancelar" [raised]="true" severity="danger" (onClick)="closeModal()"></p-button>
			</div>

		</div>
		<div class="row" *ngFor="let cuenta of Cuentas_Response">
			<div class="col-lg-12">
				<table>
					<thead>
						<tr>
							<th colspan="2"> {{ cuenta.nombre_empresa }} </th>
							<th colspan="3"> {{ cuenta.nombre }} </th>
						</tr>
						<tr>
							<th>N°</th>
							<th>Concepto</th>
							<th>Número de cuenta</th>
							<th>Mes correspondiente</th>
							<th>Monto</th>
							<th>Solicita / Autoriza</th>
							<th>Obs</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let desembolso of cuenta.desembolsos; let i = index">
							<td> {{ i + 1 }} </td>
							<td> {{ desembolso.concepto }} </td>
							<td> {{ desembolso.nro_cuenta }} </td>
							<td> {{ desembolso.nombre_periodo }} </td>
							<td> {{ desembolso.monto }} </td>
							<td> {{ desembolso.persona_solicita }} / {{ desembolso.persona_autoriza }} </td>
							<td> {{ desembolso.observaciones }} </td>

						</tr>
						<tr>
							<th colspan="4">TOTAL RETIRO</th>
							<th> {{ cuenta.monto }} </th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</section>