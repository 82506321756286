import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Signin} from "./signin/signin.model";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {AuthResponse} from "./auth.response";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://test.cloud.elastika.pe/api/'; // Cambia esta URL
  private tokenKey = 'token'; // Key para el token en localStorage
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.isAuthenticated$.next(!!this.getToken());
  }

  login(credentials: { email: string; password: string }): Observable<any> {
    return this.http.post(`${this.apiUrl}login`, credentials)
      .pipe(
        tap((response: any) => {
          this.storeToken(response.token);
          this.isAuthenticated$.next(true);
        })
      );
  }

  logout(): void {
    this.clearToken();
    this.isAuthenticated$.next(false);
  }

  private storeToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  private clearToken(): void {
    localStorage.removeItem(this.tokenKey);
  }
}
