// editar-requerimiento-pago.component.ts
import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { Subject } from 'rxjs';
import { Req_Pago_Response } from 'src/app/response/gestion-desembolso/registrar-requerimiento-pago-response';
import { Area_Service } from 'src/app/services/general-services/area.service';
import { Banco_Service } from 'src/app/services/general-services/banco.service';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Persona_Service } from 'src/app/services/general-services/persona.service';
import { Sede_Service } from 'src/app/services/general-services/sede.service';
import { Tipo_Requerimiento_Service } from 'src/app/services/general-services/tipoRequerimiento.service';
import { Editar_Req_Pago_Service } from 'src/app/services/gestion-desembolso/editar-req-pago.service';

@Component({
  selector: 'app-editar-requerimiento-pago',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    InputNumberModule,
    TableModule,
    DropdownModule,
    CalendarModule,
    ButtonModule,
    FloatLabelModule,
    InputTextModule,
    TooltipModule,
    DividerModule,
    CommonModule,
    SelectButtonModule,
    FontAwesomeModule,
    DialogModule,
    ToastModule,
    FormsModule,
    FileUploadModule,
    ReactiveFormsModule,
  ],
  templateUrl: './editar-requerimiento-pago.component.html',
  styleUrl: './editar-requerimiento-pago.component.css',
  providers: [MessageService],
})
export class EditarRequerimientoPagoComponent {
  @Input() data: any;
  @Output() closeModalEmmit = new EventEmitter<boolean>();
  @Output() registroAgregado = new EventEmitter<any>();
  empresas: any[] = [];
  bancos: any[] = [];
  idRequerimiento: number;
  requerimiento_pago: any = {};
  tipos_concepto: any[] = [];
  requerimientos_new: any[] = [];
  requerimiento_dinamico__db: any[][] = [];
  requerimiento_dinamico__new__db: any[] = [];
  sedes: any[] = [];
  areas: any[] = [];
  personas: any[] = [];
  /////////////////////////

  Update_Requerimiento_pago_Form: FormGroup;
  requerimientos: any[] = [];
  requerimiento: any = {};
  eventsSubject: Subject<void> = new Subject<void>();
  monedas: any[] = [];
  Tipo_comprobante: any[] = [
    { label: 'BOLETA', value: 'BOLETA' },
    { label: 'FACTURA', value: 'FACTURA' },
  ];
  loading: boolean;
  id_requerimiento_pago: any;

  constructor(
    private Editar_requerimiento_pago: Editar_Req_Pago_Service,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _empresaService: Empresa_Service,
    private _monedaService: Moneda_Service,
    private _tipoRequerimientoService: Tipo_Requerimiento_Service,
    private _bancoService: Banco_Service,
    private messageService: MessageService,
    private _peronsaService: Persona_Service,
    private _sedeService: Sede_Service,
    private _areaService: Area_Service,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.Update_Requerimiento_pago_Form = this.fb.group({
      id_requerimiento_pago: [null, Validators.required],
      empresa_id: [null, Validators.required],
      sede_id: [null, Validators.required],
      nombre_sede: [null, Validators.required],
      nombre_area: [null, Validators.required],
      area_id: [null, Validators.required],

      persona_id: [null, Validators.required],
      tipo_concepto: [null, Validators.required],
      concepto: [null, Validators.required],
      tipo_pago: [null, Validators.required],
      metodo_pago: [null, Validators.required],
      comprobante: [null, Validators.required],
      simbolo_moneda: [null, Validators.required],
      moneda: [null, Validators.required],
      monto: [null, Validators.required],
      nro_cuenta: ['', Validators.required],
      banco_cuenta: ['', Validators.required],
      titular_cuenta: ['', Validators.required],
      fecha_pago: [null, Validators.required],
    });
  }

  tipos_pago: any[] = [
    {
      id_tipo_pago: 1,
      nombre: 'FÍSICO',
    },
    {
      id_tipo_pago: 2,
      nombre: 'DIGITAL',
    },
  ];
  metodos_pago: any[] = [
    {
      id_tipo_pago: 1,
      nombre: 'YAPE',
    },
    {
      id_tipo_pago: 2,
      nombre: 'PLIN',
    },
    {
      id_tipo_pago: 3,
      nombre: 'TRANSFERENCIA',
    },
    {
      id_tipo_pago: 4,
      nombre: 'DEPOSITO ',
    },
    {
      id_tipo_pago: 5,
      nombre: 'EFECTIVO',
    },
  ];

  tipos_comprobante: any[] = [
    {
      id_tipo_pago: 1,
      nombre: 'DEPOSITO',
    },
    {
      id_tipo_pago: 2,
      nombre: 'TRANSFERENCIA',
    },
  ];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      this.Update_Requerimiento_pago_Form.patchValue(
        changes['data'].currentValue
      );
      this.id_requerimiento_pago = this.Update_Requerimiento_pago_Form.get(
        'id_requerimiento_pago'
      )?.value;
      if (this.id_requerimiento_pago) {
        this.Get_Requerimiento_Pago();
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.Get_Requerimiento_pago_By_Id();
    this.getEmpresas_Api();
    this.getMoneda_Api();
    this.getSedes_Api();
    this.getBanco_Api();
    this.getTipoRequerimiento_Api();
    this.getPersonas_Api();
    this.getArea_Api();
  }

  getPersonas_Api(): void {
    this._peronsaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.personas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getArea_Api(): void {
    this._areaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.areas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  Get_PersonasByAreaId_Api(object: DropdownChangeEvent): void {
    console.log('get_personas');
    let area_id = object.value;
    let request = {
      'area-id': area_id,
    };
    this._peronsaService.getByOther(request).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.personas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  Get_AreasBySedeId_Api(object: DropdownChangeEvent): void {
    let sede_id = object.value;
    let request = {
      'sede-id': sede_id,
    };
    this._areaService.getByOther(request).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.areas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getSedes_Api(): void {
    this._sedeService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.sedes = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  Get_SedesByEmpresaId_Api(object: DropdownChangeEvent): void {
    let sede_id = object.value;
    let request = {
      'empresa-id': sede_id,
    };
    this._sedeService.getByOther(request).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.sedes = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  Get_Requerimiento_Pago(): void {
    this.Editar_requerimiento_pago.getByid(
      this.Update_Requerimiento_pago_Form.get('id_requerimiento_pago')?.value
    ).subscribe({
      next: (response: any) => {
        if (response.data) {
          this.requerimiento = { ...response.data };
          this.Update_Requerimiento_pago_Form.patchValue({
            monto_desembolso: this.requerimiento.monto,
          });
        } else {
          console.log('No se encontraron datos.');
        }
      },
    });
  }

  Get_Requerimiento_pago_By_Id(): void {
    this.Editar_requerimiento_pago.getByid(
      this.data.id_requerimiento_pago
    ).subscribe({
      next: (response: any) => {
        if (response.status == 'OK') {
          this.Update_Requerimiento_pago_Form.patchValue(response.data);
          if (response.data.detalles_requerimientos) {
            for (
              let i = 0;
              i < response.data.detalles_requerimientos.length;
              i++
            ) {
              const detalle = response.data.detalles_requerimientos[i];
              if (detalle.fecha_comprobante) {
                detalle.fecha_comprobante = new Date(detalle.fecha_comprobante);
              }
              const moneda = this.monedas.find(
                (m) => m.nombre === detalle.moneda
              );
              detalle.simbolo_moneda = moneda ? moneda.simbolo : null;
              detalle.moneda = moneda ? moneda.nombre : null;
            }
          }
        }
      },
      error(error) {
        console.error('Error al cargar las empresas', error);
      },
    });
  }

  getEmpresas_Api(): void {
    this._empresaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.empresas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getMoneda_Api(): void {
    this._monedaService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.monedas = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getTipoRequerimiento_Api(): void {
    this._tipoRequerimientoService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.tipos_concepto = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }
  getBanco_Api(): void {
    this._bancoService.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.bancos = response_data;
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontraron datos');
        }
      },
      error: (error) => {
        console.log('Error al consumir el servicio', error);
      },
    });
  }

  getClienteById_Api(id: any): void {
    const id_requerimiento = id.value;

    this.Editar_requerimiento_pago.getByid(id_requerimiento).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          this.requerimiento_pago = response_data;
          // Inicializa filteredClientes con todas las opciones disponibles

          setTimeout(() => {
            this.eventsSubject.next();
          }, 100);
        } else {
          console.error('No se encontraron datos.');
        }
      },
      error: (error) => {
        console.log('Error al llamar el servicio. => ', error);
      },
    });
  }

  Update_Requerimiento_Pago() {
    const FormRequerimiento_ = new FormData();
    const getValueOrEmpty = (value: any) => value ? value : "";
    FormRequerimiento_.append(
      'id_requerimiento_pago',
      this.Update_Requerimiento_pago_Form.get('id_requerimiento_pago')?.value
    );
    FormRequerimiento_.append(
      'empresa_id',
      this.Update_Requerimiento_pago_Form.get('empresa_id')?.value
    );
    FormRequerimiento_.append(
      'sede_id',
      this.Update_Requerimiento_pago_Form.value.sede_id
    );
    FormRequerimiento_.append(
      'area_id',
      this.Update_Requerimiento_pago_Form.value.area_id
    );
    FormRequerimiento_.append(
      'persona_id',
      this.Update_Requerimiento_pago_Form.value.persona_id
    );

    FormRequerimiento_.append(
      'tipo_concepto',
      this.Update_Requerimiento_pago_Form.value.tipo_concepto
    );

    FormRequerimiento_.append(
      'concepto',
      this.Update_Requerimiento_pago_Form.value.concepto
    );

    FormRequerimiento_.append(
      'tipo_pago',
      this.Update_Requerimiento_pago_Form.value.tipo_pago
    );

    FormRequerimiento_.append(
      'metodo_pago',
      this.Update_Requerimiento_pago_Form.value.metodo_pago
    );

    FormRequerimiento_.append(
      'comprobante',
      this.Update_Requerimiento_pago_Form.value.comprobante
    );

    FormRequerimiento_.append(
      'simbolo_moneda',
      this.Update_Requerimiento_pago_Form.value.simbolo_moneda
    );
    FormRequerimiento_.append(
      'moneda',
      this.Update_Requerimiento_pago_Form.value.moneda
    );
    FormRequerimiento_.append(
      'monto',
      this.Update_Requerimiento_pago_Form.value.monto
    );
    FormRequerimiento_.append(
      'nro_cuenta',
      getValueOrEmpty(this.Update_Requerimiento_pago_Form.get('nro_cuenta')?.value)
    );
    FormRequerimiento_.append(
      'banco_cuenta',
      
      getValueOrEmpty(this.Update_Requerimiento_pago_Form.get('banco_cuenta')?.value)
    );
    FormRequerimiento_.append(
      'titular_cuenta',
      
      getValueOrEmpty(this.Update_Requerimiento_pago_Form.get('titular_cuenta')?.value)
    );
    // // FormRequerimiento_.append('fecha_pago', this.Update_Requerimiento_pago_Form.value.fecha_pago
    // );

    FormRequerimiento_.append('fecha_pago', formatDate(this.Update_Requerimiento_pago_Form.value.fecha_pago, 'yyyy-MM-dd HH:mm:ss', 'en-US'));
    
    

    
    const url = 'desembolso/requerimiento-pago/update';
    this.Editar_requerimiento_pago.post(FormRequerimiento_, url).subscribe({
      next: (response: any) => {
        if (response.code === 201) {
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'El registro se realizó correctamente',
            life: 5000,
          });
          this.registroAgregado.emit(response.data);
          this.Update_Requerimiento_pago_Form.reset({
            id_cuenta:
              this.Update_Requerimiento_pago_Form.get('id_cuenta')?.value,
          });

          setTimeout(() => {
            this.closeModalEmmit.emit(true);
          }, 1000);
        }
      },
      error: (error) => {
        console.log('Error al llamar el servicio', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Ocurrió un error al procesar la solicitud',
        });
      },
    });
  }
  hideDialog() {
    this.closeModalEmmit.emit();
  }
}
