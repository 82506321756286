/* Angular Components */
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';

/* App Components */
import { RegistrarMovimientoCajaComponent } from '../registrar-movimiento-caja/registrar-movimiento-caja.component';
import { ResumenMovimientoCajaComponent } from '../resumen-movimiento-caja/resumen-movimiento-caja.component';

/* App Services */
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';
import { CommunicationService } from 'src/app/services/general-services/comunicacion.service';
import { DropdownModule } from 'primeng/dropdown';
import { PeriodoCajaService } from 'src/app/services/gestion-caja/periodo-caja.service';
import { CommonModule } from '@angular/common';

/* Component Config */
@Component({
	selector: 'app-detalles-caja',
	standalone: true,
	imports: [
		InputTextModule,
		FormsModule,
		FloatLabelModule,
		CardModule,
		TableModule,
		DialogModule,
		ButtonModule,
		RegistrarMovimientoCajaComponent,
		ResumenMovimientoCajaComponent,
		DropdownModule,
		CommonModule
	],
	templateUrl: './detalles-caja.component.html',
	styleUrl: './detalles-caja.component.css',
	providers: [Caja_Service]
})

/* Component */
export class DetallesCajaComponent {
	/* Component Data */
	@Input() RowData_Parent: any; // Data of Parent Component
	@Input() detalles_caja_response: any[] = [];
	@Output() Close_Modal_Detalles1Caja_Event = new EventEmitter<any>();
	DetallesCaja_Response: any = {};
	id_caja: any;
	formData: any = {};
	Movimientos: any[] = [];

	/* Child Component Config */
	Display_Modal_RegistrarMovimientoCaja: boolean = false;
	Display_Modal_VerResumenMovimientoCaja: boolean = false;
	Display_Modal_Ver_PeriodosCaja: boolean = false;
	tipo_movimiento: any = 'TODOS';
	PeriodosCaja: any[] = [];
	PeriodosCaja_Db: any[] = [];
	TiposMovimiento_Caja: any[] = [
		{
			id_tipo_movimiento: 0,
			nombre: 'TODOS'
		},
		{
			id_tipo_movimiento: 1,
			nombre: 'INGRESO'
		},
		{
			id_tipo_movimiento: 2,
			nombre: 'EGRESO'
		}
	];
	id_periodo_caja: number | 'TODOS' = 'TODOS';

	ngOnChanges(changes: SimpleChanges) {
		if (changes['RowData_Parent'] && changes['RowData_Parent'].currentValue) {
			this.formData = { ...changes['RowData_Parent'].currentValue };
			this.id_caja = this.formData.id_caja;

			if (this.id_caja) {
				this.Show_DetallesCaja();
				this.Get_PeriodosCaja_By_CajaId_Api();
			}
		}
		this.changeDetectorRef.detectChanges(); // Forzar actualización
	}

	eventsSubject: Subject<void> = new Subject<void>();

	/* Component Functions */
	constructor(
		private _Caja_Service: Caja_Service,
		private changeDetectorRef: ChangeDetectorRef,
		private communicationService: CommunicationService,
		private _PeriodoCaja_Service: PeriodoCajaService
	) { }

	ngOnInit(): void {
	
		this.communicationService.data$.subscribe((data) => {
		  this.RowData_Parent = data;
		});
	  }

	/* App Logical */
	Show_DetallesCaja(): void {
		if (!this.id_caja) {
			return;
		}

		const request = {
			'with-movimiento': this.id_caja
		};

		this._Caja_Service.getByOther(request).subscribe({
			next: (response: any) => {
				if (response.status === 'OK') {
					this.DetallesCaja_Response = response.data;
					this.Movimientos = this.DetallesCaja_Response.movimientos;
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.error('Error al consumir el servicio', error);
			},
		});
	}

	Get_Movimientos_By_Periodo_TipoMovimiento(): void {
		this.Movimientos = this.DetallesCaja_Response.movimientos.filter((movimiento: any) => {
			const matchPeriodo = this.id_periodo_caja == 'TODOS' || movimiento.periodo_caja_id == this.id_periodo_caja;
			const matchTipo = this.tipo_movimiento == 'TODOS' || movimiento.tipo == this.tipo_movimiento;

			return matchPeriodo && matchTipo;
		});
	}

	Get_PeriodosCaja_By_CajaId_Api(): void {
		const request = {
			'caja': this.id_caja
		}
		this._PeriodoCaja_Service.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.PeriodosCaja = [...response_data];
					this.PeriodosCaja_Db = [...response_data];
					this.PeriodosCaja.unshift({ id_periodo_caja: 'TODOS', nombre: 'TODOS' });
				} else {
					console.error('No se enconrtó datos');
				}
			},
			error: (error) => {
				console.log("Error al consumir el servicio", error);
			}
		});
	}


	Open_Modal_RegistrarMovimientoCaja() {
		this.Display_Modal_RegistrarMovimientoCaja = true;
	}
	Close_Modal_RegistrarMovimientoCaja() {
		this.Display_Modal_RegistrarMovimientoCaja = false;
	}

		Open_Modal_VerResumenMovimientoCaja () {
			this.Display_Modal_VerResumenMovimientoCaja = true;
		}
		Close_Modal_VerResumenMovimientoCaja () {
			this.Display_Modal_VerResumenMovimientoCaja = false;
		}
		Close_Modal_RegistrarCaja(): void {
			
			this.Close_Modal_Detalles1Caja_Event.emit();
		}
		Open_Modal_Ver_PeriodosCaja(): void {
			this.Display_Modal_Ver_PeriodosCaja = true;
		}
		Close_Modal_Ver_PeriodosCaja(): void {
			this.Display_Modal_Ver_PeriodosCaja = false;
		}


		ActualizarRegistroAgregado(nuevoRegistro: any): void {
			if (nuevoRegistro && nuevoRegistro.id_movimiento_caja) {
			  const index = this.detalles_caja_response.findIndex(
				(item) =>
				  item.id_movimiento_caja === nuevoRegistro.id_movimiento_caja
			  );
			  if (index > -1) {
				// Actualiza el registro existente
				this.detalles_caja_response[index] = nuevoRegistro;
			  } else {
				// Agrega nuevo registro al inicio de la lista
				this.detalles_caja_response.unshift(nuevoRegistro);
			  }
		
			  // Llama a la API para obtener la lista completa actualizada
			  this.Show_DetallesCaja();
			} else {
			  console.error('El nuevo registro es undefined o no tiene un id válido');
			}
			//this.hideDialog();
		  }
		

}

