import { CommonModule, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { Subject } from 'rxjs';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Desembolso_Service } from 'src/app/services/gestion-desembolso/desembolso.service';
import { Liquidacion_Gastos_Service } from 'src/app/services/gestion-desembolso/liquidacion-gastos.service';
import { ReporteDesembolsoService } from 'src/app/services/gestion-desembolso/reporte-desembolso.service';

@Component({
	selector: 'app-editar-liquidacion-gastos',
	standalone: true,
	imports: [
		CardModule,
		InputNumberModule,
		TableModule,
		DropdownModule,
		ToastModule,
		CalendarModule,
		ButtonModule,
		FormsModule,
		InputTextModule,
		TooltipModule,
		DividerModule,
		CommonModule,
		SelectButtonModule,
		FontAwesomeModule,
		DialogModule,
		FormsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule
	],
	templateUrl: './editar-liquidacion-gastos.component.html',
	styleUrl: './editar-liquidacion-gastos.component.css',

})
export class EditarLiquidacionGastosComponent {
	@Input() data: any
	@Output() Close_Modal_Editar = new EventEmitter<void>();
	@Output() Close_Modal_Event3 = new EventEmitter<boolean>();

	Update_LiquidacionGasto_Form: FormGroup;
	desembolsos: any[] = [];
	desembolso: any = {};


	eventsSubject: Subject<void> = new Subject<void>();
	monedas: any[] = [];

	Tipo_comprobante: any[] = [
		{ label: 'BOLETA', value: 'BOLETA' },
		{ label: 'FACTURA', value: 'FACTURA' },
	];
	loading: boolean;
	desembolso_id: any;

	constructor(
		private fb: FormBuilder,
		private _LiquidacionGastos_Service: Liquidacion_Gastos_Service,
		private _Moneda_Service: Moneda_Service,
		private _ReporteDesembolso_Service: ReporteDesembolsoService,
		private _Desembolso_Service: Desembolso_Service,
		private changeDetectorRef: ChangeDetectorRef,
		private _LiquidacionGasto_Service: Liquidacion_Gastos_Service,
		private _Message_Service: MessageService
	) {
		this.Update_LiquidacionGasto_Form = this.fb.group({
			desembolso_id: [null, Validators.required],
			tipo_cambio: [null, Validators.required],
			monto_desembolso: [null, Validators.required],
			gasto_desembolso: [null, Validators.required],
			saldo_desembolso: [null, Validators.required],
			total_gastos: [null, Validators.required],
			detalles: [null, Validators.required],
			detalles_liquidacion_gasto__db: this.fb.array([]),
			detalles_liquidacion_gasto__new: this.fb.array([]),
			detalles_liquidacion_gasto__del: this.fb.array([]),
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['data'] && changes['data'].currentValue) {
			this.Update_LiquidacionGasto_Form.patchValue(changes['data'].currentValue);
			this.desembolso_id = this.Update_LiquidacionGasto_Form.get('desembolso_id')?.value;
			if (this.desembolso_id) {
				this.Get_Desembolso();
			}
		}
		this.changeDetectorRef.detectChanges();
	}

	ngOnInit(): void {
		this.Get_Liquidacion_Gastos_By_Id();
		this.Get_Desembolsos_Api();
		this.getMoneda_Api();
	}
	Get_Desembolsos_Api(): void {
		const request = {
			'estado': 'APROBADO'
		};
		this._ReporteDesembolso_Service.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.desembolsos = response_data;
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}
	Get_Desembolso(): void {
		this._Desembolso_Service.getByid(this.Update_LiquidacionGasto_Form.get('desembolso_id')?.value).subscribe({
			next: (response: any) => {
				if (response.data) {
					this.desembolso = {...response.data};
					this.Update_LiquidacionGasto_Form.patchValue({
						monto_desembolso: this.desembolso.monto
					});
				} else {
					console.log('No se encontraron datos.');
				}
			},
		});
	}

	getMoneda_Api(): void {
		this._Moneda_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.monedas = response_data;
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Delete_DetalleLiquidacionGasto_Db(detalle: any, index: number): void {
		this.detalles_liquidacion_gasto__db.removeAt(index);
		this.Add_DetalleLiquidacionGastos_Del(detalle)
		this.Calculate_GastoTotal();
	}
	Delete_DetalleLiquidacionGasto_New(index: number): void {
		this.detalles_liquidacion_gasto__new.removeAt(index);
		this.Calculate_GastoTotal();
	}

	Calculate_GastoTotal(): void {
		this.desembolso.gasto_desembolso = 0;
		this.detalles_liquidacion_gasto__db.value.forEach((detalle: any) => {
			this.desembolso.gasto_desembolso += parseFloat(detalle.monto);
		});
		this.detalles_liquidacion_gasto__new.value.forEach((detalle: any) => {
			this.desembolso.gasto_desembolso += parseFloat(detalle.monto);
		});
		this.detalles_liquidacion_gasto__del.value.forEach((detalle: any) => {
			this.desembolso.gasto_desembolso -= parseFloat(detalle.monto);
		});
		this.desembolso.saldo_desembolso = this.Update_LiquidacionGasto_Form.get('monto_desembolso')?.value - this.desembolso.gasto_desembolso;
		
		this.Update_LiquidacionGasto_Form.patchValue({
			gasto_desembolso: this.desembolso.gasto_desembolso,
			saldo_desembolso: this.desembolso.saldo_desembolso
		})
	}
	Select_FileComprobante_Db(event: any, index: number) {
		const file = event.target.files[0];
		if (file) {
			const requerimiento = this.detalles_liquidacion_gasto__db.at(index) as FormGroup;
			requerimiento.patchValue({ comprobante: file });
		}
	}

	get detalles_liquidacion_gasto__del(): FormArray {
		return this.Update_LiquidacionGasto_Form.get('detalles_liquidacion_gasto__del') as FormArray;
	}
	get detalles_liquidacion_gasto__new(): FormArray {
		return this.Update_LiquidacionGasto_Form.get('detalles_liquidacion_gasto__new') as FormArray;
	}
	get detalles_liquidacion_gasto__db(): FormArray {
		return this.Update_LiquidacionGasto_Form.get('detalles_liquidacion_gasto__db') as FormArray;
	}

	Add_DetalleLiquidacionGastos_Del(detalle?: any): void {
		this.detalles_liquidacion_gasto__del.push(this.Create_Requerimiento_Del(detalle));
	}
	Add_DetalleLiquidacionGastos_Db(detalle?: any): void {
		this.detalles_liquidacion_gasto__db.push(this.Create_Requerimiento_Db(detalle));
	}
	Create_Requerimiento_Del(detalle?: any): FormGroup {
		return this.fb.group({
			id_detalle_liquidacion_gasto: [detalle ? detalle.id_detalle_liquidacion_gasto : null, Validators.required],
			concepto: [detalle ? detalle.concepto : null, Validators.required],
			fecha_comprobante: [detalle ? detalle.fecha_comprobante : null, Validators.required],
			tipo_comprobante: [detalle ? detalle.tipo_comprobante : null, Validators.required],
			nro_comprobante: [detalle ? detalle.nro_comprobante : null, Validators.required],
			simbolo_moneda: [detalle ? detalle.simbolo_moneda : null, Validators.required],
			moneda: [detalle ? detalle.moneda : null, Validators.required],
			monto: [detalle ? detalle.monto : null, Validators.required],
			comprobante: [detalle ? detalle.comprobante : null, Validators.required],
			detalles: [detalle ? detalle.detalles : null, Validators.required],
		});
	}
	Create_Requerimiento_Db(detalle?: any): FormGroup {
		return this.fb.group({
			id_detalle_liquidacion_gasto: [detalle ? detalle.id_detalle_liquidacion_gasto : null, Validators.required],
			concepto: [detalle ? detalle.concepto : null, Validators.required],
			fecha_comprobante: [detalle ? detalle.fecha_comprobante : null, Validators.required],
			tipo_comprobante: [detalle ? detalle.tipo_comprobante : null, Validators.required],
			nro_comprobante: [detalle ? detalle.nro_comprobante : null, Validators.required],
			simbolo_moneda: [detalle ? detalle.simbolo_moneda : null, Validators.required],
			moneda: [detalle ? detalle.moneda : null, Validators.required],
			monto: [detalle ? detalle.monto : null, Validators.required],
			comprobante: [detalle ? detalle.comprobante : null, Validators.required],
			detalles: [detalle ? detalle.detalles : null, Validators.required],
		});
	}

	Add_DetalleLiquidacionGastos_New(): void {
		this.detalles_liquidacion_gasto__new.push(this.Create_Requerimiento_New());
	}
	Create_Requerimiento_New(): FormGroup {
		return this.fb.group({
			concepto: [null, Validators.required],
			fecha_comprobante: [null, Validators.required],
			tipo_comprobante: [null, Validators.required],
			nro_comprobante: [null, Validators.required],
			simbolo_moneda: [null, Validators.required],
			moneda: [null, Validators.required],
			monto: [null, Validators.required],
			comprobante: [null, Validators.required],
			detalles: [null, Validators.required],
		});
	}

	Get_Liquidacion_Gastos_By_Id(): void {
		this._LiquidacionGastos_Service.getByid(this.data.id_liquidacion_gasto).subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Update_LiquidacionGasto_Form.patchValue(response.data);
					if (response.data.detalles_liquidacion_gasto) {
						for (let i = 0; i < response.data.detalles_liquidacion_gasto.length; i++) {
							const detalle = response.data.detalles_liquidacion_gasto[i];
							if (detalle.fecha_comprobante) {
								detalle.fecha_comprobante = new Date(detalle.fecha_comprobante);
							}
							const moneda = this.monedas.find(m => m.nombre === detalle.moneda);
							detalle.simbolo_moneda = moneda ? moneda.simbolo : null;
							detalle.moneda = moneda ? moneda.nombre : null;

							this.Add_DetalleLiquidacionGastos_Db(detalle);
						}
					}
				}
			}, error(error) {
				console.error('Error al cargar las empresas', error);
			},
		});
	}
	Update_LiquidacionGastos(): void {
		const LiquidacionGasto_Form = new FormData();

		LiquidacionGasto_Form.append('id_liquidacion_gasto', this.data.id_liquidacion_gasto);
		LiquidacionGasto_Form.append('desembolso_id', this.Update_LiquidacionGasto_Form.get('desembolso_id')?.value);
		LiquidacionGasto_Form.append('detalles', this.Update_LiquidacionGasto_Form.get('detalles')?.value);

		this.detalles_liquidacion_gasto__db.controls.forEach((detalle, index) => {
			const DetalleForm = detalle.value;
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][id_detalle_liquidacion_gasto]`, DetalleForm.id_detalle_liquidacion_gasto);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][concepto]`, DetalleForm.concepto);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][fecha_comprobante]`, formatDate(DetalleForm.fecha_comprobante, 'yyyy-MM-dd HH:mm:ss', 'en-US'));
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][tipo_comprobante]`, DetalleForm.tipo_comprobante);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][simbolo_moneda]`, DetalleForm.simbolo_moneda);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][moneda]`, DetalleForm.moneda);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][monto]`, DetalleForm.monto);
			if (DetalleForm.comprobante) { LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][comprobante]`, DetalleForm.comprobante); }
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][nro_comprobante]`, DetalleForm.nro_comprobante);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__db[${index}][detalles]`, DetalleForm.detalles);
		})

		this.detalles_liquidacion_gasto__new.controls.forEach((detalle, index) => {
			const DetalleForm = detalle.value;
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][concepto]`, DetalleForm.concepto);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][fecha_comprobante]`, formatDate(DetalleForm.fecha_comprobante, 'yyyy-MM-dd HH:mm:ss', 'en-US'));
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][tipo_comprobante]`, DetalleForm.tipo_comprobante);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][simbolo_moneda]`, DetalleForm.simbolo_moneda);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][moneda]`, DetalleForm.moneda);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][monto]`, DetalleForm.monto);
			if (DetalleForm.comprobante) { LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][comprobante]`, DetalleForm.comprobante); }
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][nro_comprobante]`, DetalleForm.nro_comprobante);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__new[${index}][detalles]`, DetalleForm.detalles);
		});
		this.detalles_liquidacion_gasto__del.controls.forEach((detalle, index) => {
			const DetalleForm = detalle.value;
			LiquidacionGasto_Form.append(`detalles_liquidacion_gasto__del[${index}][id_detalle_liquidacion_gasto]`, DetalleForm.id_detalle_liquidacion_gasto);
		})

		const url = 'desembolso/liquidacion-gasto/update';
		this._LiquidacionGasto_Service.post(LiquidacionGasto_Form, url).subscribe({
			next: (response: any) => {
				if (response.status == 'CREATED') {
					this._Message_Service.add({
						severity: 'success',
						summary: response.title,
						detail: response.message,
						life: 3000
					});
				} else {
					this._Message_Service.add({
						severity: 'error',
						summary: response.title,
						detail: response.message,
						life: 3000
					});
				}
			},
			error: (err) => {
				this._Message_Service.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Ocurrió al consumir el servicio.',
				});
			},
		});
	}

	hideDialog() {
		this.Close_Modal_Event3.emit();
	}
	
	resetForm(): void {
		this.detalles_liquidacion_gasto__db.controls = [];
		this.desembolso = {
			desembolso_id: null,
			monto: null,
			gasto_desembolso: null,
			saldo_desembolso: null,
			detalles: null
		};
	}

}
