<p-toast></p-toast>
<p-card class="custom-card">
    <form class="formula" [formGroup]="Update_Desembolso_Form">
        <div class="row mt-5 mb-5">
            <!-- Primera fila: Empresa, Sede, Área -->
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [showClear]="true" placeholder="Empresa" [options]="empresas" optionValue="id_empresa"
                        optionLabel="nombre" formControlName="empresa_id"
                        (onChange)="Get_SedesByEmpresaId_Api($event)">
                    </p-dropdown>
                    <label>Empresa</label>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [showClear]="true" placeholder="Sede" [options]="sedes" optionValue="id_sede"
                        optionLabel="nombre_largo" formControlName="sede_id" (onChange)="Get_AreasBySedeId_Api($event)">
                    </p-dropdown>
                    <label>Sede</label>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [showClear]="true" placeholder="Área" [options]="areas" optionValue="id_area"
                        optionLabel="nombre" formControlName="area_id">
                    </p-dropdown>

                    <label>Área</label>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [showClear]="true" placeholder="Solicitante" [options]="personas"
                        optionValue="id_persona" optionLabel="nombre_completo" formControlName="persona_id__autoriza">
                    </p-dropdown>

                    <label>Persona Autorizada</label>
                </p-floatLabel>
            </div>
        </div>
        <div class="row mt-5 mb-5">
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [showClear]="true" placeholder="Empresa" [options]="empresas_cuenta" optionValue="id_empresa"
                        optionLabel="nombre" formControlName="empresa_id_cuenta"
                        (onChange)="Get_SedesByEmpresaId_Api($event)">
                    </p-dropdown>
                    <label>Empresa</label>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [showClear]="true" placeholder="Sede" [options]="sedes_cuenta" optionValue="id_sede"
                        optionLabel="nombre" formControlName="sede_id_cuenta" (onChange)="Get_AreasBySedeId_Api($event)">
                    </p-dropdown>
                    <label>Sede</label>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [showClear]="true" placeholder="Cuenta" [options]="cuentas"
                        optionValue="id_cuenta" optionLabel="nombre" formControlName="cuenta_id">
                    </p-dropdown>
                    <label>Cuenta</label>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [showClear]="true" placeholder="Periodo" [options]="periodos_cuenta"
                        optionValue="id_periodo_cuenta" optionLabel="nombre" formControlName="periodo_cuenta_id">
                    </p-dropdown>
                    <label>Periodo</label>
                </p-floatLabel>
            </div>
        </div>
        <div class="row mt-5 mb-5">            
            <div class="col-lg-2">
                <p-floatLabel>
                    <input type="text" class="input_custom" title="Solo se permiten números y guiones" pInputText
                        formControlName="detalles" />
                    <label>Detalles</label>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <input type="text" class="input_custom" title="Solo se permiten números y guiones" pInputText
                        formControlName="observaciones" />
                    <label>Observaciones</label>
                </p-floatLabel>
            </div>

            <!-- Tercera fila: Tipo Pago, Método Pago, Comprobante -->
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-calendar optionValue="fecha_pago" [showTime]="false" dateFormat="yy-mm-dd"
                        formControlName="fecha_programacion">
                    </p-calendar>
                    <label>Fecha Programacion</label>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <!-- <p-dropdown [showClear]="true" [options]="periodos_cuenta" optionValue="id_cuenta_persona" optionLabel="periodo_cuenta_id" 
						formControlName="periodo_cuenta_id"></p-dropdown>
					<label>Periodo</label> -->
                </p-floatLabel>
            </div>
        </div>

        <div class="register-button text-center button-group">
            <button pButton type="button" class="p-button-success mb-3" icon="pi pi-save" label="Guardar cambios"
                (click)="Update_Desembolso_Api()">
            </button>
        </div>
    </form>
</p-card>