<p-card class="custom-card">
    <form [formGroup]="form">
        <div class="row mt-5 mb-5">
            <div class="col-lg-2">
                <span class="p-float-label">
                    <p-dropdown [options]="desembolsos" optionLabel="codigo" optionValue="id_desembolso"
                        formControlName="id_desembolso" [filter]="true">
                    </p-dropdown>
                    <label>Desembolso</label>
                    <small
                        *ngIf="form.get('id_desembolso')?.invalid && (form.get('id_desembolso')?.dirty || form.get('id_desembolso')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('id_desembolso') }}
                    </small>
                </span>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [options]="Cajas" optionLabel="nombre" optionValue="id_caja" formControlName="id_caja">
                    </p-dropdown>
                    <label>Caja</label>
                    <small
                        *ngIf="form.get('id_caja')?.invalid && (form.get('id_caja')?.dirty || form.get('id_caja')?.touched)"
                        class="p-error">
                        {{ getErrorMessage('id_caja') }}
                    </small>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [options]="Tipos" optionLabel="nombre" optionValue="nombre"
                        formControlName="tipo"></p-dropdown>
                    <label>Tipo</label>
                    <small *ngIf="errors.tipo" class="p-error">{{ errors.tipo }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-calendar [showTime]="false" dateFormat="yy/mm/dd" formControlName="fecha"
                        appendTo="body"></p-calendar>
                    <label>Fecha</label>
                    <small *ngIf="errors.fecha" class="p-error">{{ errors.fecha }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [options]="PeriodosCaja" optionValue="id_periodo_caja" optionLabel="nombre"
                        formControlName="id_periodo_caja"></p-dropdown>
                    <label>Periodo</label>
                    <small *ngIf="errors.id_periodo" class="p-error">{{ errors.id_periodo }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [options]="TiposConcepto" optionLabel="nombre" optionValue="nombre"
                        formControlName="tipo_concepto"></p-dropdown>
                    <label>Tipo Concepto</label>
                    <small *ngIf="errors.tipo_concepto" class="p-error">{{ errors.tipo_concepto }}</small>
                </p-floatLabel>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-lg-2">
                <p-floatLabel>
                    <input type="text" pInputText formControlName="concepto" class="w-100">
                    <label>Concepto</label>
                    <small *ngIf="errors.concepto" class="p-error">{{ errors.concepto }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-1">
                <p-floatLabel>
                    <p-dropdown [options]="Monedas" optionLabel="nombre" optionValue="nombre"
                        formControlName="moneda"></p-dropdown>
                    <label>Moneda</label>
                    <small *ngIf="errors.moneda" class="p-error">{{ errors.moneda }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-1">
                <p-floatLabel>
                    <p-dropdown [options]="Monedas" optionLabel="simbolo" optionValue="simbolo"
                        formControlName="simbolo_moneda"></p-dropdown>
                    <label>Símbolo Moneda</label>
                    <small *ngIf="errors.simbolo_moneda" class="p-error">{{ errors.simbolo_moneda }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <input type="text" pInputText formControlName="monto" class="w-100">
                    <label>Monto</label>
                    <small *ngIf="errors.monto" class="p-error">{{ errors.monto }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [options]="TiposPago" optionLabel="nombre" optionValue="nombre"
                        formControlName="tipo_pago"></p-dropdown>
                    <label>Tipo Pago</label>
                    <small *ngIf="errors.tipo_pago" class="p-error">{{ errors.tipo_pago }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel>
                    <p-dropdown [options]="MetodosPago" optionLabel="nombre" optionValue="nombre"
                        formControlName="metodo_pago"></p-dropdown>
                    <label>Método Pago</label>
                    <small *ngIf="errors.metodo_pago" class="p-error">{{ errors.metodo_pago }}</small>
                </p-floatLabel>
            </div>
            <div class="col-lg-2">
                <p-floatLabel class="w-100">
                    <p-dropdown [options]="TiposComprobante" optionLabel="nombre" optionValue="nombre"
                        formControlName="tipo_comprobante" class="w-100"></p-dropdown>
                    <label>Tipo comprobante</label>
                    <small *ngIf="errors.tipo_comprobante" class="p-error">{{ errors.tipo_comprobante }}</small>
                </p-floatLabel>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-lg-12">
                <p-floatLabel>
                    <!-- <input type="text" pInputText formControlName="comprobante" class="w-100"> -->
                    <input type="file" (change)="onFileSelect($event)" class="form-control">
                    <label>Comprobante</label>
                    <small *ngIf="errors.comprobante" class="p-error">{{ errors.comprobante }}</small>
                </p-floatLabel>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-end">
                <p-button label="Registrar" severity="success" (click)="Registrar_MovimientoCaja($event)"
                    class="mr-3"></p-button>
                <p-button label="Cancelar" severity="danger" (click)="Close_Modal_RegistrarMovimientoCaja()"></p-button>
            </div>
        </div>
    </form>
</p-card>