import { Component, Input, OnInit } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ButtonAction } from 'src/app/common/button-action';
import { accionConst } from 'src/app/constants/general-constats';
import { Subject } from 'rxjs';
import { tableCol } from 'src/app/common/table-cols';
import { FilterForm } from 'src/app/common/filter-form';
import { TagModule } from 'primeng/tag';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SplitButtonModule } from 'primeng/splitbutton';

import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { ReporteDesembolsoService } from 'src/app/services/gestion-desembolso/reporte-desembolso.service';
import { Liqu_gastos_Response } from 'src/app/response/gestion-desembolso/liquidacion-gastos-response';

import { UpdateEstadoDesembolsoComponent } from '../../update-estado-desembolso/update-estado-desembolso.component';
import { CommunicationService } from 'src/app/services/general-services/comunicacion.service';

import { ViewProgramacionDesembolsoComponent } from '../view-programacion-desembolso/view-programacion-desembolso.component';
import { EditarDesembolsoComponent } from '../editar-desembolso/editar-desembolso.component';

@Component({
	selector: 'app-reporte-desembolso',
	standalone: true,
	imports: [
		FontAwesomeModule,
		TableModule,
		EditarDesembolsoComponent,
		CommonModule,
		ButtonModule,
		DialogModule,
		FormsModule,
		PaginatorModule,
		InputTextModule,
		TableModule,
		TagModule,
		InputTextModule,
		MultiSelectModule,
		DropdownModule,
		HttpClientModule,
		CommonModule,
		IconFieldModule,
		InputIconModule,
		ToastModule,
		ConfirmDialogModule,
		SplitButtonModule,
		UpdateEstadoDesembolsoComponent,
		ViewProgramacionDesembolsoComponent
	],
	templateUrl: './reporte-desembolso.component.html',
	styleUrl: './reporte-desembolso.component.css',
	providers: [
		BsModalService,
		SharedDataService,
		ConfirmationService,
		MessageService,
	],
})
export class ReporteDesembolsoComponent implements OnInit {
	displayModal: boolean = false;
	Display_Modal_ProgramacionDesembolso: boolean = false;
	rowData: any;
	selectedRowData: any;
	modal_editar: boolean = false;
	@Input() Desembolso_Response: any[] = [];
	faTableList = faTableList;
	config = bsConfigModal;
	modalref: BsModalRef = new BsModalRef();
	totalRecords: number = 0;
	loading: boolean = false;
	titleModal: string = '';
	example: {};

	eventsSubject: Subject<void> = new Subject<void>();
	valuesTable: Liqu_gastos_Response;

	cols: tableCol[] = [
		{ field: 'id_desembolso', header: 'ID' },
		{ field: 'nombre_empresa', header: 'Empresa' },
		{ field: 'nro_cuenta__recaudadora', header: 'Cuenta recaudadora' },
		{ field: 'nombre_periodo', header: 'Periodo' },
		{ field: 'monto', header: 'Monto' },
		{ field: 'persona_solicita', header: 'Solicita' },
		{ field: 'persona_autoriza', header: 'Autoriza' },
		{ field: 'detalles_estado', header: 'Detalles Estado' },
		{ field: 'estado', header: 'Estado' },
		{ field: 'Accion', header: ' Accion' },
	
	];

	filterForm: FilterForm[] = [];

	OptionsEstado_Button: any = [];

	constructor(
		private _ReporteDesembolso_Service: ReporteDesembolsoService,
		private communicationService: CommunicationService
	) { }

  ngOnInit(): void {
    
    this.Get_Desembolsos_Api();
    this.communicationService.data$.subscribe((data) => {
      // Verifica que 'data' sea un array
    //  console.log('Desembolso_Response:', data);
      if (Array.isArray(data)) {
        this.Desembolso_Response = data;
      } else {
        //console.error('La data recibida no es un array:', data);
        this.Desembolso_Response = [];
      }
    });
  }

	optionsEstadoButtonMap: Map<any, MenuItem[]> = new Map();
	getOptionsEstado_Button(rowData: any): MenuItem[] {
		if (!this.optionsEstadoButtonMap.has(rowData)) {
			const options = [
				{
					label: 'APROBAR',
					command: () => this.Open_Modal(rowData, 'APROBADO'),
				},
				{
					label: 'OBSERVAR',
					command: () => this.Open_Modal(rowData, 'OBSERVADO'),
				},
				{
					label: 'RECHAZAR',
					command: () => this.Open_Modal(rowData, 'RECHAZADO'),
				},
				{
					label: 'DESEMBOLSAR',
					command: () => this.Open_Modal(rowData, 'DESEMBOLSADO'),
				},
			];
			this.optionsEstadoButtonMap.set(rowData, options);
		}
		return this.optionsEstadoButtonMap.get(rowData) || [];
	}

	ActualizarEstadoAgregado(nuevoRegistro: any): void {
		if (nuevoRegistro && nuevoRegistro.id_desembolso) {
			const index = this.Desembolso_Response.findIndex(
				(item) => item.id_desembolso === nuevoRegistro.id_desembolso
			);

			if (index > -1) {
				this.Desembolso_Response[index] = {
					...this.Desembolso_Response[index],
					estado: nuevoRegistro.estado,
					detalles_estado: nuevoRegistro.detalles_estado,
				};
			} else {
				this.Desembolso_Response.push(nuevoRegistro);
			}

			this.hideDialog();
		} else {
			console.error('El nuevo registro es undefined o no tiene un id válido');
		}
	}

	hideDialog() {
		this.displayModal = false;
	}

	getEstadoClass(estado: string): string {
		// Normaliza el valor del estado
		const estadoNormalizado = estado ? estado.trim().toLowerCase() : '';

		switch (estadoNormalizado) {
			case 'aprobado':
				return 'estado-aprobado';

			case 'rechazado':
				return 'estado-rechazado';
			case 'desembolsado':
				return 'estado-desembolsado';

			case 'observado':
				return 'estado-observado';
			case 'programado':
				return 'estado-programado';
			default:
				return '';
		}
	}

  Get_Desembolsos_Api() {
   
  
    //this.loading = false;
    this._ReporteDesembolso_Service.get(null).subscribe({
      next: (response: any) => {
        const response_data = response.data;
        
        if (Array.isArray(response_data)) {
          this.Desembolso_Response = response_data;
        } else {
          this.Desembolso_Response = Object.values(response); // Si no es un array, asigna un array vacío
        }
  
        //this.loading = true;
        if (this.Desembolso_Response.length > 0) {
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          //console.error('No se encontró ningún dato');
        }
      },
      error: (error) => {
        this.loading = true;
        console.log('Error al llamar el servicio', error);
      },
    });
  }

  ActulizarEditarRegistroAgregado(nuevoRegistro: any): void {
	if (nuevoRegistro && nuevoRegistro.id_requerimiento_pago) {
		const index = this.Desembolso_Response.findIndex(
			(item) =>
				item.id_requerimiento_pago === nuevoRegistro.id_requerimiento_pago
		);
		if (index > -1) {
			// Actualiza el registro existente
			this.Desembolso_Response[index] = nuevoRegistro;
		} else {
			// Agrega nuevo registro al inicio de la lista
			this.Desembolso_Response.unshift(nuevoRegistro);
		}

		// Llama a la API para obtener la lista completa actualizada
		this.Get_Desembolsos_Api();
	} else {
		console.error('El nuevo registro es undefined o no tiene un id válido');
	}
	this.hideDialog();
}
  
  

  Open_Modal(rowData: any, estado: string) {
    this.rowData = { ...rowData };
    this.rowData.estado = estado;
    this.displayModal = true;
  }
  Close_Modal() {
    this.displayModal = false;
  }

	Close_Modal_ProgramacionDesembolso() {
		this.Display_Modal_ProgramacionDesembolso = false;
	}
	Open_Modal_ProgramacionDesembolso() {
		this.Display_Modal_ProgramacionDesembolso = true;
	}
	Close_Modal_Editar() {
		this.modal_editar = false;
	}
	Editar(rowData: any) {
		const id_desembolso_id = rowData.id_desembolso;
		this.selectedRowData = { ...rowData }; // Clonar los datos para evitar mutaciones
		this.modal_editar = true; // Abrir el modal de edición
	  }
}
