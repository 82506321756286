import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LoginRequest } from 'src/app/request/login/login-request';
import { LoginResponse } from 'src/app/response/login/login-response';
import { CrudService } from './crud.service';
import { behaidorInterface } from '../interfaces/behaidor-interface';
import { urlConstants } from 'src/app/constants/url-constants';

@Injectable({
	providedIn: 'root',
})

export class validate_frontend_Service
	extends CrudService<LoginRequest, LoginResponse>
	implements behaidorInterface<LoginResponse[]>
{
	private dataSource = new BehaviorSubject<LoginResponse[]>([]);

	constructor(protected http: HttpClient) {
		super(http, urlConstants.validate_frontend);
	}

	setValueBehaidor(value: LoginResponse[]): void {
		this.dataSource.next(value);
	}
	getValueBehaidorSubject() {
		return this.dataSource.getValue();
	}
	clearBehaidorSubject(): void {
		this.dataSource.next([]);
	}
}
