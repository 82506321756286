
import { CommonModule } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { PeriodoService } from 'src/app/services/general-services/periodo.service';
import { Desembolso_Service } from 'src/app/services/gestion-desembolso/desembolso.service';

@Component({
	selector: 'app-view-programacion-desembolso',
	standalone: true,
	imports: [CommonModule, DropdownModule, FormsModule, ButtonModule],
	templateUrl: './view-programacion-desembolso.component.html',
	styleUrl: './view-programacion-desembolso.component.css'
})
export class ViewProgramacionDesembolsoComponent {
	@Input() DataComponent_Parent: any;
	@Output() Close_Modal_ProgramacionDesembolso_Event = new EventEmitter<void>();
	Cuentas_Response: any = {};
	Periodos_Response: any = [];
	Empresas_Response: any = [];
	id_periodo:  | null = null;
	id_empresa:  | null = null;
	constructor(
		private _Desembolso_Service: Desembolso_Service,
		private _Desembolso__Service1: Desembolso_Service,
		private _Periodo_Service: PeriodoService,
		private _Empresa_Service: Empresa_Service
	) { }

	ngOnInit () : void {
		this.Get_Cuentas_With_Desembolsos_Api();
		this.Get_Periodos_Api();
		this.Get_Empresas_Api();
	}

	Get_Periodos_Api () : void {
		const headers = new HttpHeaders({
			Authorization: `Bearer ${localStorage.getItem('token')}`, // Agregar el token aquí
		  });
		this._Periodo_Service.get(null, headers).subscribe({
			next: (response: any) => {
				this.Periodos_Response = response.data;
			}, error: () => {
				console.log('Ocurrió un error al consumir el servicio.');
			}
		});
	}
	Get_Empresas_Api () : void {
		const headers = new HttpHeaders({
			Authorization: `Bearer ${localStorage.getItem('token')}`, // Agregar el token aquí
		  });
		this._Empresa_Service.get(null, headers).subscribe({
			next: (response: any) => {
				this.Empresas_Response = response.data;
			}, error: () => {
				console.log('Ocurrió un error al consumir el servicio.');
			}
		});
	}


	Get_Cuentas_With_Desembolsos_Combined_Api () : void {
		if (!this.id_periodo || !this.id_empresa) {
		  return;
		}
	  
		const headers = new HttpHeaders({
		  Authorization: `Bearer ${localStorage.getItem('token')}`, // Agregar el token aquí
		});
	  
		const urlPath = `cuentas--with--desembolsos/empresa/${this.id_empresa}/periodo/${this.id_periodo}`;
	  
		this._Desembolso_Service.getByOther({}, urlPath, null, headers).subscribe({
		  next: (response: any) => {
			this.Cuentas_Response = response.data;
			console.log('Datos recibidos:', response);
		  },
		  error: () => {
			console.error('Error al consumir el servicio.');
		  },
		});
	  }

	  Get_Cuentas_With_Desembolsos_By_PeriodoId_Api () : void {
		this.Get_Cuentas_With_Desembolsos_Combined_Api();
	  }
	  
	  Get_Empresas_With__By_PeriodoId_Api () : void {
		this.Get_Cuentas_With_Desembolsos_Combined_Api();
	  }
	  


	Get_Cuentas_With_Desembolsos_Api () : void {
		const headers = new HttpHeaders({
			Authorization: `Bearer ${localStorage.getItem('token')}`, // Agregar el token aquí
		  });

		this._Desembolso_Service.getByOther({}, 'cuentas--with--desembolsos', null, headers).subscribe({
			next: (response: any) => {
				this.Cuentas_Response = response.data;
			},
			error: () => {
				console.log('error');
			}
		});
	}
	closeModal () : void {
		
		this.id_empresa = null;
		this.id_periodo = null;
	  
		// Limpiar la respuesta de cuentas
		this.Cuentas_Response = [];
	  this.Get_Cuentas_With_Desembolsos_Api();
	  this.Close_Modal_ProgramacionDesembolso_Event.emit();
		
	  }
	  

}
