import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { alert_error, alert_success } from 'src/app/functions/alert-function';
import { Area_Service } from 'src/app/services/general-services/area.service';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { Persona_Service } from 'src/app/services/general-services/persona.service';
import { Sede_Service } from 'src/app/services/general-services/sede.service';

@Component({
  selector: 'app-registrar-solicitante',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    ToastModule,
    CalendarModule,
    CardModule,
    FontAwesomeModule,
    InputNumberModule,
    InputTextModule,
    ButtonModule,
    CommonModule,
  ],
  templateUrl: './registrar-solicitante.component.html',
  styleUrl: './registrar-solicitante.component.css',
})
export class RegistrarSolicitanteComponent {
  @Input() DataComponent_Parent: any;
  form: FormGroup;
  @Output() Close_Modal_RegistrarCaja_Event = new EventEmitter<any>();

  ///

  empresas: any[] = [];
  sedes: any[] = [];
  areas: any[] = [];
  solicitante: any = {};
  Tipo_documento: any[] = [
    {
      nombre: 'DNI',
    },
    {
      nombre: 'CE',
    },
  ];

  eventsSubject: any;
  ///

  constructor(
    private _empresaService: Empresa_Service,
    private fb: FormBuilder,
    private _sedeService: Sede_Service,
    private _areaService: Area_Service,
    private _personaService: Persona_Service,
    private messageService: MessageService
  ) {
    this.form = this.fb.group({
      empresa_id: [null, Validators.required],
      sede_id: [null, Validators.required],
      area_id: [null, Validators.required],
      tipo_documento: ['', Validators.required],
      numero_documento: ['', Validators.required],
      apellido_paterno: ['', Validators.required],
      apellido_materno: ['', Validators.required],
      nombres: ['', Validators.required],
      celulares: ['', Validators.required],
      correos: ['', Validators.required],
      direccion: ['', Validators.required],
      departamento: ['', Validators.required],
      provincia: ['', Validators.required],
      distrito: ['', Validators.required],
      detalles: [null],
      descripcion: [null],
    });

    // Limpia los errores al cambiar el valor en los cam	this.limpiar_validacion_escribir();
  }

  ngOnInit(): void {
    this.getEmpresas_Api();
    this.getSedes_Api();
    this.getAreas_Api();
  }

  getEmpresas_Api(): void {
    this._empresaService.get().subscribe({
      next: (response: any) => {
        this.empresas = response.data || [];
      },
      error: (error) => {
        console.error('Error al obtener empresas:', error);
      },
    });
  }

  getSedes_Api(): void {
    this._sedeService.get().subscribe({
      next: (response: any) => {
        this.sedes = response.data || [];
      },
      error: (error) => {
        console.error('Error al obtener empresas:', error);
      },
    });
  }

  getAreas_Api(): void {
    this._areaService.get().subscribe({
      next: (response: any) => {
        this.areas = response.data || [];
      },
      error: (error) => {
        console.error('Error al obtener empresas:', error);
      },
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.form.get(controlName);
    if (control!.hasError('required')) {
      return 'Este campo es obligatorio';
    }
    if (control!.hasError('pattern')) {
      return 'Formato no válido';
    }

    return '';
  }

  Register_Solicitante(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched(); // Marca todos los campos como tocados para mostrar errores
      return;
    }

    const formValues = this.form.value;
    const tipoDocumentoNombre =
      formValues.tipo_documento?.nombre || formValues.tipo_documento;
    const formData = {
      ...formValues,
      tipo_documento: tipoDocumentoNombre,
      correos: JSON.stringify([
        { nombre: 'Personal', correo: formValues.correos },
      ]),
      celulares: JSON.stringify([
        { nombre: 'Personal', celular: formValues.celulares },
      ]),
    };

    this._personaService.create(formData).subscribe({
      next: (response: any) => {
        if (response.type === 'SUCCESS') {
          this.messageService.add({
            severity: 'success',
            summary: response.title,
            detail: response.message,
            life: 3000,
          });
        	this.form.reset({});
          this.Close_Modal_RegistrarCaja_Event.emit();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: response.title,
            detail: response.message,
            life: 3000,
          });
        }
      },
      error: (err) => {
        console.error('Error al registrar el producto:', err);
        this.Close_Modal_RegistrarCaja_Event.emit();
      },
    });
  }


  hideDialog() {
    //  this.form.reset();
  	this.form.reset({});
    this.Close_Modal_RegistrarCaja_Event.emit();
  }
}
