import { CommonModule, formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, Subscription } from 'rxjs';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Desembolso_Service } from 'src/app/services/gestion-desembolso/desembolso.service';
import { Liquidacion_Gastos_Service } from 'src/app/services/gestion-desembolso/liquidacion-gastos.service';
import { ReporteDesembolsoService } from 'src/app/services/gestion-desembolso/reporte-desembolso.service';

interface City {
	name: number;
	code: string;
}
interface Moneda {
	name: string;
	code: string;
}

@Component({
	selector: 'app-registrar-liquidacion-gastos',
	standalone: true,
	imports: [
		CardModule,
		InputNumberModule,
		TableModule,
		DropdownModule,
		ToastModule,
		CalendarModule,
		ButtonModule,
		FormsModule,
		InputTextModule,
		TooltipModule,
		DividerModule,
		CommonModule,
		SelectButtonModule,
		FontAwesomeModule,
		DialogModule,
		FormsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule
	],
	templateUrl: './registrar-liquidacion-gastos.component.html',
	styleUrl: './registrar-liquidacion-gastos.component.css',
})
export class RegistrarLiquidacionGastosComponent {
	faTableList = faTableList;
	@Input() title: string = '';
	@Output() Close_Modal_Event3 = new EventEmitter<boolean>();

	@Output() registrosAgregado = new EventEmitter<any>();
	disableButton: boolean = false;
	RegistroLiquidacion_Form: FormGroup;
	Button_Disabled: boolean = false;
	eventsSubject: Subject<void> = new Subject<void>();
	formVisible = false;
	selectedOption: any;

	prodicto2: any = {};
	cities: City[] | undefined;
	selectedCity: City | undefined;
	desembolsos: any[] = [];
	desembolso: any = {};

	selectedMoney: Moneda | undefined;
	Liqu_gastos_Response!: any[];

	constructor(
		private fb: FormBuilder,
		private _liquidacion_gastos_service: Liquidacion_Gastos_Service,
		private _ReporteDesembolso_Service: ReporteDesembolsoService,
		private _Desembolso_Service: Desembolso_Service,
		private _monedaService: Moneda_Service,
		private messageService: MessageService
	) {
		this.RegistroLiquidacion_Form = this.fb.group({
			id_desembolso: [null, Validators.required],
			tipo_cambio: [null, Validators.required],
			monto_desembolso: [null, Validators.required],
			gasto_desembolso: [null, Validators.required],
			saldo_desembolso: [null, Validators.required],
			total_gastos: [null, Validators.required],
			detalles: [null, Validators.required],
			detalles_liquidacion_gastos: this.fb.array([])
		});
	}

	get detalles_liquidacion_gastos(): FormArray {
		return this.RegistroLiquidacion_Form.get('detalles_liquidacion_gastos') as FormArray;
	}

	@ViewChild('contenedorInputs', { static: true }) contenedorInputs: ElementRef;

	ngOnInit(): void {
		this.Get_Desembolsos_Api();
		this.getMoneda_Api();
	}

	Tipo_comprobante: any[] = [
		{ label: 'BOLETA', value: 'BOLETA' },
		{ label: 'FACTURA', value: 'FACTURA' },
	];

	Get_Desembolsos_Api(): void {
		const request = {
			'estado': 'DESEMBOLSADO'
		};
		this._ReporteDesembolso_Service.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.desembolsos = response_data;
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				this.loading = true;
				console.log('Error al llamar el servicio', error);
			},
		});
	}

	getMoneda_Api(): void {
		this._monedaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.monedas = response_data;
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_Desembolso(): void {
		this._Desembolso_Service.getByid(this.RegistroLiquidacion_Form.get('id_desembolso')?.value).subscribe({
			next: (response: any) => {
				if (response.data) {
					this.desembolso = response.data;
					this.RegistroLiquidacion_Form.patchValue({
						monto_desembolso: this.desembolso.monto
					});
				} else {
					console.log('No se encontraron datos.');
				}
			},
		});
	}

	stateOptions = [
		{ label: 'No', value: 'NO' },
		{ label: 'Sí', value: 'SI' },
	];
	incluir_proveedor: string = 'NO';

	suscription: Subscription;
	almacenes_origen: any[] = [];
	almacenes_destino: any[] = [];
	categorias_dinamico: any[][] = [];
	proveedores: any[] = [];
	productos_dinamico: any[] = [];
	loading: boolean = true;
	monedas: any[] = [];
	sede: any = {};

	empresa: any = {};
	area: any = {};

	/* Enviar datos del TS AL HTML (DB) */
	productos_dinamico__db: any[][] = [];
	almacen_productos_dinamico__db: any[] = [];

	Add_DetalleLiquidacionGastos(): void {
		this.detalles_liquidacion_gastos.push(this.Create_Requerimiento());
	}
	Create_Requerimiento(): FormGroup {
		return this.fb.group({
			concepto: [null, Validators.required],
			fecha_comprobante: [null, Validators.required],
			tipo_comprobante: [null, Validators.required],
			nro_comprobante: [null, Validators.required],
			simbolo_moneda: [null, Validators.required],
			moneda: [null, Validators.required],
			monto: [null, Validators.required],
			comprobante: [null, Validators.required],
			detalles: [null, Validators.required],
		});
	}

	eliminarFila(index: number) {
		this.detalles_liquidacion_gastos.removeAt(index);
		this.Calculate_GastoTotal();
	}

	Calculate_GastoTotal(): void {
		this.desembolso.gasto_desembolso = 0;
		this.detalles_liquidacion_gastos.value.forEach((detalle: any) => {
			this.desembolso.gasto_desembolso += parseFloat(detalle.monto);
		});
		this.desembolso.saldo_desembolso = this.desembolso.monto - this.desembolso.gasto_desembolso;
		this.RegistroLiquidacion_Form.patchValue({
			gasto_desembolso: this.desembolso.gasto_desembolso,
			saldo_desembolso: this.desembolso.saldo_desembolso
		})
	}
	onFileSelect(event: any, index: number) {
		const file = event.target.files[0];
		if (file) {
			const requerimiento = this.detalles_liquidacion_gastos.at(index) as FormGroup;
			requerimiento.patchValue({ comprobante: file });
		}
	}

	registerLiquidacionGastos(): void {
		const LiquidacionGasto_Form = new FormData;
		
		LiquidacionGasto_Form.append('id_desembolso', this.desembolso.id_desembolso);
		LiquidacionGasto_Form.append('monto_desembolso', this.desembolso.monto);
		LiquidacionGasto_Form.append('gasto_desembolso', this.desembolso.gasto_desembolso);
		LiquidacionGasto_Form.append('saldo_desembolso', this.desembolso.saldo_desembolso);
		LiquidacionGasto_Form.append('detalles', this.desembolso.detalles);

		this.detalles_liquidacion_gastos.controls.forEach((detalle, index) => {
			const DetalleForm = detalle.value;
			LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][concepto]`, DetalleForm.concepto);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][fecha_comprobante]`, formatDate(DetalleForm.fecha_comprobante, 'yyyy-MM-dd HH:mm:ss', 'en-US'));
			LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][tipo_comprobante]`, DetalleForm.tipo_comprobante);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][simbolo_moneda]`, DetalleForm.simbolo_moneda);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][moneda]`, DetalleForm.moneda);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][monto]`, DetalleForm.monto);
			// LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][comprobante]`, DetalleForm.comprobante);
			if (DetalleForm.comprobante) { LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][comprobante]`, DetalleForm.comprobante); }
			LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][nro_comprobante]`, DetalleForm.nro_comprobante);
			LiquidacionGasto_Form.append(`detalles_liquidacion_gastos[${index}][detalles]`, DetalleForm.detalles);
		});

		this._liquidacion_gastos_service
			.post(LiquidacionGasto_Form)
			.subscribe({
				next: (response: any) => {
					if (response.status == 'CREATED') {
						this.messageService.add({
							severity: 'success',
							summary: response.title,
							detail: response.message,
							life: 3000
						});
						this.registrosAgregado.emit(response.data);
						this.resetForm();
					} else {
						this.registrosAgregado.emit(response.data);
						this.messageService.add({
							severity: 'error',
							summary: response.title,
							detail: response.message,
							life: 3000
						});
					}
				},
				error: (err) => {
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: 'Ocurrió un error en la solicitud',
					});
				},
			});
	}

	hideDialog() {
		this.Close_Modal_Event3.emit();
	}
	resetForm(): void {
		this.detalles_liquidacion_gastos.controls = [];
		this.desembolso = {
			id_desembolso: null,
			monto: null,
			gasto_desembolso: null,
			saldo_desembolso: null,
			detalles: null,
		};
	}
}
