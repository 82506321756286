import { Component, Input, TemplateRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TableModule } from 'primeng/table';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { TablePaggingComponent } from '../../util/table-pagging/table-pagging.component';
import { RegistrarCajaComponent } from '../../gestion-caja/registrar-caja/registrar-caja.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { ButtonAction } from 'src/app/common/button-action';
import { accionConst } from 'src/app/constants/general-constats';
import { Subject, Subscription } from 'rxjs';
import { Cuenta_Response } from 'src/app/response/gestion-cuenta/cuenta-response';
import { tableCol } from 'src/app/common/table-cols';
import { FilterForm } from 'src/app/common/filter-form';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';
import { FilterTable } from 'src/app/common/filter-table';
import { RegistrarCuentaBancComponent } from '../registrar-cuenta-banc/registrar-cuenta-banc.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { DetalleCuentaBancComponent } from '../detalle-cuenta-banc/detalle-cuenta-banc.component';
import { DataService } from 'src/app/services/general-services/data.service';

@Component({
	selector: 'app-reporte-cuenta-banc',
	standalone: true,
	imports: [
		FontAwesomeModule,
		IconFieldModule,
		InputIconModule,
		TableModule,
		TablePaggingComponent,
		RegistrarCuentaBancComponent,
		DetalleCuentaBancComponent,
		CommonModule,
		ButtonModule,
		DialogModule,
		FormsModule,
		PaginatorModule,
		InputTextModule,
		SplitButtonModule,
	],
	templateUrl: './reporte-cuenta-banc.component.html',
	styleUrl: './reporte-cuenta-banc.component.css',
	providers: [BsModalService, SharedDataService],
})
export class ReporteCuentaBancComponent {
	/////////////////////////////////variables - inputs -outputs////////////////
	@Input() Liq_Gastos_Response: any[] = [];
	display: boolean = false;
	visible: boolean = false;
	faTableList = faTableList;
	RowData: any;
	modalRef: BsModalRef | undefined;
	//////////////////////////eventos-response////////////////////////////////////

	eventsSubject: Subject<void> = new Subject<void>();
	valuesTable: Cuenta_Response[] = [];
	cuenta_Response: Cuenta_Response[] = [];
	filterForm: FilterForm[] = [];
	Cuenta_Selected: Cuenta_Response = new Cuenta_Response();
	////////////////////////////data-values////////////////////////////////////////
	cols: tableCol[] = [
		{ field: 'nombre_emoresa', header: 'Empresa' },
		{ field: 'nombre_sede', header: 'Sede' },
		{ field: 'nombre_persona', header: 'Persona' },
		{ field: 'nombre_largo', header: 'Nombre' },
		{ field: 'titular', header: 'Titular' },
		{ field: 'tipo_cuenta', header: 'Tipo' },
		{ field: 'banco', header: 'Banco' },
		{ field: 'moneda', header: 'Moneda' },
		{ field: 'nro_cuenta', header: 'Nº Cuenta' },
		{ field: 'nro_cci', header: 'CCI' },
		{ field: 'monto_inicial', header: 'Monto Inicial' },
		{ field: 'monto_actual', header: 'Monto Actual' },
		{ field: 'detalles', header: 'Detalles' },
	];

	/////////////////////////constructor-oninit///////////////

	constructor(
		private _Cuenta_Service: Cuenta_Service,

		private dataService: DataService,
		private modalService: BsModalService
	) { }

	ngOnInit(): void {
		this.GetCuenta_Api();
	}

	///////////////api-get-cuentas///////////////////////
	GetCuenta_Api() {
		this._Cuenta_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				console.log(response_data);
				if (response_data) {
					this.cuenta_Response = response_data;
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}




	///////////////close-modals///////////////////////
	hideDialog() {
		this.display = false;
	}

	showDialog() {
		this.modalRef = this.modalService.show(RegistrarCuentaBancComponent, {
			class: 'modal-xl modal-custom',
		});

		if (this.modalRef.content) {
			this.modalRef.content.registroAgregado.subscribe(() => {
				this.GetCuenta_Api();
				this.Close_Modal(); // Cierra el modal
			});
			// Suscribirse al evento de cierre del modal
			this.modalRef.content.Close_Modal_Event2.subscribe(() => {
				this.Close_Modal(); // Cierra el modal cuando se emite el evento de cierre
			});
		}
	}


	Close_Modal() {
		if (this.modalRef) {
			this.modalRef.hide(); // Usa hide() en lugar de modificar display
		}
	}



	////////////////////
	showDialog2(RowData_R: any) {
		this.RowData = RowData_R;
		this.visible = true;
	}

	////////////////
	hideDialog2() {
		this.visible = false;
	}
	Close_Modal2() {
		this.visible = false;
	}
	////////////
}
