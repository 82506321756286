<div class="row">
    <div class="col-lg-12">
        <p-card>
            <div class="row mb-5">
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nombre_emoresa" [disabled]="true">
                        <label>Empresa </label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nombre_persona" [disabled]="true">
                        <label>Responsable</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nombre" [disabled]="true">
                        <label>Nombre </label>
                    </p-floatLabel>
                </div>

                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nombre_corto" [disabled]="true">
                        <label>Nombre Corto</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.detalles" [disabled]="true">
                        <label>Detalles</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.tipo_cuenta" [disabled]="true">
                        <label>Tipo Cuenta</label>
                    </p-floatLabel>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.banco" [disabled]="true">
                        <label>Banco</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-1">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.moneda" [disabled]="true">
                        <label>Moneda</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-1">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.simbolo_moneda" [disabled]="true">
                        <label>Símbolo</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.monto_actual" [disabled]="true">
                        <label>Monto actual</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-2">
                    <p-floatLabel>
                        <input pInputText [(ngModel)]="formData.nro_cuenta" [disabled]="true">
                        <label>Nº Cuenta</label>
                    </p-floatLabel>
                </div>
                <div class="col-lg-4">
                    <p-floatLabel>
                        <input pInputText class="w-100" [(ngModel)]="formData.nro_cci" [disabled]="true">
                        <label>CCI</label>
                    </p-floatLabel>
                </div>

            </div>
            <div class="row">
                <div class="col-lg-12">
                    <label>Periodos: </label>
                    <span *ngFor="let periodo of PeriodosCuenta_Db"> {{ periodo.nombre }} , </span> <button
                        class="btn btn-link" type="button" (click)="Open_Modal_Ver_PeriodosCuenta()">Detalles</button>

                    <p-dialog header="Periodos" [modal]="true" (onHide)="Close_Modal_Ver_PeriodosCuenta()"
                        [draggable]="false" [(visible)]="Display_Modal_Ver_PeriodosCuenta">
                        <table>
                            <thead>
                                <tr>
                                    <th>N</th>
                                    <th>Nombre</th>
                                    <th>Detalles</th>
                                    <th>Inicio</th>
                                    <th>Fin</th>
                                    <th>Saldo Inicial</th>
                                    <th>Ingresos</th>
                                    <th>Egresos</th>
                                    <th>Saldo Final</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let periodo_cuenta of PeriodosCuenta_Db; let i = index">
                                    <td> {{ i + 1 }} </td>
                                    <td> {{ periodo_cuenta.nombre }} </td>
                                    <td> {{ periodo_cuenta.detalles }} </td>
                                    <td> {{ periodo_cuenta.inicio }} </td>
                                    <td> {{ periodo_cuenta.fin }} </td>
                                    <td> {{ periodo_cuenta.monto_inicial }} </td>
                                    <td> {{ periodo_cuenta.monto_ingresos }} </td>
                                    <td> {{ periodo_cuenta.monto_egresos }} </td>
                                    <td> {{ periodo_cuenta.monto_final }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </p-dialog>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <h4>Movimientos</h4>
                    <p-button pRipple severity="primary" class="mr-5 mb-5" label="Registrar Movimiento"
                        (click)="Open_Modal_Registrar_MovimientoCaja()"></p-button>
                    <label>
                        Periodo:
                        <p-dropdown [showClear]="true" [options]="PeriodosCuenta" optionValue="id_periodo_cuenta"
                            optionLabel="nombre" [(ngModel)]="id_periodo_cuenta"
                            (onChange)="Get_Movimientos_By_Periodo_TipoMovimiento()"></p-dropdown>
                    </label>
                    <label>
                        Tipo Movimiento
                        <p-dropdown [showClear]="true" [options]="TiposMovimiento_Cuenta" optionValue="nombre"
                            optionLabel="nombre" [(ngModel)]="tipo_movimiento"
                            (onChange)="Get_Movimientos_By_Periodo_TipoMovimiento()"></p-dropdown>
                    </label>
                    <p-button pRipple severity="primary" label="Ver resumen por periodo"
                        (click)="Open_Modal_Ver_ResumenCuenta(formData)"></p-button>
                    <p-button pRipple severity="danger" label="Cerrar Periodo Cuenta"
                        (click)="Open_Modal_Cerrar_PeriodoCuenta(formData)"></p-button>

                    <p-table [value]="Movimientos" [tableStyle]="{ 'min-width': '60rem' }"
                        styleClass="p-datatable-gridlines p-datatable-striped">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Tipo</th>
                                <th>Fecha</th>
                                <th>Periodo</th>
                                <th>Descripción</th>
                                <th>Moneda</th>
                                <th>Ingreso</th>
                                <th>Egreso</th>
                                <th>Saldo disponible</th>
                                <th>Tipo Pago</th>
                                <th>Método Pago</th>
                                <th>Tipo Comprobante</th>
                                <th>Comprobante</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-movimiento>
                            <tr>
                                <td>{{ movimiento.tipo }}</td>
                                <td>{{ movimiento.fecha }}</td>
                                <td>{{ movimiento.nombre_periodo_cuenta }}</td>
                                <td>{{ movimiento.concepto }}</td>
                                <td>{{ movimiento.moneda }}</td>
                                <td>{{ ( movimiento.tipo == 'INGRESO' ) ? movimiento.monto : '' }}</td>
                                <td>{{ ( movimiento.tipo == 'EGRESO' ) ? movimiento.monto : '' }}</td>
                                <td>{{ movimiento.monto_disponible }}</td>
                                <td>{{ movimiento.tipo_pago }}</td>
                                <td>{{ movimiento.metodo_pago }}</td>
                                <td>{{ movimiento.tipo_comprobante }}</td>
                                <td>{{ movimiento.comprobante }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </p-card>
    </div>
</div>
<p-dialog header="Registrar movimiento de Cuenta" [draggable]="false"
    [style]="{'width':'1200vw', 'max-width':'1400px', 'height':'100vh', 'max-height':'650px'}" [modal]="true"
    (onHide)="hideDialog2()" class="dialog-modal" [(visible)]="Display_Modal_Registrar_Cuenta">
    <app-registrar-movimiento-cuenta (registroAgregado)="ActualizarRegistroAgregado($event)"
        (Close_Modal_Event3)="Close_Modal()" [Data_Parent]="formData">
    </app-registrar-movimiento-cuenta>
</p-dialog>

<p-dialog header="" [modal]="true" (onHide)="hideDialog()" [draggable]="false"
    [(visible)]="Display_Modal_Ver_ResumenCuenta">
    <app-resumen-cuenta-periodo [Data_Parent]="formData">
    </app-resumen-cuenta-periodo>
</p-dialog>

<p-dialog header="Cerrar Periodo Cuenta" [modal]="true" (onHide)="Close_Modal_Cerrar_PeriodoCuenta()"
    [draggable]="false" [(visible)]="Display_Modal_Cerrar_PeriodoCuenta"
    [style]="{'width':'600vw', 'max-width':'800px', 'height':'50vh', 'max-height':'650px'}">
    <app-cerrar-periodo-cuenta [Data_Parent]="formData">
    </app-cerrar-periodo-cuenta>
</p-dialog>