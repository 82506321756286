<div class="card flex gap-2" id="carta_1">
	<div class="w-9 mb-5">
		<div class="px-gutter pt-6 pb-20 bg-slate-900 flex-none">
			<div class="flex items-center justify-between" vexContainer>
				<h2 class="headline text-neutral-100 m-0 flex items-center w-full max-w-xxxl mr-6">
					<fa-icon [icon]="faTableList" class="hidden sm:block ml-5 fa-2xl"></fa-icon>
					<span class="ml-4 block pt-0">Requerimiento Pago</span>
				</h2>
			</div>
		</div>
	</div>


	<div class="container-fluid">

		<p-button label="Registrar Requerimiento de Pago" (click)="showDialog()"></p-button>

		<div class="card text-dark bg-light mb-3">

			<div class="card-body">
				<p-table #dt2 [value]="req_Pago_Response" dataKey="id" [rows]="10" [rowsPerPageOptions]="[10, 25, 50]"
					[paginator]="true"
					[globalFilterFields]="['estado', 'concepto','tipo_concepto', 'titular_cuenta', 'detalles_estado', 'banco_cuenta', 'nro_cuenta', 'status']">

					<ng-template pTemplate="caption">
						<div class="flex">
							<p-iconField iconPosition="left" class="ml-auto">
								<p-inputIcon>
									<i class="pi pi-search"></i>
								</p-inputIcon>
								<input pInputText #textInput type="text"
									(input)="dt2.filterGlobal(textInput.value, 'contains')"
									placeholder="Global Search" />
							</p-iconField>
						</div>
					</ng-template>

					<ng-template pTemplate="header">
						<tr>
							<th *ngFor="let col of cols" [ngClass]="col.field === 'estado' ? 'estado-col-header' : ''">
								{{ col.header }}
							</th>
						</tr>
					</ng-template>

					<ng-template pTemplate="body" let-rowData>
						<tr>
							<td *ngFor="let col of cols" [ngClass]="col.field === 'estado' ? 'estado-col' : ''">
								<span [ngClass]="col.field === 'estado' ? getEstadoClass(rowData[col.field]) : ''">
									{{ rowData[col.field] }}
								</span>
							</td>
							<td>
								<p-splitButton label="ESTADO" appendTo="body"
									[model]="getOptionsEstado_Button(rowData)"></p-splitButton>
							</td>
							<td>
								<button pButton severity="info" type="button" label="Desembolso"
									(click)="showDialog2(rowData)"></button>
							</td>
							<td>
								<button pButton severity="primary" class="pi pi-pencil
								" type="button" label="" (click)="Editar(rowData)"></button>
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>

	<p-dialog header="Actualizar estado del requerimiento de pago" [draggable]="false" [resizable]="false"
		[(visible)]="displayModal" modal="true" [closable]="false">
		<app-update-estado-requerimiento-pago (Close_Modal_Event1)="Close_Modal_f()"
			(registrosAgregado)="ActualizarEstadoAgregado($event)"
			[DataComponent_Parent]="RowData"></app-update-estado-requerimiento-pago>
	</p-dialog>

	<p-dialog header="Programar Desembolso" [draggable]="false" [resizable]="false" [(visible)]="visible" [modal]="true"
		(onHide)="hideDialog2()">
		<app-programar-desembolso (Close_Modal_Event2)="Close_Modal2()" [data]="RowData"></app-programar-desembolso>
	</p-dialog>

	

	<p-dialog header="Registro de Requerimiento de Pago" [draggable]="false" [resizable]="false" [closable]="false"
		[(visible)]="display" [modal]="true" (onHide)="hideDialog()"
		[style]="{'width':'1200vw', 'max-width':'1590px', 'height':'100vh', 'max-height':'650px'}">
		<app-registrar-req-pago (Close_Modal_Event3)="Close_Modal3()"
			(registroAgregado)="ActualizarRegistroAgregado($event)">
		</app-registrar-req-pago>
	</p-dialog>

	<ng-container *ngIf="modal_editar">
		<p-dialog [(visible)]="modal_editar" [closable]="false" [draggable]="false" [resizable]="false" modal="modal" header="Editar Requerimiento de Pago" (onHide)="Close_Modal_Editar()"
		
		[style]="{'width':'1200vw', 'max-width':'1590px', 'height':'100vh', 'max-height':'650px'}"
		>
		  <app-editar-requerimiento-pago   (closeModalEmmit)="Close_Modal_Editar()"  (registroAgregado)="ActulizarEditarRegistroAgregado($event)"  [data]="selectedRowData" (close)="modal_editar = false"></app-editar-requerimiento-pago>
		</p-dialog>
	  </ng-container>

</div>