import { environment } from "src/environments/environment";



export const urlConstants = {
/*aqui va las rutas donde esta la api de back */
    Server_Api : environment.ApiBack,

    login: `${environment.ApiBack}user/login`,
    //////////////////////////////////////////////
    get__desembolso_details_sort: `${environment.ApiBack}desembolso--details--sort/`,
    update__estado_desembolso: `${environment.ApiBack}desembolso/update-estado/`,
    update__estado_liquidacion_gasto :`${environment.ApiBack}desembolso/liquidacion-gasto/update-estado/`,
    update___estado___requerimiento__pago: `${environment.ApiBack}desembolso/requerimiento-pago/update-estado/`,
    registro_requerimiento_pago: `${environment.ApiBack}desembolso/requerimiento-pago`,
    editar_requerimiento_pago: `${environment.ApiBack}desembolso/requerimiento-pago/`,
    liquidacion_gastos: `${environment.ApiBack}desembolso/liquidacion-gasto/`,
    progrmar_desembolso: `${environment.ApiBack}desembolso`,
    desembolso: `${environment.ApiBack}desembolso/`,
    //////////////////////////////////////////
    cuenta: `${environment.ApiBack}general/cuenta/`,
    cuenta_by_id: `${environment.ApiBack}general/cuenta-with-movimiento/`,
    caja: `${environment.ApiBack}caja/`,
    movimiento_caja: `${environment.ApiBack}caja/movimiento/`,
    movimiento_cuenta : `${environment.ApiBack}general/cuenta/movimiento`,
    /////general urls/////////
    empresa: `${environment.ApiBack}general/empresa/`,
    sede: `${environment.ApiBack}general/sede/`,
    area: `${environment.ApiBack}general/area/`,
    persona: `${environment.ApiBack}general/persona/`,
    moneda: `${environment.ApiBack}general/moneda/`,
    tipoRequerimiento: `${environment.ApiBack}general/tipo-detalle-requerimiento-pago`,
    banco: `${environment.ApiBack}general/banco`,
    cuenta_empresa: `${environment.ApiBack}general/cuenta/empresa-id/`,
    periodo: `${environment.ApiBack}general/periodo/`,

    periodo_cuenta: `${environment.ApiBack}general/periodo-cuenta/`,
    periodo_caja: `${environment.ApiBack}general/periodo-caja/`,


    /* ------------------ */
    test : `${environment.ApiBack}upload-file/`
    
};
