
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { urlConstants } from 'src/app/constants/url-constants';
@Injectable({
  providedIn: 'root'
})
export class CrudService<Y, T> {

  constructor(
    protected _http: HttpClient,
    @Inject('url_service') public url_service: string

  ) { }
  public loggedIn = false;
  /*Obtiene una lista conpleta */
  get( url_custom? : string | null, headers?:HttpHeaders): Observable<Y[]> {
    const url = ( url_custom ) ? url_custom : this.url_service
    return this._http.get<Y[]>(url, {headers});
  }

  /*Obtiene una lista segun al id */
  getByid(id: number): Observable<Y> {
    return this._http.get<Y>(`${this.url_service}${id}`);
  }

  /*Obtiene una lista segun al id */
  getByOther( campos: { [key: string]: any }, url_custom? : string | null, url_custom_external?:string | null, headers?: HttpHeaders ): Observable<Y> {
    const url = ( url_custom_external ) ? url_custom_external : ( url_custom ) ? urlConstants.Server_Api+url_custom : this.url_service;
    // const params = new HttpParams;
    let request_data = '';
    for (const key in campos) {
      // params.append(key, campos[key]);
      request_data += `${key}/${campos[key]}/`;

    }
    return this._http.get<Y>(`${url}${request_data}`, {headers});
  }
  /*Crea un registro */
  create(request: T): Observable<Y> {
    return this._http.post<Y>(this.url_service, request);
  }
  post(request: any, url_custom? : string | null, url_custom_external?:string | null): Observable<any> {
    
    const url = ( url_custom_external ) ? url_custom_external : ( url_custom ) ? urlConstants.Server_Api+url_custom : this.url_service;
    // const url = ( url_custom ) ? url_custom : this.url_service;
    return this._http.post<Y>(url, request);
  }
  /*Crea un registro con soporte para archivos */
  create_formdata(request: FormData): Observable<Y> {
    return this._http.post<Y>(this.url_service, request);
  }
  /*Actualiza un registro */
  update(id: number, request: T): Observable<Y> {
    return this._http.patch<Y>(`${this.url_service}${id}`, request);
  }
  update_formdata(id: number, request: any = null): Observable<any> {
    return this._http.patch<Y>(`${this.url_service}${id}`, request);
  }
  /*Elimina un registro */
  delete(id: number): Observable<number> {
    return this._http.delete<number>(`${this.url_service}${id}`);
  }
  
  login(request: T): Observable<Y>{
    return this._http.post<Y>(this.url_service, request).pipe(
      tap((response: any) => {
        // Verifica si el inicio de sesión fue exitoso
        if (response.code === 200) {
          console.log("sdasd")
          this.loggedIn = true;
        }
      })
    );
  }
  

  	
  logout(): void {
		// Agrega aquí la lógica para cerrar sesión en tu backend si es necesario
		this.loggedIn = false;
    localStorage.removeItem('token');
	  }
	

	isLoggedIn(): boolean {
		return this.loggedIn;
	  }
  

}
