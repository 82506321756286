import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { PeriodoCajaService } from 'src/app/services/gestion-caja/periodo-caja.service';

import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';

@Component({
	selector: 'app-cerrar-periodo-caja',
	standalone: true,
	imports: [DropdownModule, ButtonModule, ToastModule,CommonModule, ReactiveFormsModule, FormsModule],
	templateUrl: './cerrar-periodo-caja.component.html',
	styleUrl: './cerrar-periodo-caja.component.css',
	providers: [MessageService],
})
export class CerrarPeriodoCajaComponent implements OnChanges {
	@Input() Data_Parent: any;
	Cajas: any[] = [];
	PeriodosCaja: any[] = [];
	Form_Request: FormGroup;

	constructor(
		private _Caja_Service: Caja_Service,
		private _Periodo_Caja_Serice: PeriodoCajaService,
		private Fb: FormBuilder,
		
		private changeDetectorRef: ChangeDetectorRef,
		private messageService: MessageService
	) {
		this.Form_Request = this.Fb.group({
			id_caja: new FormControl(null, Validators.required),
			id_periodo_caja: new FormControl(null, Validators.required),
		})
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes['Data_Parent'] && changes['Data_Parent'].currentValue) {
			this.Form_Request.patchValue({ ...changes['Data_Parent'].currentValue });

			if (this.Form_Request.get('id_caja')?.value) {
				this.Get_Periodos_Caja_By_CajaId();
				this.Get_Cajas_Api();
			}
		}
		this.changeDetectorRef.detectChanges(); // Forzar actualización
	}

	ngOnInit(): void {
	}

	Get_Cajas_Api(): void {
		this._Caja_Service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Cajas = response.data;
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio => ' + error);
			}
		})
	}

	Get_Periodos_Caja_By_CajaId(): void {
		const request = {
			'caja': this.Form_Request.get('id_caja')?.value
		}
		this._Periodo_Caja_Serice.getByOther(request).subscribe({
			next: (response) => {
				if (response.status == 'OK') {
					this.PeriodosCaja = response.data;
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio => ' + error);
			}
		});
	}

	Cerrar_PeriodoCaja(): void {
		console.log(this.Form_Request.value)
		this._Periodo_Caja_Serice.create(this.Form_Request.value).subscribe({
			next: (response: any) => {
				if (response.status == 'CREATED') {
					this.messageService.add({
						severity: 'success',
						summary: 'Éxito',
						detail:
						  response.message,
						  life: 6000,
					  });
				}
				else{
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail:
						  response.message,
					  });
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio => ' + error);
			}
		});
	}
}
