import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { alert_error, alert_success } from 'src/app/functions/alert-function';
import { Caja_Request } from 'src/app/request/gestion-caja/caja-request';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { PeriodoService } from 'src/app/services/general-services/periodo.service';
import { Persona_Service } from 'src/app/services/general-services/persona.service';
import { Sede_Service } from 'src/app/services/general-services/sede.service';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';

@Component({
	selector: 'app-registrar-caja',
	standalone: true,
	templateUrl: './registrar-caja.component.html',
	styleUrls: ['./registrar-caja.component.css'],
	imports: [ReactiveFormsModule, DropdownModule, CalendarModule, CardModule, FontAwesomeModule, InputNumberModule, InputTextModule, ButtonModule, CommonModule]
})
export class RegistrarCajaComponent {
	@Input() title: string = "";

	@Output() Close_Modal_RegistrarCaja_Event = new EventEmitter<any>();
	@Output() registroAgregado = new EventEmitter<any>();
	form: FormGroup;
	errors: any = {};
	Empresas: any[] = [];
	Sedes: any[] = [];
	Monedas: any[] = [];
	PeriodosCuenta = this.fb.array([]);
	Periodos: any[] = [];
	Personas: any[] = [];

	constructor(
		private fb: FormBuilder,
		private _Empresa_Service: Empresa_Service,
		private _Sede_Service: Sede_Service,
		private _Moneda_Service: Moneda_Service,
		private _Caja_Service: Caja_Service,
		private _Periodo_Service: PeriodoService,
		private _Persona_Service: Persona_Service
	) {
		this.form = this.fb.group({
			empresa_id: [null, Validators.required],
			sede_id: [null],
			persona_id: [null],
			nombre: [null, Validators.required],
			nombre_corto: [null],
			nombre_largo: [null],
			detalles: [null],
			fecha_inicio: [null, Validators.required],
			fecha_fin: [null, Validators.required],
			moneda: [null, Validators.required],
			simbolo_moneda: [null, Validators.required],
			monto_inicial: [null, [Validators.required, Validators.pattern('^\\d+(\\.\\d{2,})?$')]],
			periodos: this.fb.array([])
		});

		// Limpia los errores al cambiar el valor en los campos del formulario
		this.limpiar_validacion_escribir();
	}

	ngOnInit(): void {
		this.Get_Empresas_Api();
		this.Get_Monedas_Api();
		this.Get_Periodos_Api();
		this.Get_Personas_Api();
	}

	get periodos(): FormArray {
		return this.form.get('periodos') as FormArray;
	}

	Get_Periodos_Api(): void {
		this._Periodo_Service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Periodos = response.data;
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio');
			}
		});
	}

	Create_Periodo(): FormGroup {
		return this.fb.group({
			periodo_id: [null, Validators.required],
			nombre: [null, Validators.required],
			nombre_corto: [''],
			detalles: [''],
			inicio: [null, Validators.required],
			fin: [null, Validators.required],
			monto_inicial: [null, [Validators.required, Validators.pattern('^\\d+(\\.\\d{2,})?$')]],
			monto_final: [null, [Validators.required, Validators.pattern('^\\d+(\\.\\d{2,})?$')]],
			orden: [null, Validators.required]
		});
	}
	Add_PeriodoCaja(): void {
		this.periodos.push(this.Create_Periodo());
	}

	limpiar_validacion_escribir(): void {
		Object.keys(this.form.controls).forEach(key => {
			this.form.get(key)?.valueChanges.subscribe(() => {
				this.errors[key] = null; // Limpia el error cuando el usuario empieza a escribir
			});
		});
	}

	Get_Empresas_Api(): void {
		this._Empresa_Service.get().subscribe({
			next: (response: any) => {
				this.Empresas = response.data || [];
			},
			error: (error) => console.log("Error al consumir el servicio", error)
		});
	}

	Get_Sedes_By_EmpresaId_Api(object: DropdownChangeEvent): void {
		this._Sede_Service.getByOther({ 'empresa-id': object.value }).subscribe({
			next: (response: any) => {
				this.Sedes = response.data || [];
			},
			error: (error) => console.log("Error al consumir el servicio", error)
		});
	}

	Get_Monedas_Api(): void {
		this._Moneda_Service.get().subscribe({
			next: (response: any) => {
				this.Monedas = response.data || [];
			},
			error: (error) => console.log("Error al consumir el servicio", error)
		});
	}

	Get_Personas_Api(): void {
		this._Persona_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.Personas = response_data;
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	closeModal(res: boolean): void {
		this.Close_Modal_RegistrarCaja_Event.emit(res);
	}

	hideDialog() {
		//  this.form.reset();
		this.Close_Modal_RegistrarCaja_Event.emit();
	}

	Get_NombrePeriodo_Api(object: DropdownChangeEvent, i: number): void {
		this._Periodo_Service.getByid(object.value).subscribe({
			next: (response: any) => {
				if (response.data) {
					this.periodos.at(i).get('nombre')?.setValue(response.data.nombre)
					this.periodos.at(i).get('nombre_corto')?.setValue(response.data.nombre_corto)
				}
			}, error: (error) => {
				console.log('Error al consumir el servicio', error)
			}
		});
	}

	Register_Caja_APi(): void {
		if (this.form.invalid) {
			this.form.markAllAsTouched(); // Marca todos los campos como tocados para mostrar errores
			return;
		}

		this._Caja_Service.create(this.form.value).subscribe({
			next: (response: any) => {
				if (response.status === 'OK') {
					alert_success("El registro se realizó de forma exitosa.");
					this.registroAgregado.emit();
					this.Close_Modal_RegistrarCaja_Event.emit();
				} else {
					alert_error('ERROR', 'Ocurrió un error al consumir el servicio');
				}
				this.Close_Modal_RegistrarCaja_Event.emit();
			},
			error: (err) => {
				console.error("Error al registrar el producto:", err);
				this.Close_Modal_RegistrarCaja_Event.emit();
			}
		});
	}
	getErrorMessage(controlName: string): string {
		const control = this.form.get(controlName);
		if (control!.hasError('required')) {
			return 'Este campo es obligatorio';
		}
		if (control!.hasError('pattern')) {
			return 'Formato no válido';
		}
		return '';
	}
}
