/* Angular Components */
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { alert_success, alert_warning } from 'src/app/functions/alert-function';

/* App Components */

/* App Services, Request, Response */
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';
import { MovimientoCaja_Service } from 'src/app/services/gestion-caja/movimiento_caja.service';
import { MovimientoCaja_Request } from 'src/app/request/gestion-caja/movimiento-caja-request';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';

import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Desembolso_Service } from 'src/app/services/gestion-desembolso/desembolso.service';
import { PeriodoCajaService } from 'src/app/services/gestion-caja/periodo-caja.service';

/* Component Config */
@Component({

	selector: 'app-registrar-movimiento-caja',
	standalone: true,
	imports: [
		FormsModule,
		ToastModule,
		ReactiveFormsModule,
		FloatLabelModule,
		DialogModule,
		CardModule,
		DropdownModule,
		CalendarModule,
		InputTextModule,
		CommonModule,
	],
	templateUrl: './registrar-movimiento-caja.component.html',
	styleUrl: './registrar-movimiento-caja.component.css',
	providers: [Caja_Service],


})

/* Component */
export class RegistrarMovimientoCajaComponent {
	/* Component Data */

	@Output() Close_Modal_RegistrarMovimientCaja_Event = new EventEmitter<any>();
	@Output() registroAgregado = new EventEmitter<any>();
    @Input() Data_Parent: any;


	/* Data Forms Inputs */
	Cajas: any[] = [];
	form: FormGroup;
	errors: any = {};
	id_caja: any;
	Tipos: any[] = [
		{
			id_tipo: 1,
			nombre: 'INGRESO',
		},
		{
			id_tipo: 2,
			nombre: 'EGRESO',
		},
	];
	Monedas: any[] = [];
	PeriodosCaja: any[] = [];
	TiposConcepto: any[] = [
		{
			id_tipo: 1,
			nombre: 'RRHH',
		},
		{
			id_tipo: 2,
			nombre: 'OTROS',
		},
	];
	TiposPago: any[] = [
		{
			id_tipo_pago: 1,
			nombre: 'FÍSICO',
		},
		{
			id_tipo_pago: 2,
			nombre: 'DIGITAL',
		},
	];
	MetodosPago: any[] = [
		{
			id_metodo_pago: 1,
			nombre: 'EFECTIVO',
		},
		{
			id_metodo_pago: 2,
			nombre: 'YAPE',
		},
		{
			id_metodo_pago: 3,
			nombre: 'TRANSFERENCIA',
		},
	];
	TiposComprobante: any[] = [
		{
			id_tipo_comprobante: 1,
			nombre: 'VOUCHER',
		},
	];



	MovimientoCaja_Request: MovimientoCaja_Request = new MovimientoCaja_Request();

	eventsSubject: Subject<void> = new Subject<void>();
	desembolsos: any = [];
	constructor(
		private _Caja_Service: Caja_Service,
		private fb: FormBuilder,
		private _Moneda_Service: Moneda_Service,
		private _MovimientoCaja_Service: MovimientoCaja_Service,
		private messageService: MessageService,
		private _ReporteDesembolso_Service: Desembolso_Service,
		private _PeriodosCaja_Service: PeriodoCajaService
	) {
		this.form = this.fb.group({
			id_caja: [{ value: null, disabled: true }, Validators.required],
			id_desembolso: [null],
			tipo: [null, Validators.required],
			fecha: [null, Validators.required],
			tipo_concepto: [null, Validators.required],
			concepto: [null, Validators.required],
            id_periodo_caja: [null, Validators.required],
			moneda: ['', Validators.required],
			simbolo_moneda: [null, Validators.required],
			monto: [null, Validators.required],
			tipo_pago: [null, Validators.required],
			metodo_pago: [null, Validators.required],
			tipo_comprobante: [null],
			comprobante: [null]
		});

		this.limpiar_validacion_escribir();
	}
	
	ngOnChanges(changes: SimpleChanges) {
		if (changes['Data_Parent'] && changes['Data_Parent'].currentValue) {
			this.form.patchValue({ ...changes['Data_Parent'].currentValue });
			this.id_caja = changes['Data_Parent'].currentValue.id_caja;
			if (this.id_caja) {
				this.Get_Cajas_Api();
				this.Get_PeriodosCaja_By_CajaId_Api();
			}
		}
	}
	ngOnInit(): void {
		this.Get_Cajas_Api();
		this.Get_Monedas_Api();
		this.Get_Desembolsos_Api();
	}

	Get_PeriodosCaja_By_CajaId_Api(): void {
        const request = {
            caja: this.id_caja
        };
		this._PeriodosCaja_Service.getByOther(request).subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.PeriodosCaja = response.data;
				}
			}, error(error) {
				console.log('Error al consumir el servicio', error);				
			},
		});
	}

	Get_Desembolsos_Api() {
		this._ReporteDesembolso_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.desembolsos = response_data;
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_Cajas_Api(): void {
		this._Caja_Service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Cajas = response.data;
				} else {
					console.error('No se encontró ningún dato.');
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}

	Get_Monedas_Api(): void {
		this._Moneda_Service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Monedas = response.data;
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}
	onFileSelect(event: any) {
		const file = event.target.files[0];
		if (file) {
			this.form.patchValue({ comprobante: file });
		}
	}

	Registrar_MovimientoCaja(event: Event): void {
        this.form.get('id_caja')?.enable();
		const Form_MovimientoCaja_Request = new FormData();
		Form_MovimientoCaja_Request.append('id_caja', this.form.value.id_caja);
		Form_MovimientoCaja_Request.append('id_desembolso', this.form.value.id_desembolso);
		Form_MovimientoCaja_Request.append('tipo', this.form.value.tipo);
		Form_MovimientoCaja_Request.append('fecha', this.form.value.fecha);
		Form_MovimientoCaja_Request.append('id_periodo_caja', this.form.value.id_periodo_caja);
		Form_MovimientoCaja_Request.append('tipo_concepto', this.form.value.tipo_concepto);
		Form_MovimientoCaja_Request.append('concepto', this.form.value.concepto);
		Form_MovimientoCaja_Request.append('moneda', this.form.value.moneda);
		Form_MovimientoCaja_Request.append('simbolo_moneda', this.form.value.simbolo_moneda);
		Form_MovimientoCaja_Request.append('monto', this.form.value.monto);
		Form_MovimientoCaja_Request.append('tipo_pago', this.form.value.tipo_pago);
		Form_MovimientoCaja_Request.append('metodo_pago', this.form.value.metodo_pago);
		Form_MovimientoCaja_Request.append('tipo_comprobante', this.form.value.tipo_comprobante);
		Form_MovimientoCaja_Request.append('comprobante', this.form.value.comprobante);
        this.form.get('id_caja')?.disable();

		this.errors = {};

		if (this.form.invalid) {
			this.errors = this.getFormValidationErrors();
			return;
		}

		this._MovimientoCaja_Service.post(Form_MovimientoCaja_Request).subscribe({
			next: (response: any) => {
				if (response.status === 'OK') {
					this.messageService.add({
						severity: 'success',
						summary: 'Éxito',
						detail: 'El movimiento se registró con éxito!',
						life: 6000,
					});
					this.form.reset({
						id_cuenta: this.form.get('id_caja')?.value
					});
					this.eventsSubject.next();
					this.registroAgregado.emit(response.data);
					setTimeout(() => {
						this.Close_Modal_RegistrarMovimientCaja_Event.emit();
					}, 2000);
				} else {
					this.messageService.add({
						severity: 'warn',
						summary: 'Advertencia',
						detail: 'No hay saldo en la caja',
					});
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Ocurrió un error al procesar la solicitud',
				});
			},
		});
	}

	Close_Modal_RegistrarMovimientoCaja() {
		this.form.reset({
			id_cuenta: this.form.get('id_caja')?.value
		});
		this.Close_Modal_RegistrarMovimientCaja_Event.emit();
	}

	limpiar_validacion_escribir(): void {
		Object.keys(this.form.controls).forEach((key) => {
			this.form.get(key)?.valueChanges.subscribe(() => {
				this.errors[key] = null; // Limpia el error cuando el usuario empieza a escribir
			});
		});
	}

	private getFormValidationErrors() {
		const controls = this.form.controls;
		const errors: any = {};
		for (const key in controls) {
			if (controls[key].invalid) {
				errors[key] = 'Este campo es requerido';
			}
		}
		return errors;
	}

	getErrorMessage(controlName: string): string {
		const control = this.form.get(controlName);
		if (control!.hasError('required')) {
			return 'Este campo es obligatorio';
		}
		if (control!.hasError('pattern')) {
			return 'Formato no válido';
		}
		return '';
	}
}
