
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { PeriodoService } from 'src/app/services/general-services/periodo.service';
import { Desembolso_Service } from 'src/app/services/gestion-desembolso/desembolso.service';

@Component({
	selector: 'app-view-programacion-desembolso',
	standalone: true,
	imports: [CommonModule, DropdownModule, FormsModule],
	templateUrl: './view-programacion-desembolso.component.html',
	styleUrl: './view-programacion-desembolso.component.css'
})
export class ViewProgramacionDesembolsoComponent {
	@Input() DataComponent_Parent: any;
	Cuentas_Response: any = {};
	Periodos_Response: any = [];
	id_periodo: number;

	constructor(
		private _Desembolso_Service: Desembolso_Service,
		private _Periodo_Service: PeriodoService
	) { }

	ngOnInit() {
		this.Get_Cuentas_With_Desembolsos_Api();
		this.Get_Periodos_Api();
	}

	Get_Periodos_Api() {
		this._Periodo_Service.get().subscribe({
			next: (response: any) => {
				this.Periodos_Response = response.data;
			}, error: () => {
				console.log('Ocurrió un error al consumir el servicio.');
			}
		});
	}

	Get_Cuentas_With_Desembolsos_Api() {
		this._Desembolso_Service.getByOther({}, 'cuentas--with--desembolsos').subscribe({
			next: (response: any) => {
				this.Cuentas_Response = response.data;
			},
			error: () => {
				console.log('error');
			}
		});
	}

	Get_Cuentas_With_Desembolsos_By_PeriodoId_Api() {
		const request = {
			'periodo': this.id_periodo
		};
		this._Desembolso_Service.getByOther(request, 'cuentas--with--desembolsos/').subscribe({
			next: (response: any) => {
				this.Cuentas_Response = response.data;
			},
			error: () => {
				console.log('Ocurrió un error al consumir el servicio.');
			}
		});
	}

}
