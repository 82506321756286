<div class="modal-header">
	<h4 id="h41" class="modal-title pull-left">{{ title }}</h4>
</div>
<form [formGroup]="Form_Requerimiento">
	<p-card>
		<div class="header-boton">
			<div class="select-1">
				<p>Empresa:</p>
				<p-dropdown [showClear]="true" placeholder="Selecciona Empresa" [options]="empresas"
					optionValue="id_empresa" optionLabel="nombre" formControlName="empresa_id"
					(onChange)="Get_SedesByEmpresaId_Api($event)"></p-dropdown>
			</div>
			<div class="select-1">
				<p>Sede:</p>
				<p-dropdown [showClear]="true" placeholder="Selecciona Sede" [options]="sedes" optionValue="id_sede"
					optionLabel="nombre" formControlName="sede_id"
					(onChange)="Get_AreasBySedeId_Api($event)"></p-dropdown>
			</div>
			<div class="select-1">
				<p>Area:</p>
				<p-dropdown [showClear]="true" placeholder="Seleccione el área" [options]="areas" optionValue="id_area"
					optionLabel="nombre" formControlName="area_id"
					(onChange)="Get_PersonasByAreaId_Api($event)"></p-dropdown>
			</div>
			<div class="select-1">
				<p>Solicitante:</p>
				<p-dropdown [showClear]="true" placeholder="Selecciona Persona" [options]="personas"
					optionValue="id_persona" optionLabel="nombres" formControlName="persona_id"></p-dropdown>
			</div>
			<div class="botton-1">
				<p-button icon="pi pi-plus" (click)="Add_Requerimiento()" label="Agregar Requerimiento"></p-button>

			</div>
			<div class="botton-1">
				<p-button icon="pi pi-plus" severity="info" (click)="Open_Modal_ProgramacionDesembolso()" label="Agregar Solicitante"></p-button>
				
			</div>
		</div>
	</p-card>
	<p-divider type="solid"></p-divider>
	<p-card>
		<table>
			<thead>
				<tr>
					<th width="5%">Tipo Concepto</th>
					<th width="10%">Concepto</th>
					<th width="5%">Tipo Pago</th>
					<th width="5%">Método Pago</th>
					<th width="5%">Tipo Comprobante</th>
					<th width="10%">Comprobante</th>
					<th width="5%">Simbolo Moneda</th>
					<th width="5%">Moneda</th>
					<th width="5%">Monto</th>
					<th width="10%">Nº Cuenta</th>
					<th width="5%">Banco</th>
					<th width="10%">Titular</th>
					<th width="10%">Fecha de Pago</th>
					<th width="10%"></th>
				</tr>
			</thead>
			<tbody formArrayName="requerimientos">
				<tr *ngFor="let requerimiento of requerimientos.controls; let i = index" [formGroupName]="i">
					<td>
						<p-dropdown [showClear]="true" placeholder="" [options]="tipos_concepto" optionValue="nombre"
							optionLabel="nombre" formControlName="tipo_concepto"></p-dropdown>
					</td>
					<td>
						<input type="text" class="w-10" pInputText formControlName="concepto" />
					</td>
					<td>
						<p-dropdown [showClear]="true" placeholder="" [options]="tipos_pago" optionValue="nombre"
							optionLabel="nombre" formControlName="tipo_pago"></p-dropdown>
					</td>
					<td>
						<p-dropdown [showClear]="true" placeholder="" [options]="metodos_pago" optionValue="nombre"
							optionLabel="nombre" formControlName="metodo_pago"></p-dropdown>
					</td>
					<td>
						<p-dropdown [showClear]="true" placeholder="" [options]="tipos_comprobante" optionValue="nombre"
							optionLabel="nombre" formControlName="tipo_comprobante"></p-dropdown>
					</td>
					<td>
						<input type="file" (change)="onFileSelect($event, i)" class="form-control">
					</td>
					<td>
						<p-dropdown [showClear]="true" placeholder="" [options]="monedas" optionValue="simbolo"
							optionLabel="simbolo" formControlName="simbolo_moneda"></p-dropdown>
					</td>
					<td>
						<p-dropdown [showClear]="true" placeholder="" [options]="monedas" optionValue="nombre"
							optionLabel="nombre" formControlName="moneda"></p-dropdown>
					</td>
					<td>

						<p-inputNumber class="input-size" inputId="minmaxfraction" mode="decimal"
							[minFractionDigits]="2" [maxFractionDigits]="5" formControlName="monto" />
					</td>
					<td>
						<input class="input-size" pattern="^[\d-]+$" title="Solo se permiten números y guiones"
							type="text" pInputText formControlName="nro_cuenta" (keypress)="filterInput($event)" />
					</td>
					<td>
						<p-dropdown [showClear]="true" placeholder="" [options]="bancos" optionValue="nombre"
							optionLabel="nombre" formControlName="banco_cuenta"></p-dropdown>
					</td>
					<td>
						<input class="input-size" type="text" pInputText formControlName="titular_cuenta" />
					</td>
					<td>
						<p-calendar [showTime]="false" formControlName="fecha_pago" dateFormat="yy/mm/dd"
							[appendTo]="'body'" />
					</td>
					<td>
						<p-button [raised]="true" pTooltip="Eliminar" tooltipPosition="bottom" icon="pi pi-trash"
							(click)="eliminarFila(i)" severity="danger"></p-button>
					</td>
				</tr>
			</tbody>
		</table>
	</p-card>
	<p-divider type="solid"></p-divider>
	<p-card>
		<div class="register-botton">
			<button pButton type="button" class="p-button-success mb-5" icon="pi pi-save" label="Registrar"
				(click)="registerRequerimientoPago()"></button>
			<p-button label="Cancelar" (click)="hideDialog()" [raised]="true" severity="danger"></p-button>
		</div>
	</p-card>

	
</form>


<p-dialog header="Registrar Solicitante"   [draggable]="false" [resizable]="false"  [closable]="false" [(visible)]="Display_Modal_ProgramacionDesembolso" modal="true" >
	<app-registrar-solicitante [DataComponent_Parent]="rowData" (Close_Modal_RegistrarCaja_Event)="Close_Modal_ProgramacionDesembolso()"></app-registrar-solicitante>
</p-dialog>