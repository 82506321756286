<form [formGroup]="Form_Request">
    <section class="container">
        <p-toast></p-toast>
        <div class="row">
            <div class="col-lg-4">
                <label for="caja__id">Caja</label>
                <p-dropdown [options]="Cajas" optionValue="id_caja" optionLabel="nombre" inputId="caja__id" formControlName="id_caja">
                </p-dropdown>
            </div>
            <div class="col-lg-4">
                <p-button pRipple severity="success" label="Cerrar caja" (click)="Cerrar_Caja()"></p-button>
            </div>
        </div>
    </section>
</form>