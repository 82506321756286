import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';
import { PeriodoCuentaService } from 'src/app/services/gestion-cuenta/periodo-cuenta.service';

import { FormsModule } from '@angular/forms';
import { alert_success } from 'src/app/functions/alert-function';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
	selector: 'app-cerrar-periodo-cuenta',
	standalone: true,
	imports: [DropdownModule, ButtonModule, ToastModule,CommonModule, ReactiveFormsModule, FormsModule],
	templateUrl: './cerrar-periodo-cuenta.component.html',
	styleUrl: './cerrar-periodo-cuenta.component.css',
	providers: [MessageService],
})
export class CerrarPeriodoCuentaComponent implements OnChanges {
	@Input() Data_Parent: any;
	Cuentas: any[] = [];
	PeriodosCuenta: any[] = [];
	Form_Request: FormGroup;

	constructor(
		private _Cuenta_Service: Cuenta_Service,
		private _Periodo_Cuenta_Serice: PeriodoCuentaService,
		private Fb: FormBuilder,
		
		private changeDetectorRef: ChangeDetectorRef,
		private messageService: MessageService
	) {
		this.Form_Request = this.Fb.group({
			id_cuenta: new FormControl(null, Validators.required),
			id_periodo_cuenta: new FormControl(null, Validators.required),
		})
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes['Data_Parent'] && changes['Data_Parent'].currentValue) {
			this.Form_Request.patchValue({ ...changes['Data_Parent'].currentValue });

			if (this.Form_Request.get('id_cuenta')?.value) {
				this.Get_Periodos_Cuenta_By_CuentaId();
				this.Get_Cuentas_Api();
			}
		}
		this.changeDetectorRef.detectChanges(); // Forzar actualización
	}

	ngOnInit(): void {
	}

	Get_Cuentas_Api(): void {
		this._Cuenta_Service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Cuentas = response.data;
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio => ' + error);
			}
		})
	}

	Get_Periodos_Cuenta_By_CuentaId(): void {
		const request = {
			'cuenta': this.Form_Request.get('id_cuenta')?.value
		}
		this._Periodo_Cuenta_Serice.getByOther(request).subscribe({
			next: (response) => {
				if (response.status == 'OK') {
					this.PeriodosCuenta = response.data;
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio => ' + error);
			}
		});
	}

	Cerrar_PeriodoCuenta(): void {
		console.log(this.Form_Request.value)
		this._Periodo_Cuenta_Serice.create(this.Form_Request.value).subscribe({
			next: (response: any) => {
				if (response.status == 'CREATED') {
					this.messageService.add({
						severity: 'success',
						summary: 'Éxito',
						detail:
						  response.message,
						  life: 6000,
					  });
				}
				else{
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail:
						  response.message,
					  });
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio => ' + error);
			}
		});
	}
}
