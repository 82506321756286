
<form [formGroup]="Form_Request">
    <section class="container">
        <p-toast></p-toast>
        <div class="row">
                <div class="col-lg-4">
                    <label for="caja__id">Caja</label>
                    <p-dropdown [options]="Cajas" optionValue="id_caja" optionLabel="nombre" inputId="caja__id"
                        formControlName="id_caja">
                    </p-dropdown>
                </div>
                <div class="col-lg-4">
                    <label for="periodo_caja__id">Periodo Caja</label>
                    <p-dropdown [options]="PeriodosCaja" optionValue="id_periodo_caja" optionLabel="nombre"
                        inputId="periodo_caja__id" formControlName="id_periodo_caja">
                    </p-dropdown>
                </div>
                <div class="col-lg-4">
                    <p-button pRipple severity="success" label="Cerrar Periodo" (click)="Cerrar_PeriodoCaja()"></p-button>
                </div>
            </div>
        </section>
    </form>