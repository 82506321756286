import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoutes, ElementRoutes, SettingRoutes } from './admin.routes';
import { AdminPageNotFoundComponent } from './views/admin-page-not-found/admin-page-not-found.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { EventsComponent } from './views/events/events.component';
import { TestComponent } from './views/events/test/test.component';
import { ProfileComponent } from './views/settings/profile/profile.component';
import { UsersComponent } from './views/settings/users/users.component';


import { LoginGuard } from '../guards/login.guard';

import { RegistrarReqPagoComponent } from '../pages/gestion-desembolso/registrar-req-pago/registrar-req-pago.component';
import { ReporteReqPagoComponent } from '../pages/gestion-desembolso/reporte-req-pago/reporte-req-pago.component';
import { RegistrarCajaComponent } from '../pages/gestion-caja/registrar-caja/registrar-caja.component';
import { RegistrarCuentaBancComponent } from '../pages/gestion-cuenta/registrar-cuenta-banc/registrar-cuenta-banc.component';
import { ReporteCuentaBancComponent } from '../pages/gestion-cuenta/reporte-cuenta-banc/reporte-cuenta-banc.component';
import { ReporteCajaComponent } from '../pages/gestion-caja/reporte-caja/reporte-caja.component';
import { RegistrarMovimientoCuentaComponent } from '../pages/gestion-cuenta/registrar-movimiento-cuenta/registrar-movimiento-cuenta.component';
import { ReporteLiquidacionGastosComponent } from '../pages/gestion-desembolso/reporte-liquidacion-gastos/reporte-liquidacion-gastos.component';

import { ReporteDesembolsoComponent } from '../pages/gestion-desembolso/reporte-desembolso/reporte-desembolso.component';
import { UploadFileComponent } from '../pages/upload-file/upload-file.component';
import { EditarRequerimientoPagoComponent } from '../pages/gestion-desembolso/editar-requerimiento-pago/editar-requerimiento-pago.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: AdminRoutes.Dashboard,
		pathMatch: 'full',
	},
	////////////
	{
		title: 'Dashboard',
		path: AdminRoutes.Dashboard,
		component: DashboardComponent,
	},

	{
		title: 'Gestión Desembolso',
		path: AdminRoutes.Desembolso,
		children: [
			{
				title: 'Reporte Desembolso',
				path: ElementRoutes.Reporte_desembolso,
				canActivate:[LoginGuard],
				component: ReporteDesembolsoComponent,
			},
			{
				title: 'Reporte Requerimiento Pago',
				path: ElementRoutes.Reporte_requerimiento_pago,
				canActivate:[LoginGuard],
				component: ReporteReqPagoComponent,
			},
			{
				title: 'Registrar Requerimiento Pago',
				path: ElementRoutes.Registrar_requerimiento_pago,
				canActivate:[LoginGuard],
				component: RegistrarReqPagoComponent,
			},
			{
				title: 'Editar Requerimiento Pago',
				path: ElementRoutes.Editar_requerimiento_pago,
				canActivate:[LoginGuard],
				component: EditarRequerimientoPagoComponent,
			},
			{
				title: 'Registrar Liquidación Gastos',
				path: ElementRoutes.Reporte_liquidacion_gastos,
				canActivate:[LoginGuard],
				component: ReporteLiquidacionGastosComponent,
			},


		],
	},
	{
		title: 'Gestión Caja',
		path: AdminRoutes.Caja,
		children: [
			{
				title: 'Registrar  Caja',
				path: ElementRoutes.Registrar_caja,
				canActivate:[LoginGuard],
				component: RegistrarCajaComponent,
			},
			{
				title: 'Reporte  Caja',
				path: ElementRoutes.Reporte_caja,
				canActivate:[LoginGuard],
				component: ReporteCajaComponent,
			},



		],
	},
	{
		title: 'Gestión Cuenta',
		path: AdminRoutes.Cuenta,
		children: [
			{
				title: 'Registrar Cuenta ',
				path: ElementRoutes.Registrar_cuenta,
				canActivate:[LoginGuard],
				component: RegistrarCuentaBancComponent,
			},
			{
				title: 'Reporte Cuenta ',
				path: ElementRoutes.Reporte_cuenta,
				canActivate:[LoginGuard],
				component: ReporteCuentaBancComponent,
			},
			{
				title: 'Movimiento  Cuenta',
				path: ElementRoutes.Movimiento_cuenta,
				canActivate:[LoginGuard],
				component: RegistrarMovimientoCuentaComponent,
			},
		],
	},
	{
		title: 'Test',
		path: AdminRoutes.Test,
		children: [
			{
				title: 'Subir archivo ',
				path: ElementRoutes.Upload_File,
				component: UploadFileComponent,
			}
		],
	},

	//////////


	{ path: '**', component: AdminPageNotFoundComponent },
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AdminRoutingModule { }
