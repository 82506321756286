import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardModule } from 'primeng/card';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import {
	FormArray,
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { CommonModule, formatDate } from '@angular/common';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { Subject, Subscription } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { DialogModule } from 'primeng/dialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { Requerimiento_Pago_Service } from 'src/app/services/gestion-desembolso/registro-req-pago.service';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { Sede_Service } from 'src/app/services/general-services/sede.service';
import { Persona_Service } from 'src/app/services/general-services/persona.service';
import { Moneda_Service } from 'src/app/services/general-services/moneda.service';
import { Banco_Service } from 'src/app/services/general-services/banco.service';
import { Area_Service } from 'src/app/services/general-services/area.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { RegistrarSolicitanteComponent } from '../registrar-solicitante/registrar-solicitante.component';
import { TipoConcepto_Service } from 'src/app/services/general-services/tipo-concepto.service';
import { Concepto_Service } from 'src/app/services/general-services/concepto.service';

@Component({
	selector: 'app-registrar-req-pago',
	standalone: true,
	imports: [
		CardModule,
		InputNumberModule,
		TableModule,
		DropdownModule,
		CalendarModule,
		RegistrarSolicitanteComponent,
		ButtonModule,
		FormsModule,
		InputTextModule,
		TooltipModule,
		DividerModule,
		CommonModule,
		SelectButtonModule,
		FontAwesomeModule,
		DialogModule,
		ToastModule,
		FormsModule,
		FileUploadModule,
		ReactiveFormsModule,
	],
	templateUrl: './registrar-req-pago.component.html',
	styleUrl: './registrar-req-pago.component.css',
	providers: [],
})
export class RegistrarReqPagoComponent {
	visible: boolean = true;
	faTableList = faTableList;
	display: boolean = false;
	rowData: any;
	@Input() title: string = '';
	@Output() closeModalEmmit = new EventEmitter<boolean>();
	@Output() Close_Modal_Event3 = new EventEmitter<void>();
	@Output() registroAgregado = new EventEmitter<any>();
	Display_Modal_ProgramacionDesembolso: boolean = false;
	disableButton: boolean = false;
	Button_Disabled: boolean = false;
	eventsSubject: Subject<void> = new Subject<void>();
	formVisible = false;
	Form_Requerimiento: FormGroup;

	empresas: any[] = [];
	sedes: any[] = [];
	areas: any[] = [];
	personas: any[] = [];
	monedas: any[] = [];
	tipos_concepto: any[] = [];
	conceptos: any[] = [];
	tipos_pago: any[] = [
		{
			id_tipo_pago: 1,
			nombre: 'FÍSICO',
		},
		{
			id_tipo_pago: 2,
			nombre: 'DIGITAL',
		},
	];
	metodos_pago: any[] = [
		{
			id_tipo_pago: 1,
			nombre: 'YAPE',
		},
		{
			id_tipo_pago: 2,
			nombre: 'PLIN',
		},
		{
			id_tipo_pago: 3,
			nombre: 'TRANSFERENCIA',
		},
		{
			id_tipo_pago: 4,
			nombre: 'DEPOSITO ',
		},
		{
			id_tipo_pago: 5,
			nombre: 'EFECTIVO',
		},
	];
	tipos_comprobante: any[] = [
		{
			id_tipo_pago: 1,
			nombre: 'BOLETA DE VENTA',
		},
		{
			id_tipo_pago: 2,
			nombre: 'FACTURA',
		},
		{
			id_tipo_pago: 3,
			nombre: 'RECIBO POR HONORARIOS',
		},
		{
			id_tipo_pago: 4,
			nombre: 'RECIBO DE PAGO',
		},
		{
			id_tipo_pago: 5,
			nombre: 'VOUCHER',
		},
		{
			id_tipo_pago: 6,
			nombre: 'GUIA DE PAGO',
		},
		{
			id_tipo_pago: 7,
			nombre: 'OTROS',
		},

	];
	bancos: any[] = [];

	constructor(
		private fb: FormBuilder,
		private _reque_pago_service: Requerimiento_Pago_Service,
		private _empresaService: Empresa_Service,
		private _sedeService: Sede_Service,
		private _areaService: Area_Service,
		private _peronsaService: Persona_Service,
		private _monedaService: Moneda_Service,
		private _TipoConcepto_Service: TipoConcepto_Service,
		private _Concepto_Service: Concepto_Service,
		private _bancoService: Banco_Service,
		private messageService: MessageService
	) {
		this.Form_Requerimiento = this.fb.group({
			empresa_id: [null, Validators.required],
			sede_id: [null, Validators.required],
			area_id: [null, Validators.required],
			persona_id: [null, Validators.required],
			requerimientos: this.fb.array([]),
		});
	}

	ngOnInit(): void {
		this.getEmpresas_Api();
		this.getMoneda_Api();
		this.Get_TiposConcepto_Api();
		this.Get_Conceptos_Api();
		this.getBanco_Api();
	}
	closeModal(res: boolean) {
		this.closeModalEmmit.emit(res);
	}

	getPersonas_Api(): void {
		this._peronsaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.personas = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	getBanco_Api(): void {
		this._bancoService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.bancos = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_TiposConcepto_Api(): void {
		this._TipoConcepto_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.tipos_concepto = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}
	Get_Conceptos_Api(): void {
		this._Concepto_Service.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.conceptos = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	getMoneda_Api(): void {
		this._monedaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.monedas = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	getEmpresas_Api(): void {
		this._empresaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.empresas = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}
	getSedes_Api(): void {
		this._sedeService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.sedes = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_SedesByEmpresaId_Api(object: DropdownChangeEvent): void {
		let sede_id = object.value;
		let request = {
			'empresa-id': sede_id,
		};
		this._sedeService.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.sedes = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_AreasBySedeId_Api(object: DropdownChangeEvent): void {
		let sede_id = object.value;
		let request = {
			'sede-id': sede_id,
		};
		this._areaService.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.areas = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_PersonasByAreaId_Api(object: DropdownChangeEvent): void {
		let area_id = object.value;
		let request = {
			'area-id': area_id,
		};
		this._peronsaService.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.personas = response_data;
					setTimeout(() => {
						this.eventsSubject.next();
					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	suscription: Subscription;
	loading: boolean = true;
	requerimiento: any = {};
	sede: any = {};
	persona: any = {};
	empresa: any = {};
	area: any = {};
	moneda: any = {};
	tipo_requerimiento: any = {};
	banco: any = {};
	/* Enviar datos del TS AL HTML (DB) */
	requerimieto_pago__db: any[][] = [];
	almacen_requerimieto_pago__db: any[] = []; // array de pobjetos

	//filas que agregar capturar los campos
	get requerimientos(): FormArray {
		return this.Form_Requerimiento.get('requerimientos') as FormArray;
	}

	Create_Requerimiento(): FormGroup {
		return this.fb.group({
			tipo_concepto: [null, Validators.required],
			concepto: [null, Validators.required],
			detalles: [null, Validators.required],
			tipo_pago: [null, Validators.required],
			metodo_pago: [null, Validators.required],
			tipo_comprobante: [null, Validators.required],
			comprobante: [null, Validators.required],
			simbolo_moneda: [null, Validators.required],
			moneda: [null, Validators.required],
			monto: [
				null,
				[Validators.required, Validators.pattern('^\\d+(\\.\\d{2,})?$')],
			],
			nro_cuenta: ['', Validators.required],
			banco_cuenta: ['', Validators.required],
			titular_cuenta: ['', Validators.required],
			fecha_pago: [null, Validators.required],
		});
	}
	onFileSelect(event: any, index: number) {
		const file = event.target.files[0];
		if (file) {
			const requerimiento = this.requerimientos.at(index) as FormGroup;
			requerimiento.patchValue({ comprobante: file });
		}
	}

	//agregar filas en el dom de la tabla
	Add_Requerimiento() {
		this.requerimientos.push(this.Create_Requerimiento());
	}
	//elimina una fila depende al index de la tabla en este caso elimina 1
	eliminarFila(index: number) {
		this.requerimientos.removeAt(index);
	}

	registerRequerimientoPago(): void {
		/* FormData */
		const Form_Caja_Request = new FormData();
		Form_Caja_Request.append(
			'persona_id',
			this.Form_Requerimiento.value.persona_id
		);
		Form_Caja_Request.append(
			'empresa_id',
			this.Form_Requerimiento.value.empresa_id
		);
		Form_Caja_Request.append('area_id', this.Form_Requerimiento.value.area_id);
		Form_Caja_Request.append('sede_id', this.Form_Requerimiento.value.sede_id);

		/* Añadir los requerimientos with FormData */
		this.requerimientos.controls.forEach((requerimiento, index) => {
			const requerimientoForm = requerimiento.value;
			Form_Caja_Request.append(
				`requerimientos[${index}][tipo_concepto]`,
				requerimientoForm.tipo_concepto
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][concepto]`,
				requerimientoForm.concepto
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][detalles]`,
				requerimientoForm.detalles
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][tipo_pago]`,
				requerimientoForm.tipo_pago
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][metodo_pago]`,
				requerimientoForm.metodo_pago
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][tipo_comprobante]`,
				requerimientoForm.tipo_comprobante
			);
			if (requerimientoForm.comprobante) {
				Form_Caja_Request.append(
					`requerimientos[${index}][comprobante]`,
					requerimientoForm.comprobante
				);
			}
			Form_Caja_Request.append(
				`requerimientos[${index}][simbolo_moneda]`,
				requerimientoForm.simbolo_moneda
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][moneda]`,
				requerimientoForm.moneda
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][monto]`,
				requerimientoForm.monto
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][nro_cuenta]`,
				requerimientoForm.nro_cuenta
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][banco_cuenta]`,
				requerimientoForm.banco_cuenta
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][titular_cuenta]`,
				requerimientoForm.titular_cuenta
			);
			Form_Caja_Request.append(
				`requerimientos[${index}][fecha_pago]`,
				formatDate(requerimientoForm.fecha_pago, 'yyyy-MM-dd HH:mm:ss', 'en-US')
			);
		});

		this._reque_pago_service.post(Form_Caja_Request).subscribe({
			next: (response: any) => {
				if (response.status === 'CREATED') {
					this.messageService.add({
						severity: 'success',
						summary: response.title,
						detail: response.message,
						life: 3000,
					});
					this.registroAgregado.emit(response.data);
					this.closeModalEmmit.emit(true);
					this.hideDialog();
					this.Form_Requerimiento.reset({
						id_requerimiento_pago: this.Form_Requerimiento.get(
							'id_requerimiento_pago'
						)?.value,
					});
				} else {
					this.messageService.add({
						severity: 'warn',
						summary: response.title,
						detail: response.message,
						life: 3000,
					});
				}
			},
			error: (err) => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail:
						'Ocurrió un error al comunicarse con el servidor. Inténtelo de nuevo.',
				});
				this.disableButton = false;
			},
		});
	}

	hideDialog() {
		this.Form_Requerimiento.reset({});

		this.Close_Modal_Event3.emit();
	}
	filterInput(event: KeyboardEvent) {
		const allowedKeys = /^[0-9-]+$/;
		if (!allowedKeys.test(event.key)) {
			event.preventDefault();
		}
	}
	Close_Modal_ProgramacionDesembolso() {
		this.Display_Modal_ProgramacionDesembolso = false;
	}
	Open_Modal_ProgramacionDesembolso() {
		this.Display_Modal_ProgramacionDesembolso = true;
	}
}
