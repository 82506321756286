import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TableModule } from 'primeng/table';
import { tableCol } from 'src/app/common/table-cols';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';

@Component({
    selector: 'app-resumen-movimiento-caja',
    standalone: true,
    imports: [TableModule, CommonModule],
    templateUrl: './resumen-movimiento-caja.component.html',
    styleUrls: ['./resumen-movimiento-caja.component.css'],
    providers: [Caja_Service]
})
export class ResumenMovimientoCajaComponent implements OnInit {
    @Input() RowData_Parent: any;
    ResumenMovimiento_Caja: any = {};
    Movimientos: any[] = [];
    Ingresos: any[] = [];
    Egresos: any[] = [];
    id_caja: any;
    formData: any = {};

    constructor(
        private _Caja_Service: Caja_Service,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        // Cargar datos al inicializar el componente
        if (this.RowData_Parent) {
            this.loadData(this.RowData_Parent);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['RowData_Parent'] && changes['RowData_Parent'].currentValue) {
            this.loadData(changes['RowData_Parent'].currentValue);
        }
    }

    private loadData(rowData: any) {
        this.formData = { ...rowData };
        this.id_caja = this.formData.id_caja;

        if (this.id_caja) {
            this.Show_ResumenMovimientosCaja();
        }
    }

    Show_ResumenMovimientosCaja(): void {
        if (!this.id_caja) {
            console.error('ID de caja no definido');
            return;
        }

        const request = {
            'resumen-with-movimiento': this.id_caja
        };

        this._Caja_Service.getByOther(request).subscribe({
            next: (response: any) => {
                if (response.status === 'OK') {
                    this.ResumenMovimiento_Caja = response.data;
                    this.Movimientos = this.ResumenMovimiento_Caja.movimientos;
                    this.Ingresos = this.ResumenMovimiento_Caja.movimientos.ingresos;
                    this.Egresos = this.ResumenMovimiento_Caja.movimientos.egresos;
                } else {
                    console.error('No se encontró ningún dato');
                }
            },
            error: (error) => {
                console.error('Error al consumir el servicio', error);
            },
        });
    }

    ActualizarDatos(): void {
        this.Show_ResumenMovimientosCaja(); // Vuelve a cargar los datos desde el servidor
    }
}
