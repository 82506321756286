export class Liqu_gastos_Response {

  desembolso_id: number | null;
  detalles? : string ;

  detalles_liquidacion_gasto : any;

  constructor () {
      this.desembolso_id = 0;
      this.detalles = '';

      this.detalles_liquidacion_gasto = '';
  }
}
