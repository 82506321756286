import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Button } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { alert_success, alert_warning } from 'src/app/functions/alert-function';
import { UpdateEstadoLiquidacionService } from 'src/app/services/gestion-desembolso/update-estado-liquidacion-gastos.service';

@Component({
  selector: 'app-update-estado-liquidacion-gastos',
  standalone: true,
  imports: [ Button,
    FormsModule,
    InputTextModule],
  templateUrl: './update-estado-liquidacion-gastos.component.html',
  styleUrl: './update-estado-liquidacion-gastos.component.css'
})
export class UpdateEstadoLiquidacionGastosComponent {
  visible: boolean = true;
  @Output() Close_Modal_Event1= new EventEmitter<void>();
  @Input() DataComponent_Parent: any;  // Recibe los datos del componente padre
  formData: any = {};
  selectedItem: any = null; 
  Desembolso_Form: FormGroup;
  @Output() closeModalEmmit = new EventEmitter<boolean>();
  @Output() registrosAgregado = new EventEmitter<any>();
  disableButton: boolean = false;
  DataComponent: any = {
  };

  constructor (
    private fb: FormBuilder,
    private _UpdateLiquidacionPago_Service : UpdateEstadoLiquidacionService,
    private _Message_Service: MessageService
    
  ) {
    this.Desembolso_Form = this.fb.group({});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['DataComponent_Parent'] && changes['DataComponent_Parent'].currentValue) {
      this.DataComponent = { ...changes['DataComponent_Parent'].currentValue };  // Actualiza los datos del formulario
    }
  }

  closeModal(res: boolean) {
    this.closeModalEmmit.emit(res);
  }


  update__estado(): void {
    const request = this.DataComponent;

    this._UpdateLiquidacionPago_Service.update(this.DataComponent_Parent.id_liquidacion_gasto, request).subscribe({
      next: (response: any) => {
        if (response.status === 'CREATED') {
          const nuevoRegistro = response.data;
          // Emite el nuevo estado al componente que lo necesita
          this.registrosAgregado.emit(nuevoRegistro);
          
          this._Message_Service.add({
            severity: 'success',
            summary: response.title,
            detail: response.message,
            life: 6000,
          });

  
          // Cierra el modal y realiza otras acciones
          this.closeModalEmmit.emit(true);
       
          this.closeModal(true);
          this.Close_Modal_f();
        } else {
          this._Message_Service.add({
            severity: 'error',
            summary: response.title,
            detail: response.message,
            life: 6000,
          });
        }
      },
      error: (err) => {
        alert_warning('Hubo un error al actualizar el estado del desembolso');
        this.disableButton = false;
      }

    });
    this.Close_Modal_Event1.emit();
  }
  
  Close_Modal_f() {
    this.Close_Modal_Event1.emit();
  }

}
