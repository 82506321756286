<p-toast></p-toast>
<p-card class="custom-card">

	<form class="formula" [formGroup]="Update_Requerimiento_pago_Form">

		<div class="row mt-5 mb-5">
			<!-- Primera fila: Empresa, Sede, Área -->

			<div class="col-lg-2">
				<p-floatLabel>
					<p-dropdown [showClear]="true" placeholder="Empresa" [options]="empresas" optionValue="id_empresa"
						optionLabel="nombre" formControlName="empresa_id" (onChange)="Get_SedesByEmpresaId_Api($event)">
					</p-dropdown>
					<label>Empresa</label>
				</p-floatLabel>

			</div>




			<div class="col-lg-2">
				<p-floatLabel>
					<p-dropdown [showClear]="true" placeholder="Sede" [options]="sedes" optionValue="id_sede"
						optionLabel="nombre" formControlName="sede_id" (onChange)="Get_AreasBySedeId_Api($event)">
					</p-dropdown>
					<label>Sede</label>
				</p-floatLabel>
			</div>



			<div class="col-lg-2">
				<p-floatLabel>
					<p-dropdown [showClear]="true" placeholder="Área" [options]="areas" optionValue="id_area"
						optionLabel="nombre" formControlName="area_id" (onChange)="Get_PersonasByAreaId_Api($event)">
					</p-dropdown>
					<label>Área</label>
				</p-floatLabel>
			</div>


			<!-- Segunda fila: Solicitante, Tipo Concepto, Concepto -->

			<div class="col-lg-2">
				<p-floatLabel>
					<p-dropdown [showClear]="true" placeholder="Solicitante" [options]="personas"
						optionValue="id_persona" optionLabel="nombres" formControlName="persona_id">
					</p-dropdown>
					<label>Solicitante</label>
				</p-floatLabel>
			</div>

		</div>


		<div class="row mt-5 mb-5">

			<div class="col-lg-2">
				<p-floatLabel>
					<p-dropdown [showClear]="true" placeholder="Tipo Concepto" [options]="tipos_concepto"
						optionValue="nombre" optionLabel="nombre" formControlName="tipo_concepto">
					</p-dropdown>
					<label>Tipo Concepto</label>
				</p-floatLabel>
			</div>

			<div class="col-lg-2">
				<p-floatLabel>
					<input type="text" class="input_custom" pInputText formControlName="concepto" />
					<label>Concepto</label>
				</p-floatLabel>
			</div>


			<!-- Tercera fila: Tipo Pago, Método Pago, Comprobante -->
			<div class="col-lg-2">

				<p-floatLabel>
					<p-dropdown [showClear]="true" [options]="tipos_pago" optionValue="nombre" optionLabel="nombre"
						formControlName="tipo_pago"></p-dropdown>
					<label>Tipo Pago</label>
				</p-floatLabel>
			</div>

			<div class="col-lg-2">
				<p-floatLabel>
					<p-dropdown [showClear]="true" [options]="metodos_pago" optionValue="nombre" optionLabel="nombre" f
						formControlName="metodo_pago"></p-dropdown>
					<label>Método Pago</label>
				</p-floatLabel>
			</div>
		</div>


		<div class="row mt-5 mb-5">
			<label>Comprobante</label>
			<div class="col-lg-2">
				<p-floatLabel>
					<input type="file" formControlName="comprobante" class="form-control input-file" />

				</p-floatLabel>
			</div>


			<!-- Cuarta fila: Moneda, Monto, Nº Cuenta -->
			<div class="col-lg-2">
				<p-floatLabel>
					<p-dropdown [showClear]="true" [options]="monedas" optionValue="simbolo" optionLabel="simbolo"
						formControlName="simbolo_moneda">
					</p-dropdown>
					<label>Simbolo Moneda</label>
				</p-floatLabel>
			</div>
			<div class="col-lg-2">
				<p-floatLabel>
					<p-dropdown [showClear]="true" [options]="monedas" optionValue="nombre" optionLabel="nombre"
						formControlName="moneda">
					</p-dropdown>
					<label> Moneda</label>
				</p-floatLabel>
			</div>

			<div class="col-lg-2">
				<p-floatLabel>
					<p-inputNumber inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
						[maxFractionDigits]="5" formControlName="monto" />
					<label>Monto</label>
				</p-floatLabel>
			</div>

		</div>

		<!-- Quinta fila: Banco, Titular, Fecha de Pago -->

		<div class="row mt-5 mb-5">
			<div class="col-lg-2">
				<p-floatLabel>
					<input type="text" class="input_custom" title="Solo se permiten números y guiones" pInputText
						formControlName="nro_cuenta" />
					<label>Nº Cuenta</label>
				</p-floatLabel>
			</div>
			<div class="col-lg-2">

				<p-floatLabel>
					<p-dropdown [showClear]="true" [options]="bancos" optionValue="nombre" optionLabel="nombre"
						formControlName="banco_cuenta">
					</p-dropdown>
					<label>Banco</label>
				</p-floatLabel>
			</div>
			<div class="col-lg-2">
				<p-floatLabel>
					<input type="text" class="input_custom" pInputText formControlName="titular_cuenta" />
					<label>Titular</label>
				</p-floatLabel>
			</div>

			<div class="col-lg-2">
				<p-calendar optionValue="fecha_pago" [showTime]="false" dateFormat="yy-mm-dd"
					formControlName="fecha_pago">
				</p-calendar>
			</div>


		</div>

		<!-- Botón Registrar -->
		<p-card class="mt-4">
			<div class="register-button text-center button-group">
				<button pButton type="button" class="p-button-success mb-3" icon="pi pi-save" label="Registrar" 
					(click)="Update_Requerimiento_Pago()">
				</button>
				<p-button label="Cancelar" (click)="hideDialog()" [raised]="true" severity="danger"></p-button>
			</div>
			
		</p-card>
	</form>
</p-card>