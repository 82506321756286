import { Component, Input, TemplateRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableModule } from 'primeng/table';

import { RegistrarReqPagoComponent } from '../registrar-req-pago/registrar-req-pago.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ButtonAction } from 'src/app/common/button-action';
import { accionConst } from 'src/app/constants/general-constats';
import { Subject } from 'rxjs';
import { Liqu_gastos_Response } from 'src/app/response/gestion-desembolso/liquidacion-gastos-response';
import { tableCol } from 'src/app/common/table-cols';
import { FilterForm } from 'src/app/common/filter-form';
import { Liquidacion_Gastos_Service } from 'src/app/services/gestion-desembolso/liquidacion-gastos.service';
import { FilterTable } from 'src/app/common/filter-table';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { RegistrarLiquidacionGastosComponent } from '../registrar-liquidacion-gastos/registrar-liquidacion-gastos.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuItem } from 'primeng/api';
import { UpdateEstadoLiquidacionGastosComponent } from '../update-estado-liquidacion-gastos/update-estado-liquidacion-gastos.component';
import { DetalleLiquidacionGastoComponent } from '../detalle-liquidacion-gasto/detalle-liquidacion-gasto.component';
import { CommunicationService } from 'src/app/services/general-services/comunicacion.service';
import { EditarLiquidacionGastosComponent } from '../editar-liquidacion-gastos/editar-liquidacion-gastos.component';
@Component({
  selector: 'app-reporte-liquidacion-gastos',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TableModule,
    InputIconModule,
    IconFieldModule,

    RegistrarLiquidacionGastosComponent,
    UpdateEstadoLiquidacionGastosComponent,
    CommonModule,
    ButtonModule,
    DetalleLiquidacionGastoComponent,
    EditarLiquidacionGastosComponent,
    DialogModule,
    FormsModule,
    PaginatorModule,
    InputTextModule,
    SplitButtonModule,
  ],
  templateUrl: './reporte-liquidacion-gastos.component.html',
  styleUrl: './reporte-liquidacion-gastos.component.css',
  providers: [BsModalService, SharedDataService],
})
export class ReporteLiquidacionGastosComponent {
  @Input() Liq_Gastos_Response: any[] = [];
  
  faTableList = faTableList;
  config = bsConfigModal;
  selectedRowData :any;
  visible: boolean = false;
  modal_editar = false;
  modalref: BsModalRef = new BsModalRef();
  totalRecords: number = 0;
  loading: boolean = false;
  RowData: any;
  display: boolean = false;
  displayModal: boolean = false;
  titleModal: string = '';
  example: {};
  buttonActions: ButtonAction[] = [
    { accion: accionConst.add, data: {}, active: false },
    { accion: accionConst.edit, data: {}, active: false },
    { accion: accionConst.delete, data: {}, active: false },
    { accion: accionConst.config, data: {}, active: false },
    { accion: accionConst.detalles, data: {}, active: true },
    { accion: accionConst.aprobar, data: {}, active: true },
  ];

  eventsSubject: Subject<void> = new Subject<void>();
  valuesTable: Liqu_gastos_Response[] = [];

  cols: tableCol[] = [
    { field: 'id_liquidacion_gasto', header: 'ID' },
    { field: 'codigo', header: 'Código' },
    { field: 'persona__solicita', header: 'Solicitante' },
    { field: 'monto_desembolso', header: 'Desembolso' },
    { field: 'gasto_desembolso', header: 'Gasto' },
    { field: 'saldo_desembolso', header: 'Saldo' },
    { field: 'estado', header: 'Estado' },
    { field: 'detalles_estado', header: ' Detalles Estado' },
    { field: 'Accion', header: ' Accion' },
  ];

  filterForm: FilterForm[] = [];
  IngresoAlmacen_Selected: Liqu_gastos_Response = new Liqu_gastos_Response();

  constructor(
    private _Liquidacion_gastos_service: Liquidacion_Gastos_Service,
    private communicationService: CommunicationService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.GetLiquidacionGastos_Api();
    this.communicationService.data$.subscribe((data) => {
      this.Liq_Gastos_Response = data;
      
    });
  }

  GetLiquidacionGastos_Api(): void {
    this.loading = false;
    this._Liquidacion_gastos_service.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        if (response_data) {
          // Reemplaza la lista local con los datos más recientes
          this.Liq_Gastos_Response = response_data;

          // Notifica a los suscriptores de los eventos si es necesario
          setTimeout(() => {
            this.eventsSubject.next();
          }, 200);
        } else {
          console.error('No se encontró ningún dato');
        }
      },
      error: (error) => {
        this.loading = true;
        console.log('Error al llamar el servicio', error);
      },
    });
  }

  /////
  Show_Modal: string | null = null;

  optionsEstadoButtonMap: Map<any, MenuItem[]> = new Map();
  getOptionsEstado_Button(RowData_R: any): MenuItem[] {
    if (!this.optionsEstadoButtonMap.has(RowData_R)) {
      const options = [
       
        {
          label: 'OBSERVAR',
          command: () => this.Open_Modal_f(RowData_R, 'OBSERVADO'),
        },
      
        {
          label: 'LIQUIDAR',
          command: () => this.Open_Modal_f(RowData_R, 'LIQUIDADO'),
        },
      ];
      this.optionsEstadoButtonMap.set(RowData_R, options);
    }
    return this.optionsEstadoButtonMap.get(RowData_R) || [];
  }

  ActualizarEstadoAgregado(nuevoRegistro: any): void {
    if (nuevoRegistro && nuevoRegistro.id_liquidacion_gasto) {
      const index = this.Liq_Gastos_Response.findIndex(
        (item) =>
          item.id_liquidacion_gasto === nuevoRegistro.id_liquidacion_gasto
      );

      if (index > -1) {
        this.Liq_Gastos_Response[index] = {
          ...this.Liq_Gastos_Response[index],
          estado: nuevoRegistro.estado,
        };
      } else {
        this.Liq_Gastos_Response.push(nuevoRegistro);
      }

      // Opcional: Recargar los datos desde la API
      this.GetLiquidacionGastos_Api();

      this.hideDialog();
    } else {
      console.error('El nuevo registro es undefined o no tiene un id válido');
    }
  }

  ActualizarRegistroAgregado(nuevoRegistro: any): void {
    if (nuevoRegistro && nuevoRegistro.id_liquidacion_gasto) {
      const index = this.Liq_Gastos_Response.findIndex(
        (item) =>
          item.id_liquidacion_gasto === nuevoRegistro.id_liquidacion_gasto
      );
      if (index > -1) {
        // Actualiza el registro existente
        this.Liq_Gastos_Response[index] = nuevoRegistro;
      } else {
        // Agrega nuevo registro al inicio de la lista
        this.Liq_Gastos_Response.unshift(nuevoRegistro);
      }

      // Llama a la API para obtener la lista completa actualizada
      this.GetLiquidacionGastos_Api();
    } else {
      console.error('El nuevo registro es undefined o no tiene un id válido');
    }
    this.hideDialog();
  }

  findProductosArray(data: any): any[] | null {
    // Si la respuesta no es un array, busca en las propiedades
    for (const arr in data) {
      console.log('entrado a este for');
      if (Array.isArray(data[arr])) {
        // Si encuentra un array dentro de una propiedad, devuélvelo
        return data[arr];
      }
    }
    // Si no encuentra un array de productos, devuelve null
    return null;
  }
  changeFilter(name: string, array: FilterTable[]) {
    this.filterForm.map((x) => {
      if (x.name == name) {
        x.data = array;
      }
    });
  }
  recibeAction(
    data: ButtonAction,
    template: TemplateRef<any>,
    templateconfigUser: TemplateRef<any>
  ) {
    this.IngresoAlmacen_Selected = data.data;
    switch (data.accion) {
      case accionConst.add:
        this.IngresoAlmacen_Selected = new Liqu_gastos_Response();
        this.addElement(template);
        break;
      case accionConst.edit:
        this.editElement(template);
        break;
      case accionConst.config:
        this.configElement(templateconfigUser);
        break;
      case accionConst.delete:
        break;
    }
  }
  addElement(template: TemplateRef<any>) {
    this.titleModal = 'Registro Liquidaciòn Gastos';
    this.openModalWithClass(template, 'modal-xl modal-custom');
  }

  editElement(template: TemplateRef<any>) {
    this.titleModal = 'Editar Producto';
    this.openModalWithClass(template, 'gray modal-md');
  }
  configElement(template: TemplateRef<any>) {}

  // deleteElement(data: ProductResponse) {}
  openModalWithClass(template: TemplateRef<any>, customclass: string) {
    this.modalref = this.modalService.show(
      template,
      Object.assign({}, { class: customclass }, this.config)
    );
  }

  closeModal(res: any) {
    this.modalref.hide();
    if (res) {
    }
  }
  showDialog2(RowData_R: any) {
    this.RowData = RowData_R;
    this.visible = true;
  }

  showDialog() {
    this.display = true;
  }
  hideDialog() {
    this.display = false;
  }
  Close_Modal() {
    this.display = false;
  }
  Open_Modal_f(RowData_R: any, Action: string): void {
    this.RowData = { ...RowData_R };
    this.Show_Modal = Action;
    this.RowData.estado = Action;
    this.displayModal = true;
  }
  Close_Modal_f() {
    this.displayModal = false;
  }
  Close_Modal2() {
    this.visible = false;
  }
  hideDialog2() {
    this.visible = false;
  }
  getEstadoClass(estado: string): string {
    // Normaliza el valor del estado
    const estadoNormalizado = estado ? estado.trim().toLowerCase() : '';

    switch (estadoNormalizado) {
      
      case 'pendiente':
        return 'estado-pendiente';
      case 'liquidado':
        return 'estado-liquidado';
      case 'observado':
        return 'estado-observado';
      default:
        return '';
    }
  }

  Editar(rowData: Liqu_gastos_Response) {
    this.selectedRowData = { ...rowData }; // Clonar los datos para evitar mutaciones
    this.modal_editar = true; // Abrir el modal de edición
  }
  
    Close_Modal_Editar() {
      this.modal_editar = false;
    }
    
}
