import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PublicModule } from './public/public.module';

import { httpInterceptorProviders } from './_core/interceptors/interceptors.provider';
import { StrategyProviders } from "./_core/strategies/strategy.providers";
import { UtilsProviders } from "./shared/utils/utils.providers";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';


@NgModule({
    declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent],
    imports: [BrowserModule,
        AppRoutingModule,
        AdminModule,
        PublicModule,
        BrowserAnimationsModule,
        FormsModule, ToastModule],
    providers: [
        httpInterceptorProviders,
        StrategyProviders,
        UtilsProviders,
        
    MessageService, // Asegúrate de que el servicio esté aquí
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
