import { Component, Input, TemplateRef } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faL, faTableList } from '@fortawesome/free-solid-svg-icons';

import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import { ButtonAction } from 'src/app/common/button-action';
import { FilterForm } from 'src/app/common/filter-form';
import { FilterTable } from 'src/app/common/filter-table';
import { bsConfigModal } from 'src/app/common/modal-constats';
import { tableCol } from 'src/app/common/table-cols';
import { accionConst } from 'src/app/constants/general-constats';

import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';

import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { RegistrarCajaComponent } from '../registrar-caja/registrar-caja.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SharedDataService } from 'src/app/services/util/SharedData.service';
import { Caja_Response } from 'src/app/response/gestion-caja/caja-response';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';
import { CommunicationService } from 'src/app/services/general-services/comunicacion.service';
import { IconFieldModule } from 'primeng/iconfield';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputIcon, InputIconModule } from 'primeng/inputicon';
import { Caja_Request } from 'src/app/request/gestion-caja/caja-request';
import { DetallesCajaComponent } from '../detalles-caja/detalles-caja.component';
import { HttpHeaders } from '@angular/common/http';
import { validate_frontend_Service } from 'src/app/services/util/validate.service';

@Component({
  selector: 'app-reporte-caja',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TableModule,

    RegistrarCajaComponent,
    CommonModule,
    ButtonModule,
    DialogModule,
    FormsModule,
    PaginatorModule,
    InputTextModule,
    IconFieldModule,
    SplitButtonModule,
    InputIconModule,
    DetallesCajaComponent
],
  templateUrl: './reporte-caja.component.html',
  styleUrl: './reporte-caja.component.css',
  providers: [BsModalService, SharedDataService],
})
export class ReporteCajaComponent {
  
  faTableList = faTableList;
  config = bsConfigModal;
  
  modalRef: BsModalRef | undefined;
  totalRecords: number = 0;
  loading: boolean = false;
  titleModal: string = '';
  Display_Modal_RegistrarCaja = false;
  Display_Modal_DetallesCaja = false;
  RowData: Caja_Response = new Caja_Response;

  buttonActions: ButtonAction[] = [
    { accion: accionConst.add, data: {}, active: true },
    { accion: accionConst.edit, data: {}, active: false },
    { accion: accionConst.delete, data: {}, active: false },
    { accion: accionConst.config, data: {}, active: false },
    { accion: accionConst.detalles, data: {}, active: false },
    { accion: accionConst.aprobar, data: {}, active: false },
  ];

  eventsSubject: Subject<void> = new Subject<void>();
  valuesTable: Caja_Response[] = [];
  display = false;

  cols: tableCol[] = [
    { field: 'nombre_empresa', header: 'Empresa' },
    { field: 'nombre_sede', header: 'Sede' },  
    { field: 'nombre_persona', header: 'Persona' },
    { field: 'fecha_inicio', header: 'Fecha Inicio' },
    { field: 'fecha_fin', header: 'Fecha Fin' },
    { field: 'moneda', header: 'Moneda' },
    { field: 'monto_inicial', header: 'Monto Inicial' },
    { field: 'monto_actual', header: 'Monto Actual' },
    { field: 'detalles', header: 'Detalles' },
    { field: 'estado', header: 'Estado' }
  ];

  Caja_Response: Caja_Response = new Caja_Response;
  filterForm: FilterForm[] = [];
  Caja_Selected: Caja_Response = new Caja_Response();
  @Input() req_Pago_Response: any[] = [];

  constructor(
    private _Caja_Service: Caja_Service,
    private modalService: BsModalService,
    private communicationService: CommunicationService,
    private __validateFrontend_Service : validate_frontend_Service
  ) {}

  ngOnInit(): void {
    this.ValidateFront();
    this.GetCaja_Api();
    this.communicationService.data$.subscribe((data) => {
      this.req_Pago_Response = data;
    });
  }


  ActualizarRegistroAgregado(nuevoRegistro: any): void {
    this.req_Pago_Response.push(nuevoRegistro); // Actualiza la lista de datos
    this.hideDialog();
  }

  ValidateFront():void{
    this.__validateFrontend_Service.post({}).subscribe({
      next : (response : any) =>{
        if(response.status == "OK"){
          console.log("valdacion correcta");
        }
      
      },
      error: (error) => {
        this.loading = true;
        console.log('Error al llamar el servicio', error);
      },
    })

  }

  GetCaja_Api() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('token')}`, // Agregar el token aquí
    });
    this.loading = false;
    this._Caja_Service.get().subscribe({
      next: (response: any) => {
        const response_data = response.data;
        this.loading = true;
        if (response_data) {
          this.Caja_Response = response_data;
          
          this.req_Pago_Response = response_data;
        } else {
          console.error('No se encontró ningún dato');
        }
      },
      error: (error) => {
        this.loading = true;
        console.log('Error al llamar el servicio', error);
      },
    });
  }



  findProductosArray(data: any): any[] | null {
    for (const arr in data) {
      if (Array.isArray(data[arr])) {
        return data[arr];
      }
    }
    return null;
  }
  changeFilter(name: string, array: FilterTable[]) {
    this.filterForm.map((x) => {
      if (x.name == name) {
        x.data = array;
      }
    });
  }
  ///MODALS///
  Open_Modal_RegistrarCaja() {
    this.modalRef = this.modalService.show(RegistrarCajaComponent, {
      class: 'modal-xl modal-custom',  
    });
    
    if (this.modalRef.content) { 
      this.modalRef.content.registroAgregado.subscribe(() => {
        this.GetCaja_Api();
        this.Close_Modal(); // Cierra el modal
      });
      // Suscribirse al evento de cierre del modal
      this.modalRef.content.Close_Modal_RegistrarCaja_Event.subscribe(() => {
        this.Close_Modal(); // Cierra el modal cuando se emite el evento de cierre
      });
    }
  }

    Close_Modal() {
    if (this.modalRef) {
      this.modalRef.hide(); // Usa hide() en lugar de modificar display
    }
  }

  Close_Modal_RegistrarCaja() {
    this.Display_Modal_RegistrarCaja = false;
  }
  
  Open_Modal_DetallesCaja( RowData: Caja_Response ) {
    this.RowData = RowData;
    this.Display_Modal_DetallesCaja = true;
  }
  Close_Modal_DetallesCaja(  ) {
    this.Display_Modal_DetallesCaja = false;
  }

  hideDialog() {
    this.display = false;
  }
}
