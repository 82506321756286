import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRedirecting = false;
	private cancelPendingRequests = new Subject<void>();

	constructor(private router: Router) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = localStorage.getItem('token');
		if (token) {
			const clonedRequest = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			});
			return next.handle(clonedRequest);
		}

		return next.handle(req);

		return next.handle(req).pipe(
			takeUntil(this.cancelPendingRequests),
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401 && !this.isRedirecting) {
					this.isRedirecting = true;
					this.cancelPendingRequests.next();
					this.router.navigate(['/login']).then(() => {
						this.isRedirecting = false;
					});
				}
				return throwError(() => error);
			})
		);
	}
}
