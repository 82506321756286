<div class="p-field">
  <label for="area_id">Empresa:</label>
  <p-dropdown [showClear]="true" (onChange)="onEmpresaChange($event)" placeholder="Selecciona Empresa"
    [options]="empresas" optionValue="id_empresa" optionLabel="nombre" [(ngModel)]="empresaSeleccionada">
  </p-dropdown>
</div>

<div class="p-field">
  <label for="banco_cuenta">Cuenta:</label>
  <p-dropdown [showClear]="true" (onChange)="onCuentaChange($event)" placeholder="Selecciona Cuenta" [options]="cuentas"
    optionValue="id_cuenta" optionLabel="nombre" [(ngModel)]="cuentaSeleccionada" (onChange)="Get_PeriodosCuenta_By_CuentaId_Api()">
  </p-dropdown>
</div>



<div class="p-field">
  <label for="concepto">Periodo:</label>
  <p-dropdown [showClear]="true" placeholder="Selecione Periodo" [options]="periodos_cuenta" optionValue="id_periodo_cuenta"
    optionLabel="nombre" [(ngModel)]="p_desembolso.periodo_cuenta_id"></p-dropdown>
</div>
<div class="p-field">
  <label for="concepto">Persona Autoriza:</label>
  <p-dropdown [showClear]="true" placeholder="Selecciona Persona" [options]="personas" optionValue="id_persona"
    optionLabel="nombre_completo" [(ngModel)]="p_desembolso.id__persona__autorizada"></p-dropdown>
</div>
<div class="p-field">
  <label for="fecha_programacion">Fecha de programación:</label>
  <p-calendar appendTo="body" id="fecha_programacion" class="d-block" [(ngModel)]="p_desembolso.fecha_programacion"></p-calendar>
</div>

<div class="p-field">
  <label for="detalles">Observación:</label>
  <input id="detalles"  pInputText [(ngModel)]="formData.observaciones" name="observaciones"
    class="full-width input" />
</div>


<div class="p-field">
  <label for="detalles">Simobolo moneda</label>
  <input id="detalles" readonly pInputText [(ngModel)]="formData.simbolo_moneda" name="nro_cuenta"
    class="full-width readonly-input" />
</div>
<div class="p-field">
  <label for="detalles">Moneda</label>
  <input id="detalles" readonly pInputText [(ngModel)]="formData.moneda" name="banco_cuenta"
    class="full-width readonly-input" />
</div>
<div class="p-field">
  <label for="detalles">Monto:</label>
  <input id="detalles" readonly pInputText [(ngModel)]="formData.monto" name="titular_cuenta"
    class="full-width readonly-input" />
</div>

<div class="p-field">
  <label for="detalles">nro_cuenta:</label>
  <input id="detalles" readonly pInputText [(ngModel)]="formData.nro_cuenta" name="titular_cuenta"
    class="full-width readonly-input" />
</div>
<div class="p-field">
  <label for="detalles">banco_cuenta:</label>
  <input id="detalles" readonly pInputText [(ngModel)]="formData.banco_cuenta" name="titular_cuenta"
    class="full-width readonly-input" />
</div>
<div class="p-field">
  <label for="detalles">titular_cuenta:</label>
  <input id="detalles" readonly pInputText [(ngModel)]="formData.titular_cuenta" name="titular_cuenta"
    class="full-width readonly-input" />
</div>

<!-- Agrega los demás campos del formulario aquí -->

<div class="button-register">
  <p-button  (click)="registerProgramacionDesembolso()" label="Registrar" class="booton-r"></p-button>
  <p-button label="Cancelar" (click)="hideDialog()" [raised]="true" class="booton-r" severity="danger"></p-button>

</div>