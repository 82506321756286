import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { Caja_Service } from 'src/app/services/gestion-caja/gestion_caja.service';

@Component({
	selector: 'app-cerrar-caja',
	standalone: true,
	imports: [DropdownModule, ButtonModule, ToastModule, CommonModule, ReactiveFormsModule, FormsModule],
	templateUrl: './cerrar-caja.component.html',
	styleUrl: './cerrar-caja.component.css',
	providers: [MessageService],
})
export class CerrarCajaComponent implements OnChanges {
	@Input() Data_Parent: any;
	Form_Request: FormGroup;
	Cajas: any[] = [];

	constructor(
		private _Caja_Service: Caja_Service,
		private _Message_Service: MessageService,
		private changeDetectorRef: ChangeDetectorRef,
		private Fb: FormBuilder,
	) {
		this.Form_Request = this.Fb.group({
			id_caja: new FormControl(null, Validators.required)
		})
	};

	ngOnChanges(changes: SimpleChanges) {
		console.log('Caja => '+ JSON.stringify(changes['Data_Parent'].currentValue));
		if (changes['Data_Parent'] && changes['Data_Parent'].currentValue) {
			this.Form_Request.patchValue({ ...changes['Data_Parent'].currentValue });

			if (this.Form_Request.get('id_caja')?.value) {
				this.Get_Cajas_Api();
			}
		}
		this.changeDetectorRef.detectChanges(); // Forzar actualización
	}

	Get_Cajas_Api(): void {
		this._Caja_Service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Cajas = response.data;
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio => ' + error);
			}
		})
	}

	Cerrar_Caja(): void {
		this._Caja_Service.post(this.Form_Request.value, 'caja/cerrar').subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this._Message_Service.add({
						severity: 'success',
						summary: 'Éxito',
						detail:
							response.message,
						life: 6000,
					});
				}
				else {
					this._Message_Service.add({
						severity: 'error',
						summary: 'Error',
						detail:
							response.message,
					});
				}
			}, error: (error) => {
				console.log('Ocurrió un error al consumir el servicio => ' + error);
			}
		});
	}

}
