import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Test_Service } from 'src/app/services/test/test.service';

@Component({
  selector: 'app-upload-file',
  standalone: true,
  imports: [],
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.css'
})

export class UploadFileComponent {

  selectedFile: File;
  apiUrl = 'https://api-sigfi.cloud.elastika.pe/api/v1/upload-file'; // Reemplaza con la URL de tu API

  imagen_url: string;

  constructor(
    private _Test_Service : Test_Service
  ) { }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);  
    this._Test_Service.create(formData)
    .subscribe(response => {
      this.imagen_url = 'https://api-sigfi.cloud.elastika.pe' + response.path;
      console.log('archivo subido');
    });
  }
//   onSubmit() {
//     const formData = new FormData();
//     formData.append('file', this.selectedFile, this.selectedFile.name);  
//  console.log(formData);


//     this.http.post(this.apiUrl, formData, {
//       reportProgress: true,
//       observe: 'events'
//     })
//     .subscribe(response => {
//       this.imagen_url = response;
//       console.log('archivo subido');
//     });
//   }

}
