import { CommonModule } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Input,
	SimpleChanges,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { Cuenta_Response } from 'src/app/response/gestion-cuenta/cuenta-response';
import { Area_Service } from 'src/app/services/general-services/area.service';
import { Empresa_Service } from 'src/app/services/general-services/empresa.service';
import { Persona_Service } from 'src/app/services/general-services/persona.service';
import { Sede_Service } from 'src/app/services/general-services/sede.service';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';
import { PeriodoCuentaService } from 'src/app/services/gestion-cuenta/periodo-cuenta.service';
import { Desembolso_Service } from 'src/app/services/gestion-desembolso/desembolso.service';
import { ReporteDesembolsoService } from 'src/app/services/gestion-desembolso/reporte-desembolso.service';

@Component({
	selector: 'app-editar-desembolso',
	standalone: true,
	imports: [
		FormsModule,

		CardModule,
		InputNumberModule,
		TableModule,
		DropdownModule,
		CalendarModule,
		ButtonModule,
		FloatLabelModule,
		InputTextModule,
		TooltipModule,
		DividerModule,
		CommonModule,
		SelectButtonModule,
		FontAwesomeModule,
		DialogModule,
		ToastModule,
		FileUploadModule,
		ReactiveFormsModule,
	],
	templateUrl: './editar-desembolso.component.html',
	styleUrl: './editar-desembolso.component.css',
})
export class EditarDesembolsoComponent {
	@Input() data: any;
	cuentas: any[] = [];
	Update_Desembolso_Form: FormGroup;
	id_desembolso: any;
	empresas: any[] = [];
	sedes: any[] = [];
	empresas_cuenta: any[] = [];
	sedes_cuenta: any[] = [];
	periodos_cuenta_id: any;
	personas: any[] = [];
	areas: any[] = [];
	periodos_cuenta: any[] = [];
	desembolso: any = {};
	@Input() Desembolso_Response: any[] = [];

	constructor(
		private fb: FormBuilder,
		private _Cuenta_Service: Cuenta_Service,
		private changeDetectorRef: ChangeDetectorRef,
		private _ReporteDesembolso_Service: Desembolso_Service,
		private _peronsaService: Persona_Service,
		private _PeriodoCuenta_service: PeriodoCuentaService,
		private _sedeService: Sede_Service,
		private _areaService: Area_Service,
		private _empresaService: Empresa_Service,
		private _Desembolso_Service: Desembolso_Service,
		private _Message_Service: MessageService
	) {
		this.Update_Desembolso_Form = this.fb.group({
			id_desembolso: [null, Validators.required],
			cuenta_id: [null, Validators.required],
			periodo_cuenta_id: [null, Validators.required],
			empresa_id: [null, Validators.required],
			sede_id: [null, Validators.required],
			area_id: [null, Validators.required],
			detalles: [null],
			observaciones: [null],
			persona_id: [null, Validators.required],
			fecha_programacion: [null, Validators.required],
			persona_id__autoriza: [null],

			
			sede_id_cuenta: [null, Validators.required],
			empresa_id_cuenta: [null, Validators.required],
		});
	}
	///aqui trae el desembolsoo//
	ngOnChanges(changes: SimpleChanges) {
		if (changes['data'] && changes['data'].currentValue) {

			this.Update_Desembolso_Form.patchValue(changes['data'].currentValue);

			// this.periodos_cuenta_id = changes['data'].currentValue.requerimiento_pago_id;
			this.id_desembolso =
				this.Update_Desembolso_Form.get('id_desembolso')?.value;
			if (this.id_desembolso) {
				this.Get_Desembolsos();
			}
		}
		this.changeDetectorRef.detectChanges();
	}
	ngOnInit(): void {
		this.Get_Cuentas_Api();
		this.Get_Desembolsos_By_Id_Api();
		this.getEmpresas_Api();
		this.getEmpresasCuenta_Api();
		this.getSedes_Api();
		this.getSedesCuenta_Api();
		this.getPersonas_Api();
		this.getArea_Api();
		this.Get_PeriodosCuenta_Api();
	}
	onPersonaChange(event: any): void {
		const personaId = event.value;
		const selectedPersona = this.personas.find(persona => persona.id_persona === personaId);

		if (selectedPersona && selectedPersona.area_id) {
			this.Update_Desembolso_Form.patchValue({
				area_id: selectedPersona.area_id
			});

			// Si es necesario, obtén los detalles del área
			const area = this.areas.find(a => a.id_area === selectedPersona.area_id);
		}
	}

	Get_Desembolsos(): void {
		this._ReporteDesembolso_Service.getByid(
			this.Update_Desembolso_Form.get('id_desembolso')?.value
		).subscribe({
			next: (response: any) => {
				if (response.data) {
					this.desembolso = { ...response.data };
					const periodo_cuenta_id = this.desembolso.periodo_cuenta_id;
					this.Update_Desembolso_Form.patchValue({
						monto_desembolso: this.desembolso.monto,
					});
				} else {
					console.log('No se encontraron datos.');
				}
			},
			error: (error) => {
				console.log('Error al obtener los datos de desembolso:', error);
			}
		});
	}

	//traer los datos id
	Get_Desembolsos_By_Id_Api(): void {
		this._ReporteDesembolso_Service.getByid(this.data.id_desembolso).subscribe({
			next: (response: any) => {
				this.Update_Desembolso_Form.patchValue(response?.data || {});
				this.Get_Persona_By_Id_Api();
				this.Get_Cuenta_By_Id_Api();
			},
			error: (error) => {
				console.error('Error al cargar las empresas', error);
			},
		});
	}

	Get_PeriodosCuenta_Api(): void {
		this._PeriodoCuenta_service.get().subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.periodos_cuenta = response.data;
				} else {
					console.error('No se encontró ningún dato');
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			}
		});
	}
	

	getPersonas_Api(): void {
		this._peronsaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.personas = response_data;
					setTimeout(() => {
					}, 200);
				} else {
					console.error('No se enconsstraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_Persona_By_Id_Api(): void {
		this._peronsaService.getByid(this.Update_Desembolso_Form.value.persona_id__autoriza).subscribe({
			next: (response: any) => {
				this.Update_Desembolso_Form.patchValue({
					empresa_id: response.data.empresa_id,
					sede_id: response.data.sede_id,
					area_id: response.data.area_id,
				});
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}
	Get_Cuenta_By_Id_Api(): void {
		this._Cuenta_Service.getByid(this.Update_Desembolso_Form.value.cuenta_id).subscribe({
			next: (response: any) => {
				this.Update_Desembolso_Form.patchValue({
					empresa_id_cuenta: response.data.empresa_id,
					sede_id_cuenta: response.data.sede_id,
				});
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_Desembolsos_Api(): void {
		this._ReporteDesembolso_Service.get(null).subscribe({
			next: (response: any) => {
				const response_data = response.data;

				if (Array.isArray(response_data)) {
					this.Desembolso_Response = response_data;
				} else {
					this.Desembolso_Response = Object.values(response); // Si no es un array, asigna un array vacío
				}

				if (this.Desembolso_Response.length > 0) {
					setTimeout(() => {

					}, 200);
				} else {
				}
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}

	Update_Desembolso_Api(): void {
		const url = 'desembolso/update';
		this._Desembolso_Service.post(this.Update_Desembolso_Form.value, url).subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this._Message_Service.add({
						severity: 'success',
						summary: response.title,
						detail: response.message,
						life: 3000,
					});
				} else {
					this._Message_Service.add({
						severity: 'error',
						summary: response.title,
						detail: response.message,
						life: 3000,
					});
				}
			}
		});

	}

	Get_SedesByEmpresaId_Api(object: DropdownChangeEvent): void {
		let sede_id = object.value;
		let request = {
			'empresa-id': sede_id,
		};
		this._sedeService.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.sedes = response_data;
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}
	getEmpresas_Api(): void {
		this._empresaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.empresas = response_data;
					setTimeout(() => {

					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}
	getEmpresasCuenta_Api(): void {
		this._empresaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.empresas_cuenta = response_data;
					setTimeout(() => {

					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_AreasBySedeId_Api(object: DropdownChangeEvent): void {
		let sede_id = object.value;
		let request = {
			'sede-id': sede_id,
		};
		this._areaService.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.areas = response_data;
					setTimeout(() => {

					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}
	getArea_Api(): void {
		this._areaService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.areas = response_data;
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}
	getSedes_Api(): void {
		this._sedeService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.sedes = response_data;
					setTimeout(() => {

					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}
	getSedesCuenta_Api(): void {
		this._sedeService.get().subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.sedes_cuenta = response_data;
					setTimeout(() => {

					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_PersonasByAreaId_Api(object: DropdownChangeEvent): void {
		let area_id = object.value;
		let request = {
			'area-id': area_id,
		};
		this._peronsaService.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					// this.personas = response_data;
					setTimeout(() => {

					}, 200);
				} else {
					console.error('No se encontraron datos');
				}
			},
			error: (error) => {
				console.log('Error al consumir el servicio', error);
			},
		});
	}

	Get_Cuentas_Api(): void {
		this._Cuenta_Service.get().subscribe({
			next: (response: any) => {
				this.cuentas = response.data;
			},
			error: (error) => {
				console.log('Error al llamar el servicio', error);
			},
		});
	}
}
