<div class="modal-header">
    <h4 id="h41" class="modal-title pull-left">{{ title }}</h4>
</div>

<form [formGroup]="RegistroLiquidacion_Form">
    <p-card>
        <div class="header-boton">
            <div class="select-1">
                <p>Desembolso:</p>
                <p-dropdown [showClear]="true" placeholder="Selecciona Desembolso" [options]="desembolsos"
                    optionValue="id_desembolso" optionLabel="codigo" formControlName="id_desembolso"
                    [filter]="true" (onChange)="Get_Desembolso()"></p-dropdown>
            </div>
            <div class="select-1">
                <p>Monto Desembolso:</p>
                <input type="text" class="input-size" pInputText formControlName="monto_desembolso" [readOnly]="true" />
            </div>
            <div class="select-1">
                <p>Gasto Desembolso:</p>
                <input type="text" class="input-size" pInputText formControlName="gasto_desembolso"
                    [readOnly]="true" />
            </div>
            <div class="select-1">
                <p>Saldo Desembolso:</p>
                <input type="text" class="input-size" pInputText formControlName="saldo_desembolso"
                    [readOnly]="true" />
            </div>
            <div class="select-1">
                <p>Detalles:</p>
                <input type="text" class="input-size" pInputText formControlName="detalles"
                    [readOnly]="true" />
            </div>

            <div class="botton-1">
                <p-button icon="pi pi-plus" (click)="Add_DetalleLiquidacionGastos()" label="Agregar"></p-button>
            </div>
        </div>
    </p-card>

    <p-divider type="solid"></p-divider>

    <p-card>
        <table>
            <thead>
                <tr>
                    <th width="20%">Concepto</th>
                    <th width="10%">Fecha</th>
                    <th width="10%">Tipo Comprobante</th>
                    <th width="5%">N° Comprobante</th>
                    <th width="5%">Simbolo Moneda</th>
                    <th width="5%">Moneda</th>
                    <th width="8%">Monto</th>
                    <th width="8%">Comprobante</th>
                    <th width="8%">Detalles</th>
                    <th width="5%">Acciones</th> <!-- Añadido para acciones -->
                </tr>
            </thead>
            <tbody formArrayName="detalles_liquidacion_gastos">
                <tr *ngFor="let detalle_liquidacion of detalles_liquidacion_gastos.controls; let i = index" [formGroupName]="i">
                    <td>
                        <input type="text" class="input-size" pInputText formControlName="concepto" />
                    </td>
                    <td>
                        <p-calendar [showTime]="false" formControlName="fecha_comprobante" dateFormat="yy/mm/dd"
                            appendTo="body"></p-calendar>
                    </td>
                    <td>
                        <p-dropdown [options]="Tipo_comprobante" formControlName="tipo_comprobante"></p-dropdown>
                    </td>
                    <td>
                        <input class="input-size" type="text" pInputText formControlName="nro_comprobante" />
                    </td>
                    <td>
                        <p-dropdown [options]="monedas" optionValue="simbolo" optionLabel="simbolo"
                            formControlName="simbolo_moneda"></p-dropdown>
                    </td>
                    <td>
                        <p-dropdown [options]="monedas" optionValue="nombre" optionLabel="nombre"
                            formControlName="moneda"></p-dropdown>
                    </td>
                    <td>
                        <input class="input-size monto-detalle-liquidacion" type="number" pInputText
                            formControlName="monto" (keyup)="Calculate_GastoTotal()" />
                    </td>
                    <td>
                        <input type="file" (change)="onFileSelect($event, i)" class="form-control">
                    </td>
                    <td>
                        <input type="text" class="input-size" pInputText formControlName="detalles" />
                    </td>
                    <td>
                        <p-button [raised]="true" pTooltip="Eliminar" tooltipPosition="bottom" icon="pi pi-trash"
                            (click)="eliminarFila(i)" severity="danger"></p-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </p-card>

    <p-divider type="solid"></p-divider>

    <p-card>
        <div class="register-botton">
            <button pButton type="button" class="p-button-success mb-5" icon="pi pi-save" label="Registrar"
                (click)="registerLiquidacionGastos()"></button>
            <p-button label="Cancelar" (click)="hideDialog()" [raised]="true" severity="danger"></p-button>
        </div>
    </p-card>
</form>