<section class="row">
	<div class="col-lg-12">
		<div class="row">
			<div class="col-lg-10">
				<label>
					Periodo Cuenta:
					<p-dropdown [showClear]="true" [options]="PeriodosCuenta" optionValue="id_periodo_cuenta"
						optionLabel="nombre" [(ngModel)]="id_periodo"
						(onChange)="Get_Movimientos_By_Periodo()"></p-dropdown>
				</label>
			</div>
			<div class="col-lg-2">
				{{ Cuenta.estado_periodo_cuenta }}
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<h3 class="titulo"> {{ Cuenta.nombre_empresa }} </h3> <br>
				<h4 class="titulo resumen-periodo">RESUMEN MENSUAL</h4> <br>
				<h4 class="titulo nombre-cuenta"> {{ Cuenta.nombre }} </h4> <br>
				<h5 class="titulo"> {{ Cuenta.nombre_periodo }} {{ Cuenta.year_periodo }}  </h5> <br> 
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
					<p-table [tableStyle]="{ 'min-width': '50rem' }"
                        styleClass="p-datatable-gridlines p-datatable-striped">
					<ng-template pTemplate="header">
						<tr>
							<th>SALDO INICIAL</th>
							<td> {{ Cuenta.inicio_periodo }} </td>
							<td class="monto"> {{ Cuenta.monto_inicial }} </td>
						</tr>
						<tr>
							<th>TOTAL INGRESOS</th>
							<td> {{ Cuenta.inicio_periodo }} al {{ Cuenta.fin_periodo }} </td>
							<td class="monto"> {{ Cuenta.monto_ingresos }} </td>
						</tr>
						<tr>
							<th>TOTAL EGRESOS</th>
							<td> {{ Cuenta.inicio_periodo }} al {{ Cuenta.fin_periodo }} </td>
							<td class="monto"> {{ Cuenta.monto_egresos }} </td>
						</tr>
						<tr>
							<th>SALDO FINAL</th>
							<td> {{ Cuenta.fin_periodo }} </td>
							<th class="monto"> {{ Cuenta.monto_final }} </th>
						</tr>
					</ng-template>
				</p-table>
			</div>
		</div>
	</div>

</section>