
<form [formGroup]="Form_Request">
<section class="container">
    <p-toast></p-toast>
    <div class="row">
            <div class="col-lg-4">
                <label for="cuenta__id">Cuenta</label>
                <p-dropdown [options]="Cuentas" optionValue="id_cuenta" optionLabel="nombre" inputId="cuenta__id"
                    formControlName="id_cuenta">
                </p-dropdown>
            </div>
            <div class="col-lg-4">
                <label for="periodo_cuenta__id">Periodo Cuenta</label>
                <p-dropdown [options]="PeriodosCuenta" optionValue="id_periodo_cuenta" optionLabel="nombre"
                    inputId="periodo_cuenta__id" formControlName="id_periodo_cuenta">
                </p-dropdown>
            </div>
            <div class="col-lg-4">
                <p-button pRipple severity="success" label="Cerrar Periodo" (click)="Cerrar_PeriodoCuenta()"></p-button>
            </div>
        </div>
    </section>
</form>