import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Signin} from "./signin/signin.model";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {AuthResponse} from "./auth.response";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://api-sigfi.cloud.elastika.pe/api/login'; // Cambia esta URL
  private tokenKey = 'token'; // Key para el token en localStorage
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.isAuthenticated$.next(!!this.getToken());
  }

  login(credentials: { email: string; password: string }): Observable<any> {
    return this.http.post(`${this.apiUrl}`, credentials)
      .pipe(
        tap((response: any) => {
          if (response.token) {
            this.storeToken(response.token);
            this.isAuthenticated$.next(true);
          } else {
            console.error('Token no encontrado en la respuesta');
          }
        })
        
      );
  }

  logout(): void {
    this.clearToken();
    this.isAuthenticated$.next(false);
  }

  private storeToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  private clearToken(): void {
    localStorage.removeItem(this.tokenKey);
  }
}
