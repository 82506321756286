import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { DatetimeHelper } from 'src/app/_core/helpers/datetime.helper';
import { CommonService } from 'src/app/_core/services/common.service';
import { AdminRoutes } from 'src/app/admin/admin.routes';
import { AppRoutes } from 'src/app/app.routes';
import { pageTransition } from 'src/app/shared/utils/animations';
import { Images } from 'src/assets/data/images';
import { AlertType } from '../../../shared/components/alert/alert.type';
import { PublicRoutes } from '../../public.routes';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LoginRequest } from 'src/app/request/login/login-request';
import { SessionService } from 'src/app/services/session.service';
import { LoginResponse } from 'src/app/response/login/login-response';
import { alert_warning } from 'src/app/functions/alert-function';
import { LoginService } from 'src/app/services/util/login.service';
import { AuthService } from '../auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [InputTextModule, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './signin.component.html',
  providers: [LoginService],
  styleUrls: ['./signin.component.css'],
  animations: [pageTransition],
})
export class SigninComponent   {

  credentials = { email: '', password: '' };

  constructor(private authService: AuthService, private router: Router,		private messageService: MessageService,) {}

  login() {
    this.authService.login(this.credentials).subscribe(
      () => {
        // Si el inicio de sesión es exitoso
        this.messageService.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Ingreso correctamente',
          life: 6000,
        });
        this.router.navigate(['/admin/inicio']);
      },
      (error) => {
        // Si ocurre un error (por ejemplo, credenciales incorrectas)
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Usuario o contraseña incorrecto',
          life: 6000,
        });
      }
    );
  }

}








