import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { Cuenta_Service } from 'src/app/services/gestion-cuenta/gestion-cuenta.service';
import { MovimientoCuenta_Service } from 'src/app/services/gestion-cuenta/movimiento_cuenta.service';
import { PeriodoCuentaService } from 'src/app/services/gestion-cuenta/periodo-cuenta.service';

@Component({
	selector: 'app-resumen-cuenta-periodo',
	standalone: true,
	imports: [
		TableModule, DropdownModule, CommonModule, FormsModule
	],
	templateUrl: './resumen-cuenta-periodo.component.html',
	styleUrl: './resumen-cuenta-periodo.component.css',
	providers: [
		MovimientoCuenta_Service
	]
})
export class ResumenCuentaPeriodoComponent {

	@Input() Data_Parent: any;
	Data: any = {};
	id_cuenta: number;
	Cuenta: any = {};
	id_periodo: number;
	PeriodosCuenta: any = [];



	constructor(
		private _Cuenta_Service: Cuenta_Service,
		private _PeriodoCuenta_Service: PeriodoCuentaService
	) { }


	ngOnChanges(changes: SimpleChanges) {
		if (changes['Data_Parent'] && changes['Data_Parent'].currentValue) {
			this.Data = { ...changes['Data_Parent'].currentValue };
			if (this.Data.id_cuenta) {
				this.Get_PeriodosCuenta_By_CuentaId_Api();
			}
		}
	}


	ngOnInit(): void {
	}
	Get_Movimientos_By_Periodo(): void {
		const request = {
			'resumen-cuenta': this.Data.id_cuenta,
			'periodo-cuenta': this.id_periodo
		}
		this._Cuenta_Service.getByOther(request, 'general/').subscribe({
			next: (response: any) => {
				if (response.status == 'OK') {
					this.Cuenta = response.data;
				} else {
					console.log('Error al consumir el servicio');
				}
			}
		})
	}

	Get_PeriodosCuenta_By_CuentaId_Api(): void {
		const request = {
			'cuenta': this.Data.id_cuenta
		}
		this._PeriodoCuenta_Service.getByOther(request).subscribe({
			next: (response: any) => {
				const response_data = response.data;
				if (response_data) {
					this.PeriodosCuenta = response_data;
				} else {
					console.error('No se enconrtó datos');
				}
			},
			error: (error) => {
				console.log("Error al consumir el servicio", error);
			}
		});
	}

}
