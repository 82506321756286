import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detalle-liquidacion-gasto',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    DialogModule,
  ],
  templateUrl: './detalle-liquidacion-gasto.component.html',
  styleUrl: './detalle-liquidacion-gasto.component.css',
})
export class DetalleLiquidacionGastoComponent {
  @Input() data: any; // Recibe los datos del componente padre
  formData: any = {};
  Back_Server__Storage: string = environment.Back_Server__Storage;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].currentValue) {
      this.formData = { ...changes['data'].currentValue };
    }
  }

  get detallesGastos() {
    return this.formData?.detalles_liquidacion_gasto || [];
  }
  verComprobante(){

  }
}
