import { Component, EventEmitter, Input, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Button } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

import { alert_success, alert_warning } from 'src/app/functions/alert-function';
import { UpdateEstadoDesembolsoService } from 'src/app/services/gestion-desembolso/update-estado-desembolso.service';

@Component({
  selector: 'app-update-estado-desembolso',
  standalone: true,
  imports: [
    Button,
    FormsModule,
    InputTextModule
  ],
  templateUrl: './update-estado-desembolso.component.html',
  styleUrl: './update-estado-desembolso.component.css'
})
export class UpdateEstadoDesembolsoComponent {
  @Output() Close_Modal_Event = new EventEmitter<void>();
  @Input() DataComponent_Parent: any;  // Recibe los datos del componente padre
  selectedItem: any = null; 
  Desembolso_Form: FormGroup;
  @Output() registrosAgregado = new EventEmitter<any>();
  disableButton: boolean = false;

  /* Component Data */
  DataComponent: any = {};

  constructor (
    private fb: FormBuilder,
    private _UpdateEstadoDesembolso_Service : UpdateEstadoDesembolsoService
  ) {
    this.Desembolso_Form = this.fb.group({});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['DataComponent_Parent'] && changes['DataComponent_Parent'].currentValue) {
      this.DataComponent = { ...changes['DataComponent_Parent'].currentValue };  // Actualiza los datos del formulario
    }
  }
 
  update__estado(): void {
    const request = {...this.DataComponent};

    this._UpdateEstadoDesembolso_Service.update(request.id_desembolso, request).subscribe({
        next: (response: any) => {
            if (response.status === 'CREATED') {
                const nuevoRegistro = response.data;

                // Emite el nuevo estado al componente que lo necesita
                this.registrosAgregado.emit(nuevoRegistro);

                // Mostrar una alerta de éxito
                alert_success(response.message, 3000);

                // Cerrar el modal y emitir eventos para cerrar otros modales si es necesario
                this.hideDialog();
              
            } else {
                alert_warning(response.message);
            }
        },
        error: (err) => {
            console.error('Error:', err);
            alert_warning('Hubo un error al actualizar el estado del desembolso');
            this.disableButton = false;
        }
    });

    // Emitir evento para cerrar el modal si es necesario
    this.Close_Modal_Event.emit();
}


  hideDialog() {
    this.Close_Modal_Event.emit();
  }

}
